<template>
    <div :class="['ufo--dd-select', {dark: darkScheme}]" tabindex="1" @blur="showOptions = false">
        <label v-if="label" :class="{open : showOptions}">{{ label }}</label>
        <div class="ufo--dd-selector">
            <div :class="['ufo--dd-selected', {open : showOptions}, {'disabled':disabled}]" @click="toggleDropDown()">
                <div class="selected-container">
                    <Icon class="type-icon" v-if="icon" :icon-name="icon" />
                    <Component :is="inlineEdit ? 'input' : 'span'"
                               :value="inlineEdit ? selected : null"
                               :type="inlineEdit ? inputType : null"
                               :autofocus="inputFocus"
                               @click="handleInputFocus($event)"
                               @blur="handleInputBlur($event)"
                    >
                        {{ selected }}
                    </Component>
                </div>
                <Icon class="chevron" icon-name="dropdown-select" />
            </div>
            <transition name="drop-down">
                <div v-if="showOptions" :class="['ufo--dd-options', {'footer': this.$slots['footer-option']}]">
                    <div class="dd-scrollable" ref="scrollable">
                        <div v-for="option in options"
                             :key="option"
                             @click="selectOption(option)"
                             :class="['ufo--dd-option', {selected : selected === option}]"
                        >
                            <span>
                                {{ option }}
                            </span>
                            <Icon v-if="selected === option" icon-name="checkmark" />
                        </div>
                        <slot name="footer-option"></slot>
                    </div>
                </div>
            </transition>
        </div>

    </div>
</template>
<script>
    import dropdownSelectIcon from "../../../images/general-icons/dropdown-select.svg"
    import Icon from "../Icon.vue";
    import PerfectScrollbar from 'perfect-scrollbar';

    let Ps = null;


    export default {
        name: 'drop-down-select',
        inheritAttrs: false,
        components: {
            Icon
        },
        props: {
            label: {
                type: String,
                default: null
            },
            selected: {
                type: String,
                default: null
            },
            options: {
                type: Array,
                default: null
            },
            inlineEdit: {
                type: Boolean,
                default: false
            },
            inputType: {
                type: String,
                default: "text"
            },
            darkScheme: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            icon: {
                type:String,
                default:null
            }
        },
        watch: {
            showOptions(val) {
                if(!!val) {
                    this.$nextTick(() => Ps = new PerfectScrollbar(this.$refs.scrollable, {wheelPropagation: false}));
                } else {
                    Ps.destroy(this.$refs.scrollable);
                }
            }
        },
        data() {
            return {
                showOptions: false,
                dropdownSelectIcon: dropdownSelectIcon,
                inputFocus: false
            }
        },
        methods: {
            toggleDropDown() {
                this.showOptions = !this.showOptions && !this.disabled
            },
            handleInputFocus(e) {
                if(this.inlineEdit) {
                    e.stopPropagation();
                    this.inputFocus = true;
                    this.showOptions = false;
                }
            },
            handleInputBlur(e) {
                if(this.inlineEdit) {
                    this.selectOption(e.target.value);
                    this.inputFocus = false
                }
            },
            selectOption(option) {
                this.showOptions = false;
                this.$emit('click', option);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ufo--dd-select {
        --labelColor: var(--asphalt50);
        --selectColor: var(--asphalt60);
        --selectBackgroundColor: var(--asphalt20);
        --selectBackgroundHoverColor: var(--asphalt30);
        --activeColor: var(--asphalt100);
        --optionsBackgroundColor: var(--asphalt10);
        --optionsBackgroundBorderColor: var(--asphalt20);
        --itemColor: var(--asphalt100);
        --itemBackgroundHoverColor: var(--asphalt20);
        --optionsShadow: var(--shadow-light-small);

        &.dark{
            --labelColor: var(--asphalt40);
            --selectColor: var(--asphalt40);
            --selectBackgroundColor: var(--asphalt80);
            --selectBackgroundHoverColor: var(--asphalt70);
            --activeColor: var(--asphalt10);
            --optionsBackgroundColor: var(--asphalt70);
            --optionsBackgroundBorderColor: var(--asphalt60);
            --itemColor: var(--asphalt10);
            --itemBackgroundHoverColor: var(--asphalt60);
            //--optionsShadow: var(--shadow-dark-small);
        }

        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        font: var(--editor-ui-b2-regular);
        letter-spacing: 0.14px;

        label {
            font: var(--editor-ui-b2-regular);
            color: var(--labelColor);
            margin-bottom: 16px;
        }

        .ufo--dd-selector {
            width: 100%;
            position: relative;
            text-transform: capitalize;
        }
        .ufo--dd-selected {
            font: var(--editor-ui-b2-regular);
            color: var(--selectColor);
            background-color: var(--selectBackgroundColor);
            padding: 6px 12px 6px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 48px;

            &:hover {
                background-color: var(--selectBackgroundHoverColor);
            }

            &.disabled {
                cursor: not-allowed;
                background-color: transparent;
                color: var(--asphalt50);
            }

            svg {
                margin-left: 10px;
                transition: transform 100ms ease-in-out;
                width: 24px;
                height: 24px;
            }

            .selected-container {
                display: flex;
                align-items: center;
                gap: 8px;

                .type-icon {
                    margin-left: 0;
                    width: 20px;
                    height: 20px;
                }
            }

            &.open {
                color: var(--activeColor);
                &:hover {
                    background-color: var(--selectBackgroundColor);
                }
                .chevron {
                    transform: rotate(180deg);
                }
            }

            input {
                padding: 0;
                border: none;
                max-width: 48px;
                background-color: inherit;
                color: var(--selectColor);
                outline: none;

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                /* Firefox */
                &[type=number] {
                    -moz-appearance: textfield;
                }
            }
        }

        .ufo--dd-options {
            width: 100%;
            border: 1px solid var(--optionsBackgroundBorderColor);

            position: absolute;
            left: 0;
            top: 60px;
            z-index: 1;
            background: var(--optionsBackgroundColor);

            border-radius: 4px;
            box-shadow: var(--optionsShadow);

            .dd-scrollable {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-height: 205px;
                overflow: hidden;
                user-select: none;
            }

            &.footer {
                max-height: unset;
            }

            .ufo--dd-option {
                padding: 12px;
                font: var(--editor-ui-b2-regular);
                cursor: pointer;
                width: 100%;
                color: var(--itemColor);
                display: flex;
                justify-content: space-between;

                &:hover {
                    background-color: var(--itemBackgroundHoverColor);
                }
                &.selected {
                    font: var(--editor-ui-b2-medium);
                }

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
</style>