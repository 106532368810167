<template>
    <img v-if="section && section.type === 'LogoWall' && columnMedia.url.startsWith('/example_project_images/logo-') && $store.state.darkMode"
         ref="img"
         @load="imageLoaded"
         v-zoomable="isImageZoomable"
         loading="lazy"
         :src="getImageUrl"
         :alt="columnMedia.altTag ? columnMedia.altTag : 'Image alt tag'">

    <img v-else
         ref="img"
         @load="imageLoaded"
         v-zoomable="isImageZoomable"
         loading="lazy"
         :class="forcedAspectRatio ? `aspect-ratio-${section.mediaAspectRatio}` : null"
         :src="getImageUrl"
         :alt="columnMedia.altTag ? columnMedia.altTag : 'Image alt tag'"
    >
</template>

<script>
import {hexToHSL, resolveColor} from "../../../../common/helpers.js";

    export default {
        name: "PlaceColumnMediaContent",
        props: ['section', 'columnMedia', 'placeholderImage'],
        mounted() {
            if(this.section && this.section.type === 'LogoWall' && !this.$store.state.darkMode) {
                this.$store.state.darkMode = hexToHSL(resolveColor(this.designSettings.colorStyles.background, this.designSettings.colorPalette)).l < 0.2;
            }
        },
        methods: {
            imageLoaded() {
                this.$store.commit("recalculateScrollTriggers");
            },
        },
        computed: {
            isImageZoomable() {
                return this.section && this.section.type !== 'LogoWall';
            },
            designSettings() {
                return this.$store.getters.isCaseStudy && !this.$store.getters.usingGlobalColorStyles
                    ? this.$store.state.project.designSettings
                    : this.$store.state.portfolio.designSettings
            },
            forcedAspectRatio() {
                return !!this.section?.mediaAspectRatio;
            },
            getImageUrl() {
                if(this.section && this.section.type === 'LogoWall' && this.columnMedia.url.startsWith('/example_project_images/logo-') && this.$store.state.darkMode) {
                    return this.columnMedia.url.replace('.png', '-white.png')
                }
                else {
                    return this.columnMedia.croppedImageUrl ? this.columnMedia.croppedImageUrl : this.columnMedia.url ? this.columnMedia.url : '/example_project_images/' + this.placeholderImage
                }
            }
        }
    }
</script>