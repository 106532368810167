<template>
    <div :class="['navbar', navBarLayout, navBarStyle]">
        <GetHeroPortfolioLogo v-if="!navigationIsBelow" />
        <div :class="['navbar-container', {'no-mob' : navigationIsInlineUnderText() || templateSettings.theme.includes('profile_theme_default')},  $store.state.view.navOpen ? 'on' : '']"
             :style="usePortfolioHeroBackground"
        >
            <div v-if="showLines" class="line"></div>

                <GetHeroNavigationItem v-for="(page, index) in portfolioPages"
                                       :index="index"
                                       :page="page"
                                       :key="page._id"
                />

            <GetHeroSocialLinks v-if="socialLinksAreInNavbar()"/>
            <div v-if="showLines" class="line"></div>
        </div>

        <HamburgerToggle v-if="showHambi()" />
        <div v-if="templateSettings.theme === 'theme_three'" @click="goHome">
            <GetHeroPortfolioImage />
        </div>
    </div>
</template>

<script>
import {
    NavigationLayoutEnum,
    NavigationTypeEnum,
    SocialLinksLocationEnum
} from "../../../../common/enums.js";
import GetHeroNavigationItem from "./GetHeroNavigationItem.vue";
import GetHeroPortfolioLogo from "./GetHeroPortfolioLogo.vue";
import GetHeroSocialLinks from "./GetHeroSocialLinks.vue";
import GetHeroPortfolioImage from "./GetHeroPortfolioImage.vue";
import HamburgerToggle from "../../../components/HamburgerToggle.vue";

export default {
    name: "GetHeroNavigation",
    components: {
        GetHeroNavigationItem,
        GetHeroPortfolioLogo,
        HamburgerToggle,
        GetHeroPortfolioImage,
        GetHeroSocialLinks
    },
    props: ['showHomeButton'],
    computed: {
        portfolio() {
            return this.$store.state.view.portfolio
        },
        templateSettings() {
            return this.portfolio.templateSettings
        },
        portfolioPages() {
            let pages = this.$store.state.view.portfolioPages
            if(!this.showHomeButton) {
                pages = pages.filter(p => p.type !== 'home')
            }

            return pages
        },
        navBarLayout() {
            return this.portfolio.templateSettings.navigation.layout
        },
        navBarStyle() {
            return this.portfolio.templateSettings.navigation.style
        },
        showLines() {
            return this.portfolio.templateSettings.navigation.layout === NavigationLayoutEnum.LINE_MIDDLE
        },
        usePortfolioHeroBackground() {
            return this.portfolio.templateSettings.theme === 'theme_five' && this.portfolio.templateSettings.hero.image.url && !this.portfolio.designSettings.colorStyles.background.primaryColor
                ? [
                    {'background': `url('${this.portfolio.templateSettings.hero.image.url}') 100% 100% / 100% 100% no-repeat`},
                ]
                : null
        },
        theme() {
            return this.portfolio.templateSettings.theme
        },
        navigationIsBelow() {
            return this.portfolio.templateSettings.navigation && this.portfolio.templateSettings.navigation.type === NavigationTypeEnum.BELOW;
        }
    },
    methods: {
        selected(page) {
            return this.$store.state.view.activePage === page.shortId ? 'selected' : ''
        },
        active(page) {
            return this.$store.state.view.activePage === page.shortId ? 'active' : ''
        },
        pageTitle(title) {
            return this.portfolio.templateSettings.navigation.navigationItems.button.upperCase
                ? title.toUpperCase()
                : title
        },
        socialLinksAreInNavbar() {
            return this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.NAVBAR &&
                this.portfolio.socialLinks &&
                this.portfolio.socialLinks.length > 0 &&
                this.portfolio.socialLinks[0].type !== "";
        },
        navigationIsInlineUnderText() {
            return this.portfolio.templateSettings.navigation.type === NavigationTypeEnum.INLINE_BELOW_TEXT
        },
        showHambi() {
            return !this.navigationIsInlineUnderText()
                && !this.templateSettings.theme.includes('profile_theme_default')
                && !this.templateSettings.theme.includes('profile_theme_four')
                && !this.templateSettings.theme.includes('profile_theme_nine')
        },
        goHome() {
            window.location = this.portfolio.ownDomainAddress ? 'https://' + this.portfolio.ownDomainAddress :  '/' + this.portfolio.shortId
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/styles/layouts/new-portfolio-layout/components/navbar";
</style>