<template>
    <svg width="128" height="48" viewBox="0 0 128 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.7023 7.32158L24.8295 13.9386L27.2983 14.7156L27.9377 12.4441L32.2096 17.0704L0 7L20.3587 36.6846L38.15 19.4961L26.7023 7.32158ZM23.1236 29.9349L26.036 19.1049L23.5764 18.328L20.0937 31.2201L7.09256 12.1837L33.1249 20.3265L23.1236 29.9349Z" fill="#0F0F0F"/>
        <path d="M121.313 23.4087C122.638 23.4087 123.797 23.6734 124.79 24.2027C125.801 24.732 126.586 25.4918 127.143 26.4822C127.719 27.4726 128.006 28.6678 128.006 30.068C128.006 31.451 127.719 32.6463 127.143 33.6537C126.586 34.6441 125.801 35.4039 124.79 35.9332C123.797 36.4626 122.638 36.7272 121.313 36.7272C120.006 36.7272 118.846 36.4626 117.835 35.9332C116.824 35.4039 116.031 34.6441 115.456 33.6537C114.898 32.6463 114.619 31.451 114.619 30.068C114.619 28.6678 114.898 27.4726 115.456 26.4822C116.031 25.4918 116.824 24.732 117.835 24.2027C118.846 23.6734 120.006 23.4087 121.313 23.4087ZM121.313 26.0212C120.65 26.0212 120.084 26.1748 119.613 26.4822C119.16 26.7725 118.812 27.2164 118.568 27.8141C118.323 28.4117 118.201 29.163 118.201 30.068C118.201 30.9729 118.323 31.7242 118.568 32.3219C118.812 32.9195 119.16 33.372 119.613 33.6793C120.084 33.9696 120.65 34.1147 121.313 34.1147C121.958 34.1147 122.507 33.9696 122.96 33.6793C123.431 33.372 123.788 32.9195 124.032 32.3219C124.276 31.7242 124.398 30.9729 124.398 30.068C124.398 29.163 124.276 28.4117 124.032 27.8141C123.788 27.2164 123.431 26.7725 122.96 26.4822C122.507 26.1748 121.958 26.0212 121.313 26.0212Z" fill="#0F0F0F"/>
        <path d="M110.526 23.2763C109.777 23.2763 109.219 23.1226 108.853 22.8153C108.487 22.4908 108.304 22.0213 108.304 21.4066C108.304 20.7748 108.487 20.3052 108.853 19.9979C109.219 19.6734 109.777 19.5112 110.526 19.5112C111.276 19.5112 111.834 19.6734 112.2 19.9979C112.566 20.3052 112.749 20.7748 112.749 21.4066C112.749 22.0213 112.566 22.4908 112.2 22.8153C111.834 23.1226 111.276 23.2763 110.526 23.2763ZM112.304 25.0083V36.47H108.722V25.0083H112.304Z" fill="#0F0F0F"/>
        <path d="M105.399 36.4718V19.5518H101.853V36.4718H105.399Z" fill="#0F0F0F"/>
        <path d="M92.5315 23.4087C93.8562 23.4087 95.0154 23.6734 96.0089 24.2027C97.0199 24.732 97.8043 25.4918 98.3621 26.4822C98.9373 27.4726 99.2249 28.6678 99.2249 30.068C99.2249 31.451 98.9373 32.6463 98.3621 33.6537C97.8043 34.6441 97.0199 35.4039 96.0089 35.9332C95.0154 36.4626 93.8562 36.7272 92.5315 36.7272C91.2242 36.7272 90.065 36.4626 89.0541 35.9332C88.0431 35.4039 87.25 34.6441 86.6748 33.6537C86.117 32.6463 85.8381 31.451 85.8381 30.068C85.8381 28.6678 86.117 27.4726 86.6748 26.4822C87.25 25.4918 88.0431 24.732 89.0541 24.2027C90.065 23.6734 91.2242 23.4087 92.5315 23.4087ZM92.5315 26.0212C91.8691 26.0212 91.3026 26.1748 90.832 26.4822C90.3788 26.7725 90.0302 27.2164 89.7861 27.8141C89.5421 28.4117 89.4201 29.163 89.4201 30.068C89.4201 30.9729 89.5421 31.7242 89.7861 32.3219C90.0302 32.9195 90.3788 33.372 90.832 33.6793C91.3026 33.9696 91.8691 34.1147 92.5315 34.1147C93.1764 34.1147 93.7255 33.9696 94.1787 33.6793C94.6493 33.372 95.0067 32.9195 95.2507 32.3219C95.4947 31.7242 95.6167 30.9729 95.6167 30.068C95.6167 29.163 95.4947 28.4117 95.2507 27.8141C95.0067 27.2164 94.6493 26.7725 94.1787 26.4822C93.7255 26.1748 93.1764 26.0212 92.5315 26.0212Z" fill="#0F0F0F"/>
        <path d="M81.9696 19.5518C82.4287 19.5518 82.6374 19.5518 83.3052 19.6744C83.9731 19.797 84.3487 19.7918 86.1018 20.9367L84.5731 23.1446C84.0652 22.8219 84.0669 22.8184 83.7569 22.6973C83.0568 22.4239 82.7034 22.4587 82.3716 22.4587C81.6922 22.4587 81.1569 22.7522 80.792 23.0996C80.3275 23.5411 80.3516 24.2101 80.3516 24.9272V27.0372L80.347 27.7498V36.5762H76.7655V24.1887C76.7655 23.4716 76.8879 22.7881 77.1316 22.2424C77.3752 21.6956 77.7149 21.132 78.1508 20.7734C78.5866 20.4148 79.1002 20.0383 79.6938 19.8669C80.3035 19.6786 80.5922 19.6334 80.9261 19.5926C81.26 19.5518 81.5104 19.5518 81.9696 19.5518ZM84.474 26.5699L82.7221 29.1818H72.7451L74.4904 26.5699L84.474 26.5699Z" fill="#0F0F0F"/>
        <path d="M74.1557 36.5371H69.8677L66.1026 30.5438L62.3899 36.5371H58.2588L64.194 28.0081L58.6771 19.6328H62.9128L66.3903 25.3188L69.92 19.6328H73.8419L68.2989 27.8801L74.1557 36.5371Z" fill="#0F0F0F"/>
        <path d="M56.4089 19.6328V29.8173C56.4089 32.1224 55.7814 33.8555 54.5264 35.0166C53.2714 36.1607 51.4411 36.7327 49.0357 36.7327C46.6302 36.7327 44.8 36.1607 43.545 35.0166C42.29 33.8555 41.6625 32.1224 41.6625 29.8173V19.6328H45.2706V29.6636C45.2706 31.0808 45.5757 31.9069 46.1858 32.5899C46.8133 33.2729 47.7633 33.6144 49.0357 33.6144C50.3081 33.6144 51.2494 33.2729 51.8595 32.5899C52.4696 31.9069 52.7746 31.0808 52.7746 29.6636V19.6328H56.4089Z" fill="#0F0F0F"/>
    </svg>
</template>

<script>
    export default {
        name: "UXFolioLogo"
    }
</script>

<style lang="scss" scoped>
    svg {
        path {
            fill: var(--color-text);
        }
    }
</style>