<template>
    <button :class="[ type ]">
        <span v-if="label" :data-label="label">{{ label }}</span>
        <ArrowIcon v-if="type !== 'inline' && !$slots.default" />
        <slot v-else></slot>
    </button>
</template>

<script>
    import ArrowIcon from "../icons/ArrowIcon.vue";

    export default {
        name: 'CtaButton',
        props: {
            label: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: "primary" // primary, secondary, tertiary, inline
            }
        },
        components: {
            ArrowIcon
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../styles/public-pages/components/buttons";
</style>

<style lang="scss" global>
button {
    &.secondary {
        svg {
            path {
                fill: var(--color-btn-secondary-text);
            }
        }
        &:hover {
            svg {
                path {
                    fill: var(--color-btn-secondary-text-hover);
                }
            }
        }
    }
    &.tertiary {
        svg {
            path {
                fill: var(--color-btn-tertiary-text);
            }
        }
        &:hover {
            svg {
                path {
                    fill: var(--color-btn-tertiary-text-hover);
                }
            }
        }
    }
    &.inline {
        svg {
            path {
                fill: var(--color-btn-inline-text);
            }
        }
        &:hover {
            svg {
                path {
                    fill: var(--color-btn-inline-text-hover);
                }
            }
        }
    }
}
</style>