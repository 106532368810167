<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <template v-if="section.media.mockupSettings">
                <div :class="section.media.mockupSettings.mockupType">
                    <MockupContainer
                        :media="section.media"
                    />
                </div>
            </template>
            <PlaceMediaContent v-else :section="section" :default-caption="defaultCaption" :placeholder-image="placeholderImage" />
        </template>
    </AnimationWrapper>
</template>

<script>
    import PlaceMediaContent from "./PlaceMediaContent.vue";
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";
    import MockupContainer from "@/scripts/common/components/MockupContainer.vue";

    export default {
        name: "GetMediaContent",
        components: {MockupContainer, AnimationWrapper, PlaceMediaContent},
        props: ['section', 'defaultCaption', 'placeholderImage']
    }

</script>