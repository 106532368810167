<template>
    <ModalWrapper :show="show" @close="close" :modal-size="'small'" dark-scheme disableOutsideClick>
        <template #modal-body>
            <ModalBody>
                <div class="modal-body-container">
                    <div class="illustration">
                        <Icon :icon-name="'check'" />
                    </div>
                    <div class="text-wrapper">
                        <div class="title-text">Thank you!</div>
                        <div class="subtitle-text">Your review has been submitted.</div>
                    </div>
                    <div class="link-wrapper">
                        <div>Would you like to get a review?</div>
                        <CtaButton dark-scheme @click="openEditor" type="link">Open the editor</CtaButton>
                    </div>
                </div>
            </ModalBody>
        </template>
    </ModalWrapper>
</template>

<script>
    import ModalWrapper from "../../../sections/modals/_ModalWrapper.vue";
    import ModalBody from "../../../sections/modals/_ModalBody.vue";
    import ModalHeader from "../../../sections/modals/_ModalHeader.vue";
    import ModalFooter from "../../../sections/modals/_ModalFooter.vue";
    import Icon from "../../../common/Icon.vue";
    import CtaButton from "../../../common/components/CtaButton.vue";

    export default {
        name: "OverviewSuccessModal",
        props: ['show', 'close'],
        components: {
            CtaButton,
            Icon,
            ModalFooter,
            ModalHeader,
            ModalBody,
            ModalWrapper
        },
        methods: {
            openEditor() {
                window.location.href = '/home';
            }
        }
    }
</script>

<style scoped lang="scss">
    .modal-body-container {
        display: flex;
        width: 500px;
        padding: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        flex-shrink: 0;

        .illustration {
            display: flex;
            padding: 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 999px;
            border: 1px solid var(--asphalt50);
            background: var(--asphalt60);

            svg {
                width: 24px;
                height: 24px;
                color: var(--asphalt10);
            }
        }

        .text-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            .title-text {
                color: var(--asphalt10);
                font: var(--editor-ui-h2);
            }

            .subtitle-text {
                color: var(--asphalt30);
                font: var(--editor-ui-b2-regular);
            }
        }

        .link-wrapper {
            display: flex;
            align-items: center;
            gap: 6px;

            div {
                color: var(--asphalt40);
                font: var(--editor-ui-b2-regular);
            }
        }
    }
</style>