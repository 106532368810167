import {
    MockupColorScheme,
    MockupDeviceType,
    Mockup,
    MockupType,
    MockupOrientation,
    MockupSettings,
    MockupSettingsGroups
} from "@/scripts/common/mockupStore/mockupStructure.js";

export const laptopMockups = {
    [MockupDeviceType.LAPTOP]: {
        [MockupType.REALISTIC]: {
            'v1': new Mockup(
                'laptop-realistic-v1',
                MockupDeviceType.LAPTOP,
                MockupType.REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-realistic-v3',
                MockupDeviceType.LAPTOP,
                MockupType.REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [MockupSettingsGroups.LAPTOP_NOTCH],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.SEMI_REALISTIC]: {
            'v1': new Mockup(
                'laptop-semi-realistic-v1',
                MockupDeviceType.LAPTOP,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v2': new Mockup(
                'laptop-semi-realistic-v2',
                MockupDeviceType.LAPTOP,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-semi-realistic-v2',
                MockupDeviceType.LAPTOP,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [MockupSettingsGroups.LAPTOP_NOTCH],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.SIMPLE]: {
            'v2': new Mockup(
                'laptop-simple-v2',
                MockupDeviceType.LAPTOP,
                MockupType.SIMPLE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-simple-v2',
                MockupDeviceType.LAPTOP,
                MockupType.SIMPLE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [MockupSettingsGroups.LAPTOP_NOTCH],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.OUTLINE]: {
            'v1': new Mockup(
                'laptop-outline-v1',
                MockupDeviceType.LAPTOP,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v2': new Mockup(
                'laptop-outline-v2',
                MockupDeviceType.LAPTOP,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-outline-v3',
                MockupDeviceType.LAPTOP,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.BROWSER]: {
            'v2': new Mockup(
                'laptop-browser-v2',
                MockupDeviceType.LAPTOP,
                MockupType.BROWSER,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-browser-v3',
                MockupDeviceType.LAPTOP,
                MockupType.BROWSER,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.TRADITIONAL]: {
            'v1': new Mockup(
                'laptop-traditional-v1',
                MockupDeviceType.LAPTOP,
                MockupType.TRADITIONAL,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.MINIMAL]: {
            'v2': new Mockup(
                'laptop-minimal-v2',
                MockupDeviceType.LAPTOP,
                MockupType.MINIMAL,
                [MockupColorScheme.LIGHT],
                'v2',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
            'v3': new Mockup(
                'laptop-minimal-v3',
                MockupDeviceType.LAPTOP,
                MockupType.MINIMAL,
                [MockupColorScheme.LIGHT],
                'v3',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
        }
    },
}