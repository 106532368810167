<template>
    <div :id="'section-' + section.index"
         v-if="(section.showText !== null || section.showMedia !== null) && ((section.showText && section.showText === true) && (section.showMedia && section.showMedia === true))"
         :class="['ufo-sec',
          'ufo-sec--textandmedia',
          'flipped-' + section.flipped,
          section.media.mockupSettings ? 'mockup-on' : '' ]">
        <ToggleGrid />
        <div class="grid">
            <div v-for="element in elements" :key="element.key" :class="['col', `col-${element.key}`, mediaType, mediaDisplayMode, frameClasses, isEmbedContainer, verticalAlignment]">
                <div v-if="element.key === 'media'" class="media-container">
                    <GetMediaContent :section="section" :default-caption="projectTitle" :placeholder-image="'empty-image-and-text-image.jpg'" />
                    <GetMediaCaption :caption="section.media.caption" />
                </div>
                <template v-if="element.key === 'text'">
                    <GetSectionText :section-text="section.text"/>
                    <CtaButton :form-submit-button="false" :section="section" :alignment="textAlign"/>
                </template>
            </div>
        </div>
    </div>
    <div v-else-if="(section.showText !== null || section.showMedia !== null) && section.showText === false && section.showMedia === true"
         :id="'section-' + section.index"
         :class="['ufo-sec',
         'ufo-sec--media',
         'image',
         section.media.mockupSettings ? 'mockup-on' : null]">
        <div class="grid">
            <div :class="['media-container',
                'display-mode--' + (section.media.displayMode ? section.media.displayMode : 'default'),
                verticalAlignment,
                section.media.type === 'Video' || section.media.type === 'Prototype' || section.media.type === 'Media' ? 'embed-container' : '']"
                 :style="{'padding-top' : section.style&& section.style.paddingTop ? 0 : null,
                    'paddingBottom': section.style && section.style.paddingBottom ? 0 : null
                 }"
            >
                <GetMediaContent  :section="section" :default-caption="projectTitle" :placeholder-image="'empty-big-image.jpg'" />
                <GetMediaCaption :caption="section.media.caption" />
            </div>
        </div>
    </div>
    <TextSection v-else-if="(section.showText !== null || section.showMedia !== null) && section.showText === true && section.showMedia  === false" :section="section"/>
    <div v-else :id="'section-'+ section.index"
         :class="['ufo-sec', 'ufo-sec--textandmedia', 'flipped-' + section.flipped, section.media.mockupSettings ? 'mockup-on' : '']">
        <div class="grid">
            <div v-for="element in elements" :key="element.key" :class="['col', `col-${element.key}`, mediaType, mediaDisplayMode, frameClasses, isEmbedContainer, verticalAlignment]">
                <div v-if="element.key === 'media'" class="media-container">
                    <GetMediaContent :section="section" :default-caption="projectTitle" :placeholder-image="'empty-image-and-text-image.jpg'" />
                    <GetMediaCaption :caption="section.media.caption" />
                </div>
                <template v-if="element.key === 'text'">
                    <GetSectionText :section-text="section.text"/>
                    <CtaButton :form-submit-button="false" :section="section" :alignment="textAlign"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import GetMediaContent from "./_helper/GetMediaContent.vue";
    import GetMediaCaption from "./_helper/GetMediaCaption.vue";
    import GetSectionText from "./_helper/GetSectionText.vue";
    import TextSection from "./Text.vue";
    import CtaButton from "./_helper/CtaButton.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import {MockupDeviceType, MockupOrientation} from "@/scripts/common/mockupStore/mockupStructure.js";

    export default {
        name: "TextAndMediaSections",
        components: { GetMediaCaption, GetMediaContent, TextSection, CtaButton, GetSectionText, ToggleGrid },
        props:['section', 'projectTitle'],
        beforeMount() {
            if(this.section.flipped) {
                this.elements.reverse();
            }
        },
        data() {
            return {
                elements: [
                    { key: 'media'},
                    { key: 'text'},
                ]
            };
        },
        computed: {
            textAlign() {
                return this.section.text.style && this.section.text.style.textAlign ? 'push-to-' + this.section.text.style.textAlign : ""
            },
            mediaType() {
                return `media-${this.section.media.type.toLowerCase()}`
            },
            mediaDisplayMode() {
                return this.section.media.displayMode ? "media-" + this.section.media.displayMode : "media-default"
            },
            isEmbedContainer() {
                return this.section.media.type === 'Video' || this.section.media.type === 'Prototype' || this.section.media.type === 'Media'? 'embed-container' : ''
            },
            frameClasses() {
                let classes = [];
                if (this.section.media && this.section.media.mockupSettings) {
                    classes = [this.section.media.mockupSettings.deviceType === MockupDeviceType.MOBILE ? 'frame-mobile' : '',
                        this.section.media.mockupSettings.deviceType === MockupDeviceType.TABLET ?'frame-tablet' : '',
                        this.section.media.mockupSettings.deviceType === MockupDeviceType.LAPTOP ? 'frame-desktop' : '',
                        this.section.media.mockupSettings.orientation === MockupOrientation.LANDSCAPE ? 'landscape' : '']
                }
                return classes
            },
            verticalAlignment() {
                return `aligned-${this.section.style && this.section.style.boxAlign && this.section.style.boxAlign.split('-')[1] || 'middle'}`
            }
        }
    }
</script>