export const basicSections = [
    {type: 'Text', name: 'Text', cssClass: 'text'},
    {type: 'Image', name: 'Big image', cssClass: 'image'},
    {type: 'TextAndMedia', name: 'Text & image', cssClass: 'image-text'},
    {type: 'Columns', name: 'Columns', cssClass: 'columns'},
    {type: 'DividerLine', name:'Divider line', cssClass:'divider'},
    {type: 'Gallery', name: 'Gallery', cssClass: 'gallery'},
    {type: 'Prototype', name: 'Prototype', cssClass: 'prototype'},
    {type: 'Video', name: 'Video', cssClass: 'video'},
    {type: 'Media', name: 'Media', cssClass: 'media'},
    {type: 'Stat', name: 'Stats', cssClass: 'stats'},
    {type: 'Header', name: 'Subheader', cssClass: 'header'},
    {type: 'BoldStatement', name: 'Bold statement', cssClass: 'statement'},
    {type: 'Navigation', name: 'Process', cssClass: 'navigation'},
    {type: 'Testimonial', name: 'Testimonials', cssClass: 'testimonial'},
    {type: 'LogoWall', name: 'Logo wall', cssClass: 'logo-wall'},
    {type: 'Cta', name: 'Call to action', cssClass: 'cta'},
];

export const uxMethods = [
    {type: 'Interviews', name: 'Interviews', cssClass: 'interviews'},
    {type: 'Surveys', name: 'Survey', cssClass: 'surveys'},
    {type: 'Personas', name: 'Personas', cssClass: 'personas'},
    {type: 'CustomerJourney', name: 'Customer journey', cssClass: 'cj'},
    {type: 'UserJourney', name: 'User journey', cssClass: 'uj'},
    {type: 'CardSorting', name: 'Card sorting', cssClass: 'card-sorting'},
    {type: 'Sketches', name: 'Sketches', cssClass: 'sketches'},
    {type: 'Wireframes', name: 'Wireframes', cssClass: 'wireframes'},
    {type: 'UsabilityTesting', name: 'Usability testing', cssClass: 'user-testing'},
    {type: 'UiDesign', name: 'UI design', cssClass: 'ui-design'}
];

export const homePageRecommendedSections = [
    {type: 'Bio', name: 'About me', cssClass: 'bio'},
    {type: 'ShortIntro', name: 'Short intro', cssClass: 'short-intro'},
    {type: 'Education', name: 'Education', cssClass: 'education'},
    {type: 'Services', name: 'Services', cssClass: 'skills'},
    {type: 'Articles', name: 'Articles', cssClass: 'articles'},
    {type: 'Awards', name: 'Awards', cssClass: 'awards'},
    {type: 'Hobbies', name: 'Hobbies', cssClass: 'hobbies'},
    {type: 'SocialMedia', name: 'Social media', cssClass: 'personas'},
    {type: 'ADPList', name:'ADPList profile', cssClass: 'adplist'}
];

export const aboutPageSections = [
    {type: 'Text', name: 'Text', cssClass: 'text'},
    {type: 'TextAndMedia', name: 'Text & image', cssClass: 'image-text'},
    {type: 'Columns', name: 'Columns', cssClass: 'columns'},
    {type: 'DividerLine', name:'Divider line', cssClass:'divider'},
    {type: 'Header', name: 'Subheader', cssClass: 'header'},
    {type: 'Gallery', name: 'Gallery', cssClass: 'gallery'},
    {type: 'Image', name: 'Big image', cssClass: 'image'},
    {type: 'Video', name: 'Video', cssClass: 'video'},
    {type: 'Stat', name: 'Stats', cssClass: 'stats'},
    {type: 'Testimonial', name: 'Testimonial', cssClass: 'testimonial'},
    {type: 'BoldStatement', name: 'Bold statement', cssClass: 'statement'},
    {type: 'ContactForm', name: 'Contact form', cssClass: 'contact-form'},
    {type: 'Cta', name: 'Call to action', cssClass: 'cta'},
    {type: 'LogoWall', name: 'Logo wall', cssClass: 'logo-wall'},

];

export const allSectionTypes = [...new Set([...basicSections, ...uxMethods, ...aboutPageSections, ...homePageRecommendedSections])]
