<template>

    <img
        v-if="section.media.type === 'Image'"
        ref="img"
        @load="imageLoaded"
        v-zoomable="isZoomAvailable"
        loading="lazy"
        :class="forcedAspectRatio ? `aspect-ratio-${section.mediaAspectRatio}` : null"
        :src="getImageUrl"
        :alt="section.media.altTag ? altTag : defaultCaption"
        :width="section.media.width"
        :height="section.media.height"
    />

    <div v-else-if="isEmbeddedMedia && section.media.url" class="embed">
        <iframe
            v-bind="iframeAttributes"
            height="100%"
            loading="lazy"
            allowTransparency="true"
            allowfullscreen="false">
        </iframe>
    </div>

    <ADPListProfile v-else-if="section.media.type === 'ADPList'" :media="section.media" />

</template>

<script>
    import ADPListProfile from "@/scripts/sections/common/ADPListProfile.vue";
    import {MockupDeviceType} from "@/scripts/common/mockupStore/mockupStructure.js";
    import {getIframeAttributes} from "@/scripts/common/helpers.js";
    export default {
        name: "PlaceMediaContent",
        components: {ADPListProfile},
        props:['section', 'defaultCaption', 'placeholderImage'],
        data() {
            return {
                iframeAttributes: {}
            }
        },
        mounted() {
            if(this.isEmbeddedMedia) {
                this.iframeAttributes = this.getIframeAttributes(this.section.media);
            }
        },
        computed: {
            isZoomAvailable() {
                return this.section.type !== 'MainHeader'
                    && (!this.section.media.displayMode || this.section.media.displayMode && this.section.media.displayMode !== 'full')
                    && (!this.section.media.mockupSettings)
            },
            altTag() {
                return this.section.media.altTag.replace('\"', '&quot;')
            },
            getImageUrl() {
                if(!this.section.media.croppedImageUrl && this.section.media.url?.includes('/example')) {
                    if (this.section.media.url.includes('suggestion-wireframes')) {
                        if(this.section.media.mockupSettings && (this.section.media.mockupSettings.deviceType === MockupDeviceType.LAPTOP || this.section.media.mockupSettings.deviceType === MockupDeviceType.DESKTOP) ) {
                            return '/example_project_images/v2/suggestion-wireframes-desktop.png'
                        }
                    } else if (this.section.media.url.includes('gallery-sample-tall')) {
                        if(this.section.media.mockupSettings && (this.section.media.mockupSettings.deviceType === MockupDeviceType.LAPTOP || this.section.media.mockupSettings.deviceType === MockupDeviceType.DESKTOP) ) {
                            return this.section.media.url.replace('tall', 'desktop').replace('jpg', 'png')
                        } else if(this.section.media.mockupSettings && this.section.media.mockupSettings.deviceType === MockupDeviceType.TABLET) {
                            return  this.section.media.url.replace('tall', 'tablet').replace('jpg', 'png')
                        }
                    }
                    return this.section.media.url;
                }
                else {
                    return !!this.section.media.croppedImageUrl ? this.section.media.croppedImageUrl : this.section.media.url
                }
            },
            isEmbeddedMedia() {
                return this.section.media.type === 'Video' || this.section.media.type === 'Prototype' || this.section.media.type === 'Media'
            },
        },
        methods: {
            imageLoaded() {
                this.$store.commit("recalculateScrollTriggers");
            },
            forcedAspectRatio() {
                return !!this.section?.mediaAspectRatio
            },
            getIframeAttributes(media) {
                return getIframeAttributes(media);
            }
        }
    }
</script>