import { generateHTML, generateJSON } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

// Map the tagName to a node type for TipTap
const nodeTypeMap = {
    h1: 'heading',
    h2: 'heading',
    h3: 'heading',
    h4: 'heading',
    h5: 'heading',
    h6: 'heading',
    p: 'paragraph',
};

// Custom Paragraph Extension to Support Classes
const CustomParagraph = Paragraph.extend({
    addAttributes() {
        return {
            class: {
                default: null,
            },
        };
    },
});

/**
 * Converts a plain string into rich text HTML using a specified tag.
 *
 * @param {string} tagName - The HTML tag to wrap the text (e.g., "h2", "p").
 * @param {string} text - The plain text string to convert.
 * @param {string} className - Optional class name for paragraphs, like 'p2'.
 * @returns {string} - The generated HTML string.
 */
export function convertStringToRichTextHTML(text, tagName, className = '') {
    const nodeType = nodeTypeMap[tagName.toLowerCase()] || 'paragraph';

    // Decode HTML entities (e.g., &nbsp; → \u00A0)
    function decodeHTML(html) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = html;
        return textarea.value;
    }

    // Remove hidden characters
    function cleanText(input) {
        return decodeHTML(input) // Convert HTML entities to characters
            .replace(/\u00A0/g, ' ') // Convert non-breaking space to normal space
            .replace(/\u200B/g, '')  // Remove zero-width space
            .replace(/\u200C/g, '')  // Remove zero-width non-joiner
            .replace(/\u200D/g, '')  // Remove zero-width joiner
            .replace(/\u2028|\u2029/g, '') // Remove line separators
            .replace(/\uFEFF/g, ''); // Remove byte order mark (BOM)
    }

    const sanitizedText = cleanText(text);

    // Generate the JSON structure
    const jsonContent = {
        type: 'doc',
        content: [
            {
                type: nodeType,
                ...(nodeType === 'heading' && { attrs: { level: parseInt(tagName[1]) } }),
                ...(nodeType === 'paragraph' && className && { attrs: { class: className } }),
                content: [{ type: 'text', text: sanitizedText }],
            },
        ],
    };

    // Generate HTML with the custom paragraph extension
    return generateHTML(jsonContent, [Document, Heading, CustomParagraph, Text]);
}

/**
 * Converts a TipTap-generated HTML string to plain text by parsing the JSON structure.
 *
 * @param {string} htmlString - The TipTap HTML string to convert to plain text.
 * @returns {string} - The plain text string without HTML tags.
 */
export function convertRichTextHTMLToPlainText(htmlString) {
    // Parse the HTML string back into JSON
    const jsonContent = generateJSON(htmlString, [Document, Heading, CustomParagraph, Text]);

    // Recursively traverse the JSON to extract plain text
    const extractText = (node) => {
        if (node.type === 'text') {
            return node.text || '';
        }
        if (node.content) {
            // Join child nodes with line breaks between tags
            return node.content.map(extractText).join('\n');
        }
        return '';
    };

    return extractText(jsonContent).trim();
}

/**
 * Converts all text nodes in an array to the specified node type using a key.
 *
 * @param {Array} textsArray - The array of text boxes with their data.
 * @param {string} key - The key within each text box to update (e.g., "data" or "description").
 * @param {string} targetType - The target HTML tag to convert to (e.g., "h3", "p").
 * @param {string} className - Optional class name for paragraphs, like 'p2'.
 * @returns {Array} - The updated array with converted text content.
 */
export function convertAllTextNodesToTypeByKey(textsArray, key, targetType, className = '') {
    if (!key) {
        throw new Error(`No key specified.`);
    }

    const nodeType = nodeTypeMap[targetType.toLowerCase()] || 'paragraph';

    return textsArray.map((textBox) => {
        // Extract the text from the specified key
        const { text } = textBox[key];

        // Generate JSON from the existing HTML or plain text
        const jsonContent = generateJSON(text, [Document, Heading, CustomParagraph, Text]);

        // Update the node type in the JSON structure
        jsonContent.content = jsonContent.content.map((node) => {
            if (node.type === 'paragraph' || node.type === 'heading' || node.type === 'text') {
                return {
                    ...node,
                    type: nodeType,
                    ...(nodeType === 'heading' && { attrs: { level: parseInt(targetType[1]) } }),
                    ...(nodeType === 'paragraph' && className && { attrs: { class: className } }),
                };
            }
            return node;
        });

        // Generate HTML from the updated JSON structure
        const updatedHTML = generateHTML(jsonContent, [Document, Heading, CustomParagraph, Text]);

        // Update the textBox object with the new HTML in the specified key
        return {
            ...textBox,
            [key]: {
                ...textBox[key],
                text: updatedHTML, // Update the text content with the new HTML
            },
        };
    });
}

/**
 * Gets the node type and class (if applicable) of the first meaningful node in the Tiptap-generated JSON structure.
 *
 * @param {string} htmlString - The Tiptap HTML string to parse.
 * @returns {object | null} - An object with `nodeType` and `class` properties, or null if no meaningful node is found.
 */
export function getFirstNodeDetails(htmlString) {
    // Parse the HTML string back into JSON
    const jsonContent = generateJSON(htmlString, [Document, Heading, CustomParagraph, Text]);

    // Check if the document has content and retrieve the first node
    if (jsonContent.content && jsonContent.content.length > 0) {
        const firstNode = jsonContent.content[0];

        // Handle heading nodes
        if (firstNode.type === 'heading' && firstNode.attrs && firstNode.attrs.level) {
            return {
                nodeType: `h${firstNode.attrs.level}`,
                class: null, // Headings don't typically have classes in this context
            };
        }

        // Handle paragraph nodes and check for class
        if (firstNode.type === 'paragraph') {
            const className = firstNode.attrs?.class || '';
            return {
                nodeType: 'p',
                class: className,
            };
        }
    }

    return null; // Return null if no meaningful node is found
}