<template>
    <div :class="['url-card', {disabled:disabled}]" @click="!disabled ? copyUrl() : null">
        <div class="url">
            <span>{{ url }}</span>
            <Icon v-if="!disabled" :icon-name="copied ? 'check' : 'copy'" />
        </div>
        <div v-if="type" class="type">{{ type }}</div>
    </div>
</template>

<script>
    import { event } from "vue-gtag";
    import Icon from "../Icon.vue";

    export default {
        name: "UrlCardCopy",
        components: {
            Icon
        },
        props: ['url', 'type', 'eventName', 'disabled'],
        data() {
            return {
                copied:false
            }
        },
        methods: {
            copyUrl() {
                navigator.clipboard.writeText(this.url);
                this.copied = true;

                setTimeout(() => {
                    this.copied = false;
                }, 2000)

                event(this.eventName, {
                    'event_category': 'Portfolio editor',
                    'event_label': `User: ${this.$store.state.user._id}, ${this.$refs.url}`
                });
            },
        }
    }
</script>

<style scoped lang="scss">
.url-card {
    display: flex;
    flex-direction: column;
    padding: 16px 14px 16px 16px;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    border-radius: 4px;
    background: var(--asphalt90);

    cursor: pointer;

    &.disabled {
        svg,
        .url {
            color: var(--asphalt50);
        }
        .type {
            color: var(--asphalt60);
        }

        &:hover {
            color: var(--asphalt50);
            cursor: default;
            svg,
            .url {
                color: var(--asphalt50);
            }
        }
    }

    &:hover {
        background-color: var(--asphalt80);
        color: var(--asphalt10);

        svg,
        .url {
            color: var(--asphalt10);
        }
    }

    .url {
        font: var(--editor-ui-b1-medium);
        color: var(--asphalt30);
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 8px;

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        svg {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            color: var(--asphalt30);
        }
    }
    .type {
        font: var(--editor-ui-b2-regular);
        color: var(--asphalt40);
    }
}
</style>