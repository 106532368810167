<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings?.animationStyle"
        :animation-speed="$store.getters.animationSettings?.animationSpeed"
        :delay-animation="animationDelay"
    >
        <template #animationContent>
            <div v-if="templateSettings.showProfilePicture"
                 :class="['ufo--page-hero-image',
                 !templateSettings.hero || !templateSettings.hero.height ? 'fix-h' : '',
                 templateSettings.hero && templateSettings.hero.flipped ? 'flipped' : '',
                 image.displayMode ? image.displayMode : 'theme-default',
                 templateSettings.hero && templateSettings.hero.alignment.vertical ? templateSettings.hero.alignment.vertical : '',
                 templateSettings.hero && templateSettings.hero.alignment.horizontal ? templateSettings.hero.alignment.horizontal : 'left']"
                 :style="{
                    '--background-color': templateSettings.hero && templateSettings.hero.backgroundColor ? templateSettings.hero.backgroundColor : null
                 }"
            >
                <img :src="imageUrl" :alt="imageAlt" class="hero-image">
            </div>
        </template>
    </AnimationWrapper>
</template>

<script>
import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

export default {
    name: "GetHeroPortfolioImage",
    components: {AnimationWrapper},
    props: [''],
    computed: {
        templateSettings() {
            return this.$store.state.portfolio.templateSettings
        },
        image() {
            return this.$store.state.portfolio.image
        },
        imageUrl() {
            return !!this.$store.state.portfolio.image.croppedImageUrl
                ? this.$store.state.portfolio.image.croppedImageUrl
                : !!this.$store.state.portfolio.image.url
                    ? this.$store.state.portfolio.image.url
                    : require('../../../../../images/user-picture-empty-state.png')
        },
        imageAlt() {
            return this.$store.state.view.user.name + 's portfolio profile image'
        },
        animationDelay() {
            if(this.$store.state.portfolio &&
                this.$store.state.portfolio.templateSettings &&
                this.$store.state.portfolio.templateSettings.showProfilePicture) {
                if(this.$store.state.portfolio.templateSettings.hero.flipped) {
                    if(this.$store.state.portfolio.templateSettings.theme.includes('theme_sixteen')) {
                        return 0.2;
                    } else {
                        return 0.4;
                    }
                } else {
                    if(this.$store.state.portfolio.templateSettings.theme.includes('theme_sixteen')) {
                        return 0.4;
                    } else {
                        return 0.2;
                    }
                }
            } else {
                return 0;
            }
        }
    }
}
</script>