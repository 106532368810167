<template>
    <div class="sidebar-overlay">
        <PanelHeader :cascading-view="true" @close="triggerClose()" />

        <ScrollableContainer>
            <div class="ufo-sepa--cop-det">
                <slot></slot>
            </div>
        </ScrollableContainer>

        <PanelFooter @primaryAction="triggerSave()" @secondaryAction="triggerCancel()" />
    </div>
</template>

<script>
import PanelHeader from "./PanelHeader.vue";
import PanelFooter from "./PanelFooter.vue";
import ScrollableContainer from "@/scripts/common/sideBars/components/ScrollableContainer.vue";

export default {
    name: 'overlaying-sidebar-panel',
    components: {
        ScrollableContainer,
        PanelHeader,
        PanelFooter
    },
    emits: ["save", "cancel", "close"],
    methods: {
        triggerSave() {
            this.$emit('save');
        },
        triggerCancel() {
            this.$emit('cancel');
        },
        triggerClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar-overlay {
    position: fixed;
    top: 76px;
    left: 16px;
    z-index: 1;
    width: 352px;
    height: calc(100vh - 76px - 16px);

    display: flex;
    flex-direction: column;

    background-color: var(--asphalt95);

    border-radius: 8px;
    border: 1px solid var(--asphalt80);
    box-shadow: 0 30px 8px 0 rgba(0, 0, 0, 0.00), 0 19px 8px 0 rgba(0, 0, 0, 0.01), 0 11px 6px 0 rgba(0, 0, 0, 0.05), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 1px 3px 0 rgba(0, 0, 0, 0.10);
}

.ufo-sepa--cop-det {
    position: relative;
    flex: 2;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
}
</style>