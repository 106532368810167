import {
    MockupColorScheme,
    MockupDeviceType,
    Mockup,
    MockupType,
    MockupOrientation,
    MockupSettings,
    MockupSettingsGroups
} from "@/scripts/common/mockupStore/mockupStructure.js";

export const tabletMockups = {
    [MockupDeviceType.TABLET]: {
        [MockupType.REALISTIC]: {
            'v1': new Mockup(
                'tablet-realistic-v1',
                MockupDeviceType.TABLET,
                MockupType.REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
            'v3': new Mockup(
                'tablet-realistic-v3',
                MockupDeviceType.TABLET,
                MockupType.REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.SEMI_REALISTIC]: {
            'v1': new Mockup(
                'tablet-semi-realistic-v1',
                MockupDeviceType.TABLET,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
            'v2': new Mockup(
                'tablet-semi-realistic-v2',
                MockupDeviceType.TABLET,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.SIMPLE]: {
            'v2': new Mockup(
                'tablet-simple-v2',
                MockupDeviceType.TABLET,
                MockupType.SIMPLE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.OUTLINE]: {
            'v1': new Mockup(
                'tablet-outline-v1',
                MockupDeviceType.TABLET,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
            'v2': new Mockup(
                'tablet-outline-v2',
                MockupDeviceType.TABLET,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.BROWSER]: {
            'v2': new Mockup(
                'tablet-browser-v2',
                MockupDeviceType.TABLET,
                MockupType.BROWSER,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.TRADITIONAL]: {
            'v1': new Mockup(
                'tablet-traditional-v1',
                MockupDeviceType.TABLET,
                MockupType.TRADITIONAL,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v1',
                [],
                [MockupOrientation.PORTRAIT, MockupOrientation.LANDSCAPE]
            ),
        },
        [MockupType.MINIMAL]: {
            'v2': new Mockup(
                'tablet-minimal-v2',
                MockupDeviceType.TABLET,
                MockupType.MINIMAL,
                [MockupColorScheme.LIGHT],
                'v2',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
        }
    },
}