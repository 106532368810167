import { store } from "../store/FolioStore.js";

export default {
    mounted(el, binding) {
        if (binding.value === false) return;

        el.style.cursor = 'zoom-in';

        const clickHandler = (event) => {
            const target = event.currentTarget;
            store.commit('setZoom', {
                url: target.getAttribute('src'),
                ref: target
            });
        };

        el.addEventListener('click', clickHandler);

        // Find the closest container that holds the clones.
        const container = el.closest('.carousel-gallery');

        if (!container) {
            console.warn('Gallery container not found.');
            return;
        }

        // Use a MutationObserver to catch clones being added and attach the same click handler.
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.querySelectorAll) {
                            node.querySelectorAll('img').forEach(img => {
                                if (img.getAttribute('src') === el.getAttribute('src')) {
                                    img.style.cursor = 'zoom-in';
                                    img.addEventListener('click', clickHandler);
                                }
                            });
                        }
                    }
                });
            });
        });

        // Observe the container for added nodes (clones) anywhere in its subtree.
        observer.observe(container, { childList: true, subtree: true });
    }
};
