<template>
    <button @click="$emit('click')"
            :class="[
                {
                    'tooltip-on' : !!tooltip,
                    'dark': darkScheme,
                },
                importance,
                size
            ]"
            :tooltip="tooltip"
            :tooltip-position="tooltipPosition"
    >
        <icon :icon-name="icon"/>
    </button>
</template>

<script>

import Icon from "../../common/Icon.vue";

export default {
    name: 'icon-button',
    components: {Icon},
    emits: ['click'],
    props: {
        tooltip: {
            type: String,
            default: null
        },
        tooltipPosition: {
            type: String,
            default: null
        },
        darkScheme: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null
        },
        importance: {
            type: String,
            default: 'low'  // low, high
        },
        size: {
            type: String,
            default: 'regular'  // small, regular
        }
    },
}
</script>

<style lang="scss" scoped>
button {
    --color-icon-btn-color: var(--asphalt50);
    --color-icon-btn-color-hover: var(--asphalt100);
    --color-icon-btn-label-color: var(--asphalt10);
    --color-icon-btn-label-bg: var(--asphalt90);
    &.high {
        --color-icon-btn-color: var(--asphalt60)
    }

    &.dark {
        --color-icon-btn-color: var(--asphalt40);
        --color-icon-btn-color-hover: var(--asphalt10);
        --color-icon-btn-label-color: var(--asphalt100);
        --color-icon-btn-label-bg: var(--asphalt20);
        &.high {
            --color-icon-btn-color: var(--asphalt30)
        }
    }


    background-color: transparent;
    border: none;
    font: var(--editor-ui-b3-semibold);
    text-transform: capitalize;
    color: var(--color-icon-btn-color);
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    padding: 0px;

    &:focus {
        outline: none;
    }

    &:hover {
        color: var(--color-icon-btn-color-hover);
    }

    svg {
        width: 24px;
        height: 24px;
    }
    &.small {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.tooltip-on {
        &[tooltip] {
            position:relative;
            &::after {
                content: attr(tooltip);
                position: absolute;
                left:50%;
                top: -6px;
                transform: translateX(-50%) translateY(-100%);
                background: var(--color-icon-btn-label-bg);
                font: var(--editor-ui-b3-semibold);
                color: var(--color-icon-btn-label-color);
                letter-spacing: 0.12px;
                text-align: center;
                text-transform: none;
                border-radius: 4px;
                pointer-events: none;
                padding: 8px 12px;
                z-index:99;
                opacity:0;
                white-space: nowrap;
                transition: opacity .2s ease-in-out, top .2s ease-in-out;
            }
            &:hover {
                &::before,
                &::after {
                    opacity:1;
                }
            }
        }
        &.tooltip-slim {
            &::after {
                font-size: 13px;
                padding: 8px;
            }
        }
        &.large {
            cursor: pointer;
            &::after {
                min-width: 140px;
                text-align: center;
            }
        }

        // positions
        &[tooltip-position='top'] {
            &::before,
            &::after {
                left:50%;
            }
        }
        &[tooltip-position='right'] {
            &::before,
            &::after {
                left:100%;
                top:50%;
            }
            &::before {
                margin-left:1px;
                transform:translateY(-50%) rotate(90deg);
            }
            &::after {
                margin-left:8px;
                transform: translateX(0%)   translateY(-50%);
            }
        }
        &[tooltip-position='top-right'] {
            &::after {
                left: -3px;
                top: -40px;
                transform: translateX(0px) translateY(0%);
            }
            &::before {
                left: 6px;
                top: -12px;
                transform: translateY(50%);
                content: '';
                position: absolute;
                border-width: 6px;
                border-style: solid;
                border-color: var(--color-icon-btn-label-bg) transparent transparent transparent;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
                z-index: 98;
            }
        }
        &[tooltip-position='bottom'] {
            &::before,
            &::after {
                top:100%;
                margin-top:8px;
            }
            &::before {
                transform: translateX(-50%) translateY(-100%) rotate(-180deg);
            }
            &::after {
                transform: translateX(-50%) translateY(0%);
            }
        }
        &[tooltip-position='left'] {
            &::before,
            &::after {
                left:0%;
                top:47%;
            }
            &::before{
                position: absolute;
                margin-left:-12px;
                transform:translateY(-50%) rotate(-90deg)
            }
            &::after{
                margin-left:-8px;
                transform: translateX(-100%) translateY(-50%);
            }
        }
    }
}
</style>