<template>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 25.6666L13.6583 23.0999L20.1667 29.6083V7.33325H23.8333V29.6083L30.3417 23.0999L33 25.6666L22 36.6666L11 25.6666Z" fill="#0F0F0F"/>
    </svg>
</template>

<script>
    export default {
        name: "ArrowLargeIcon"
    }
</script>

<style lang="scss" scoped>
    svg {
        path {
            fill: var(--color-text)
        }
    }
</style>