<template>
    <transition-group
        v-if="animationStyle && animationStyle !== 'NONE' && $store.state.triggerAnimation"
        appear
        @before-enter="beforeEnter"
        @enter="enter"
        :css="false"
    >
        <slot name="animationContent" />
    </transition-group>
    <template v-else>
        <slot name="animationContent" />
    </template>
</template>

<script>
    import gsap from "gsap";
    import ScrollTrigger from "gsap/ScrollTrigger";
    import { onMounted, onUnmounted } from 'vue'

    export default {
        name: 'animation-wrapper',
        components: {},
        props: {
            delayAnimation: {
                default: 0,
                type: Number,
            },
            animationStyle: {
                default: "NONE",
                type: String,
            },
            animationSpeed: {
                default: 0,
                type: Number,
            },
            disableAnimation: {
                default: false,
                type: Boolean,
            },
            isFinalSection: {
                default: false,
                type: Boolean,
            },
        },
        setup(props) {
            let context;
            gsap.registerPlugin(ScrollTrigger);
            const beforeEnter = (el) => {
                if (props.disableAnimation || props.animationStyle === "NONE") return;

                el.style.willChange = "transform";
                el.style.opacity = 0;

                // Lookup object instead of if-else chain
                const animationStyles = {
                    RISE: "translateY(10px)",
                    PAN_LEFT: "translateX(10px)",
                    PAN_RIGHT: "translateX(-10px)",
                    MOVE_IN: "translate(-10px, 10px)",
                    BRING_IN: "translate(-10px, -10px)",
                    FALL: "translateY(-10px)",
                    SCALE: "scale(0.98)",
                };

                if (animationStyles[props.animationStyle]) {
                    el.style.transform = animationStyles[props.animationStyle];
                }
            };

            const enter = (el) => {
                if(!props.disableAnimation && props.animationStyle !== "NONE") {
                    const start = props.isFinalSection ?
                        "top bottom" :
                        "top bottom-=120px";

                    context = gsap.context(() => {
                        // Animation definition
                        const animation = gsap.to(el, {
                            duration: props.animationSpeed,
                            delay: props.delayAnimation,
                            ease: "sine.out",
                            opacity: 1,
                            scale: 1,
                            x: "0",
                            y: "0",
                            paused: true,
                            clearProps: "transform,opacity"
                        });

                        // On enter trigger
                        ScrollTrigger.create({
                            trigger: el,
                            start: start,
                            // markers: {
                            //     startColor: "green",
                            //     endColor: "transparent",
                            //     fontSize: "12px",
                            // },
                            onEnter: () => {
                                if(animation.progress() === 0) {
                                    animation.restart(true);
                                }
                            }
                        });

                        // On leave trigger
                        ScrollTrigger.create({
                            trigger: el,
                            onLeaveBack: () => animation.pause(0)
                        });
                    });
                }
            }

            onMounted(() => {
                ScrollTrigger.refresh(true);
            });

            onUnmounted(() => {
                if(context) {
                    context.kill();
                }
            });

            const updateAnimationSetting = () => {
                ScrollTrigger.refresh(true);
            }

            const recalculateTriggers = () => {
                setTimeout(() => {
                    ScrollTrigger.refresh(true);
                }, 10);
            }

            return {
                beforeEnter,
                enter,
                updateAnimationSetting,
                recalculateTriggers
            }
        },
        watch: {
            animationStyle: function () {
                this.updateAnimationSetting();
            },
            animationSpeed: function () {
                this.updateAnimationSetting();
            },
            "$store.state.showSidePanel": function (newValue, oldValue) {
                if(newValue !== oldValue) {
                    this.recalculateTriggers();
                }
            },
            "$store.state.recalculateScrollTrigger": function (newValue, oldValue) {
                if(newValue !== oldValue) {
                    this.recalculateTriggers();
                }
            },
            "$store.state.portfolio.templateSettings.theme": function (newValue, oldValue) {
                if(newValue !== oldValue) {
                    this.recalculateTriggers();
                }
            },
        },
    }
</script>
