<template>
    <div class="nav-url-edit">

        <div class="navbar-toggle" @click.stop="dropdownOpen = !dropdownOpen">
            <NavBarPill
                :label="portfolioURL"
                :active="dropdownOpen"
                dark-scheme
                icon-name="context-menu"
            />
        </div>

        <transition name="drop-down-from-toolbar">
            <ClickOutsideObserver :is-active="dropdownOpen" @click-outside="dropdownOpen = false">
                <div v-if="dropdownOpen" class="url-edit-container">
                    <div class="body-container">
                        <p class="title-text">{{pageIsCaseStudy ? 'Case study' : 'Portfolio'}} URL</p>
                        <MessageHighlight
                            v-if="notPublished"
                            dark-scheme
                            type="info"
                            :message="`Publish your ${pageIsCaseStudy && !portfolio.draft ? 'case study' : 'portfolio'} to get a shareable link. Go back to the editor to <b>publish</b>`"
                        />
                        <div v-else class="url-container">
                            <div class="card" @click="copyUrl">
                                <span>
                                    {{copied ? 'Link copied!' : portfolioURL}}
                                </span>
                                <IconButton dark-scheme size="small" :icon="copied ? 'check' : 'copy'" importance="low" />
                            </div>

                            <div class="url-type">
                                {{ !isOwnDomain(portfolioURL) ? 'This is a default UXfolio URL.' : 'Your domain' }}
                            </div>
                        </div>
                    </div>
                    <div class="dd-container">
                        <div class="dd-select-items">
                            <a :class="['dd-select-item', { 'blocked': blocked }]"
                               @click="openDomain">
                                <Icon :height="24" :width="24" :icon-name="'web'"/>
                                Custom Domain
                                <Tag
                                    v-if="blocked"
                                    icon-name="lock"
                                    text="Upgrade to unlock"
                                    dark-scheme
                                    @click.stop="openPrices"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </ClickOutsideObserver>
        </transition>
    </div>
</template>

<script>


import Icon from "../../../common/Icon.vue";
import UrlCardCopy from "../../../common/components/UrlCardCopy.vue";
import NavBarPill from "../../../common/editorNavBar/NavBarPill.vue";
import ClickOutsideObserver from "../../../common/utilities/ClickOutsideObserver.vue";
import MessageHighlight from "../../../common/components/MessageHighlight.vue";
import IconButton from "../../../common/components/IconButton.vue";
import {event} from "vue-gtag";
import {Features} from "../../../common/enums.js";
import {isFeatureAvailable} from "../../../common/helpers.js";
import Tag from "../../../common/components/Tag.vue";
import MixpanelService from "../../../analytics/mixpanel.js";

export default {
    name: "UrlEditNavContainer",
    components: {
        IconButton,
        MessageHighlight,
        ClickOutsideObserver,
        NavBarPill,
        UrlCardCopy,
        Icon,
        Tag
    },
    data() {
        return {
            dropdownOpen: false,
            copied: false
        }
    },
    methods: {
        isOwnDomain(url) {
            return !url.includes("uxfol.io/") && !url.includes("herokuapp.com") && !url.includes("localhost:")
        },
        openDomain() {
            if(this.blocked) return
            this.$router.push(`/home/domain/search`);
        },
        openPrices() {
            MixpanelService.track("UPGRADE_CTA_CLICKED",
                {
                    ...MixpanelService.getUrls(),
                    "event_group": MixpanelService.MixpanelEventGroups.USER_LIFECYCLE,
                    "source": "Url dropdown",
                    "context": `View - ${this.pageIsCaseStudy ? 'Case study' : 'Portfolio'}`,
                    "to": "Prices"
                }
            )

            this.$router.push('/home/prices');
        },
        copyUrl() {
            navigator.clipboard.writeText(this.portfolioURL);
            this.copied = true;

            setTimeout(() => {
                this.copied = false;
            }, 3000)

            event('Copy portfolio share link', {
                'event_category': 'Portfolio editor',
                'event_label': `User: ${this.$store.state.user._id}, ${this.portfolioURL}`
            });
        },
    },
    computed: {
        portfolio() {
            return this.$store.state.portfolio;
        },
        portfolioURL() {
            return this.$store.getters.pageViewURL;
        },
        pageIsCaseStudy() {
            return this.$store.getters.isCaseStudy;
        },
        blocked() {
            return !isFeatureAvailable(Features.CUSTOM_DOMAIN, this.$store.state.userSettings.currentSubscriptionPlan, this.$store.state.userSettings.currentSubscription?.status);
        },
        notPublished() {
            if (this.pageIsCaseStudy) {
                return this.portfolio.draft ? true : this.$store.state.project.draft
            } else {
                return this.portfolio.draft
            }
        }
    }

}
</script>

<style scoped lang="scss">
.nav-url-edit {
    position: relative;

    .navbar-toggle button {
        outline: none;
        flex-direction: row-reverse;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid var(--asphalt90);
        background: var(--asphalt95);
        height: 36px;

        &:hover, &.selected {
            border-radius: 8px;
            border: 1px solid var(--asphalt70);
            background: var(--asphalt90);
        }
    }

    .url-edit-container {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 55px;
        display: flex;
        width: 460px;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid var(--asphalt90);
        background: var(--asphalt95);

        /* Dark/Medium */
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.40), 0 16px 24px 0 rgba(0, 0, 0, 0.32);

        .body-container {
            display: flex;
            padding: 24px;
            flex-direction: column;
            justify-content: center;
            gap: 24px;

            .title-text {
                margin:0;
                color: var(--asphalt10);
                font: var(--editor-ui-b1-semibold);
            }

            .url-container {
                display: flex;
                flex-direction: column;
                gap: 12px;
                color: var(--asphalt40);

                .card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    gap: 8px;
                    border-radius: 6px;
                    padding: 16px 14px 16px 16px;
                    font: var(--editor-ui-b2-medium);
                    color: var(--asphalt30);
                    height: 48px;
                    cursor: pointer;
                    background: var(--asphalt90);

                    button.dark.low.small {
                        padding: 6px 8px;
                        border-radius: 4px;
                    }
                    span {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    &:hover {
                        color: var(--asphalt10);
                        background: var(--asphalt80);

                        button.dark.low.small {
                            color: var(--asphalt10);
                        }
                    }
                }
                .url-type {
                    font: var(--editor-ui-b3-regular);
                    padding: 0 12px;
                }
            }
        }

        .dd-container {
            display: flex;
            padding: 8px 0;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            border-top: 1px solid var(--asphalt90);

            .dd-select-items {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .dd-select-item {
                padding: 12px 24px;
                color: var(--asphalt40);
                font: var(--editor-ui-b2-medium);
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                gap: 8px;
                &:not(.blocked):hover,
                &:not(.blocked):focus {
                    color: var(--asphalt10);
                }
                &.blocked {
                    cursor: default;
                }
            }
        }
    }
}
</style>