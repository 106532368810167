<template>

    <main class="landing coming-soon">

        <Header class="soon" coming-soon />

        <section class="page-content">
            <div class="ufo--container">
                <h1>Coming soon</h1>
                <h2>This {{type}} has not been published yet. Come back later to view it!</h2>
            </div>
        </section>
    </main>
</template>

<script>

import Header from "../view/publicPages/components/Header.vue";

export default {
    name: 'coming-soon',
    props: ['type'],
    components: {
        Header
    },
    mounted() {
        const initUserMode = this.getTheme() || this.getMediaPreference();
        this.setMode(initUserMode);
    },
    methods: {
        setMode(mode) {
            localStorage.setItem("uxfolio-user-theme", mode);
            document.documentElement.className = mode;
            this.$store.state.view.publicPages.activeMode = mode;
        },
        getMediaPreference() {
            const hasDarkPreference = window.matchMedia(
                "(prefers-color-scheme: dark)"
            ).matches;

            return hasDarkPreference ? "dark-mode" : "light-mode";
        },
        getTheme() {
            return localStorage.getItem("uxfolio-user-theme");
        },
    },
}
</script>

<style lang="scss" global>
@import "../../styles/public-pages/reboot";
@import "../../styles/public-pages/general";
</style>

<style lang="scss" scoped>


main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 56px;
    background: var(--color-bg) url("../../images/illustrations/squared-bg.svg");
}

.coming-soon {

    :deep(header) {
        display: flex;
        justify-content: center;
    }

    .page-content {
        width: 100%;
        height: calc(100% - 98px);
        display: flex;
        flex-direction: column;
        align-items: center;

        .ufo--container {
            width: 524px;
            height: 75vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: var(--color-bg);
            padding: 48px;
            text-align: center;

            h1 {
                color: var(--color-text);
                font: var(--font-h2);
            }
            h2 {
                color: var(--color-form-elem-text-disabled);
                font: var(--font-b2);
            }
        }
    }
}


</style>