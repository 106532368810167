<template>
    <component v-if="currentComponent !== 'ErrorPage' && currentComponent !== 'ComingSoon'" :is="currentMode">
        <component :is="currentComponent" v-bind="currentProps" />
    </component>
    <component v-else :is="currentComponent" v-bind="currentProps" />
</template>

<script>
    import {checkFonts} from "../../settingsEditor/_helpers/fontLoaders.js";
    import GoogleFontsHelper from "../../common/GoogleFontsHelper.js";
    import ApiHelper from "../../api/ApiHelper.js";
    import ReviewMode from "./ReviewMode.vue";
    import ReceiverMode from "./ReceiverMode.vue";

    //Pages
    import PortfolioPage from "../portfolio/Portfolio.vue";
    import Project from "../portfolio/Project.vue"
    import CustomPage from "../portfolio/Page.vue";
    import PasswordProtection from "../portfolio/common/PasswordProtection.vue";
    import ErrorPage from "../common/ErrorPage.vue";
    import {resolveColor} from "@/scripts/common/helpers.js";
    import ComingSoon from "../../common/ComingSoon.vue";

    export default {
        name: "ReviewWrapper",
        components: {
            ReviewMode,
            PortfolioPage,
            Project,
            CustomPage,
            PasswordProtection,
            ErrorPage,
            ReceiverMode,
            ComingSoon
        },
        props: ['review'],
        data() {
            return {
                loaded: false,
                pageType: null,
                projectShortId:null,
                error:false
            }
        },
        mounted() {
            //Global reiew mode is active.
            this.$reviewStore.isReviewMode = true;
            //Set store if we have review or reciever mode
            this.$reviewStore.receiver = !this.review
        },
        //This runs if user don't have owndomain adress
        async beforeRouteEnter(to, from, next) {
            try {
                let url = '/data'
                let portfolioShortId = to.params.portfolioShortId;

                if(portfolioShortId) {
                    url += '/shortid/' + portfolioShortId
                    if(to.params.projectShortId) {
                        url += '/' + to.params.projectShortId
                    }
                }
                else {
                    url += '/portfolio'
                }

                let res = await ApiHelper.get(url + "?isReview=true");

                next(vm => vm.setData(res.status, res.data))
            }
            catch(err) {
                next(vm => vm.setData(err, null))
            }
        },
        async beforeRouteUpdate(to, from) {
            try {
                let url = this.projectFetchUrl(to)
                let res = await ApiHelper.get(url + "?isReview=true");
                let data = res.data;
                this.setData(null, data);
            }
            catch(err) {
                console.log('error', err)
            }
        },
        methods: {
            setData(err, data) {
                if(err && !data) {
                    if(err.response.status === 404) {
                        this.pageType = 'not_found'
                        this.error = true;
                        this.loaded = true;
                        return;
                    } else if (err.response.status === 401) {
                        this.pageType = 'coming_soon';
                        this.loaded = true;
                        return;
                    }
                }

                //Load fonts
                if(!this.$store.state.view.fontsLoaded) {
                    checkFonts(data.fonts)
                    //load legacy fonts
                    let legacyFonts = data.fontNames.filter((fontName) => !data.fonts.map(x => x.name).includes(fontName))
                    legacyFonts.forEach((name) =>{
                        GoogleFontsHelper.getFontURL(name);
                    })
                    this.$store.state.view.fontsLoaded = true;
                }

                this.password = false;

                let viewData = {
                    portfolio:data.portfolio,
                    project:data.project,
                    user:data.user,
                    portfolioPages:data.portfolioPages,
                    activePage:data.project.shortId,
                    footerProjects:data.footerProjects ? data.footerProjects : [],
                    caseStudies:data.caseStudies,
                    hasDraftCasestudies:data.hasDraftCasestudies,
                    loggedInUserId:data.loggedInUserId,
                    emergencyModeSettings:data.emergencyModeSettings
                }

                //Update current page
                if(data.project.type === 'home') {
                    this.pageType = "portfolio";
                }
                else {
                    if(data.project.type === 'caseStudy') {
                        this.pageType = "project";
                    }
                    else {
                        this.pageType = "custom"
                    }
                }

                this.$store.commit('updateView', viewData);

                const target = document.querySelector("body");

                if(data.pageType === 'project') {
                    target.style.backgroundColor = resolveColor(
                        data.project.designSettings.colorStyles.background,
                        data.project.designSettings.colorPalette
                    );
                }
                else {
                    target.style.backgroundColor = resolveColor(
                        data.portfolio.designSettings.colorStyles.background,
                        data.portfolio.designSettings.colorPalette
                    );
                }

                if(!data.portfolio.templateSettings.theme.includes('profile_')) {
                    try {
                        import(`../../../styles/_${data.portfolio.templateSettings.theme.replaceAll('_', '-').replace('profile_', '')}-base.scss`);
                    }
                    catch (e) {
                        console.log('failed to import scss')
                    }
                }

                //TODO: figure this ourt
                this.$store.state.portfolio = data.portfolio
                this.$store.state.project = data.project

                this.loaded = true;
            },
            projectFetchUrl(to) {
                let url = '/data/project'
                let projectShortId = to.params.projectShortId;
                let portfolioShortId = to.params.portfolioShortId;

                if(portfolioShortId) {
                    url += "/" + portfolioShortId;
                }

                if(projectShortId) {
                    url += "/" + projectShortId;
                }

                return url;
            }
        },
        computed: {
            currentComponent() {
                if (!this.loaded) return null;
                switch (this.pageType) {
                    case 'project': return 'Project';
                    case 'custom': return 'CustomPage';
                    case 'not_found': return 'ErrorPage';
                    case 'portfolio': return 'PortfolioPage';
                    case 'coming_soon': return 'ComingSoon';
                    default: return null;
                }
            },
            currentProps() {
                if (this.error) {
                    return {
                        error: {
                            code: 404,
                            title: 'This page doesn’t exist',
                            message: 'We can’t seem to find the page you are looking for'
                        }
                    };
                }
                if (this.pageType === 'coming_soon') {
                    return { type: 'Case Study' };
                }
                return {};
            },
            currentMode() {
                return this.review ? 'ReviewMode' : 'ReceiverMode';
            }
        }
    }
</script>

<style lang="scss">
    .review-wrapper {
        .ufo-sec--navigation {
            .sticky-header {
                &.sticked {
                    top:60px;
                }
            }
        }
    }
</style>