function topLogo(theme) {
    let avalibleTemplates = ['theme_seventeen']
    return avalibleTemplates.includes(theme)
}

function showImageDisplayButtons(theme) {
    const excludedThemes = [
        'theme_three',
        'theme_eight',
        'theme_twelve',
        'theme_six',
        'theme_sixteen',
    ];
    return !excludedThemes.includes(theme);
}

function heroDescriptionLeftAlignment(theme) {
    const includedThemes = [
        'theme_thirteen',
        'theme_fifteen'
    ];
    return includedThemes.includes(theme);
}

function logoIsSupportedInTemplate(theme) {
    // logo supported in the following templates
    // Nelson 13, Otis 12, Bauhaus 11, Orielle 10, Nominee 8, Panorama 5, Poster
    // logo not supported
    let notAllowedInThemes = ['theme_one', 'theme_two', 'theme_three', 'theme_six', 'theme_seven', 'theme_eleven'];
    return !notAllowedInThemes.includes(theme) && ( !theme.includes('profile_') || theme === 'profile_theme_eight' ) // it is not allowed in legacy themes
}

function roundImageIsAvalible(theme) {
    let roundImageTemplates = ['theme_default', 'theme_three', 'theme_eight', 'theme_nine', 'theme_ten', 'theme_elven', 'theme_twelve']
    return roundImageTemplates.includes(theme);
}

function footerAllowedInTheme(theme) {
    let footerDisabledForThemes = ['theme_four', 'profile_theme_four', 'theme_nine', 'profile_theme_nine', 'theme_default', 'profile_theme_default'];
    return !footerDisabledForThemes.includes(theme);
}

function heroDescriptionUpDownButtonAllowedInTheme(theme) {
    let heroDescriptionUpDownButtonDisabledForThemes = ['theme_twelve', 'theme_six', 'theme_fourteen', 'theme_fifteen', 'theme_seventeen', 'theme_eighteen'];
    return !heroDescriptionUpDownButtonDisabledForThemes.includes(theme);
}

function foregroundImageAvalible(theme) {
    let disabledThemes = ['theme_three', 'theme_five', 'theme_thirteen', 'theme_fourteen', 'theme_fifteen', 'theme_seventeen', 'theme_eighteen'];
    return !disabledThemes.includes(theme);
}

function avalibleHeroTextAlignments(theme) {
    if(theme === 'theme_seventeen') {
        return []
    }
    else {
        return ['left', 'center', 'right']
    }
}


const TemplateRestrictions = {
    topLogo: topLogo,
    showImageDisplayButtons: showImageDisplayButtons,
    heroDescriptionLeftAlignment:heroDescriptionLeftAlignment,
    logoIsSupportedInTemplate: logoIsSupportedInTemplate,
    roundImageIsAvalible:roundImageIsAvalible,
    footerAllowedInTheme: footerAllowedInTheme,
    heroDescriptionUpDownButtonAllowedInTheme: heroDescriptionUpDownButtonAllowedInTheme,
    foregroundImageAvalible: foregroundImageAvalible,
    avalibleHeroTextAlignments:avalibleHeroTextAlignments,
};

export default TemplateRestrictions;