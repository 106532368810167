<template>
    <div :class="['tag', {'dark': darkScheme}]">
        <Icon :width="16" :height="16" :icon-name="iconName" />
        {{text}}
    </div>
</template>

<script>


import Icon from "../Icon.vue";

export default {
    name: "Tag",
    components: {
        Icon
    },
    props: {
        text: {
            type: String,
            default: ''
        },
        iconName: {
            type: String
        },
        darkScheme: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
.tag {
    display: inline-flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--asphalt20);
    font: var(--editor-ui-b3-medium);
    color: var(--asphalt50);
    cursor: pointer;

    &.dark {
        background: var(--asphalt90);
        color: var(--asphalt40);
        &:hover {
            background: var(--asphalt70);
            color: var(--asphalt10)
        }
    }

    &:hover {
        background: var(--asphalt10);
        color: var(--asphalt70)
    }
}

</style>