<template>
    <div v-bind="$attrs" class="mockup-svg-container" :class="{shadow:isShadowEnabled}">
        <InlineSvg ref="inlineSvg" :src="svgSrc" @loaded="svgLoaded" />
    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import {MockupType} from "@/scripts/common/mockupStore/mockupStructure.js";

export default {
    name: "Mockup",
    props: {
        svgSrc: { type: String, required: true },
        imageSrc: { type: String, required: false },
        media: { type: Object },
        altTag: { type: String },
        imageLoaded: { type: Function, default: null },
        setLightBox: { type: Boolean, default: false },
        index: { type: Number },
        shadow: {type:Boolean, default:false},
        mockup: {type:Object},
        isThumbnail:{type:Boolean, default:false}
    },
    components: { InlineSvg },
    data() {
        return {
            imgElement: null,
            foreignObject: null,
            screenArea: null,
            containerDiv:null,
            mockupId:null,
            defs:null,
            fullSvg:null
        };
    },
    watch: {
        imageSrc(newSrc) {
            if (this.imgElement) {
                if(!newSrc) {
                    this.containerDiv.style.background = "linear-gradient(161deg, #8F8F8F 12.93%, #515151 89.74%)";
                }
                this.imgElement.src = newSrc;
            }
        },
    },
    computed: {
        isShadowEnabled() {
            return this.shadow && this.mockup.mockupType !== MockupType.OUTLINE && this.mockup.mockupType !== MockupType.SEMI_REALISTIC
        }
    },
    methods: {
        svgLoaded(event) {
            this.onSvgLoad(event);
        },
        async onSvgLoad(event) {
            const svg = event;
            if (!svg) return;

            this.fullSvg = svg;

            // Locate the screen area within the SVG
            this.screenArea = svg.querySelector('rect[fill="#6D6D6D"]') || svg.querySelector('path[fill="#6D6D6D"]') || svg.querySelector(`#${this.mockupId}`);
            if (!this.screenArea) {
                console.log("No screen area path found: ", this.svgSrc);
                return;
            }else {
                this.mockupId = `unique_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
                this.screenArea.setAttribute('id', this.mockupId);
            }

            let darkScheme = this.svgSrc.includes('dark');
            const noDevice = this.svgSrc.includes('minimal');
            const isRealistic = this.mockup.mockupType === MockupType.REALISTIC
            this.screenArea.setAttribute('fill', noDevice ? 'transparent' : (darkScheme || isRealistic) ? 'black' : 'white');

            // Create the <foreignObject> for scrollable content
            this.foreignObject = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");

            this.foreignObject.style.position = "relative";
            this.foreignObject.style.pointerEvents = "all";
            this.foreignObject.classList.add("mockup-screen");


            const wrapperDiv = document.createElement("div");
            wrapperDiv.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
            wrapperDiv.classList.add("wrapper");

            const borderRadius = this.extractBorderRadius(this.screenArea);
            //set dynamic border radiuses from screen
            wrapperDiv.style.borderRadius = `${borderRadius}`;
            wrapperDiv.style.overflow = "hidden";


            // Container for the scrollable image content
            this.containerDiv = document.createElement("div");
            this.containerDiv.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
            this.containerDiv.classList.add(this.isThumbnail ? "container-mockup": "scrollable-container-mockup");

            if(!this.imageSrc) {
                this.containerDiv.style.background = "linear-gradient(161deg, #8F8F8F 12.93%, #515151 89.74%)";
            }

            this.imgElement = document.createElement("img");
            this.imgElement.src = this.imageSrc;
            this.imgElement.setAttribute("alt", this.altTag || "");

            // Dynamically add classes based on frame and forced aspect ratio
            if (this.media) {
                if (this.forcedAspectRatio) {
                    this.imgElement.classList.add(`aspect-ratio-${this.forcedAspectRatio}`);
                }
            }

            // Set up the @load functionality if imageLoaded is provided
            this.imgElement.onload = () => {
                if (this.imageLoaded) {
                    this.imageLoaded(this.imgElement);
                }
            };

            // Ensure proper display for the image
            this.imgElement.style.width = "100%";
            this.imgElement.style.height = "auto";
            this.imgElement.style.display = "block";
            this.imgElement.style.objectPosition = "top left";
            this.imgElement.style.maxHeight = "unset";

            wrapperDiv.appendChild(this.containerDiv);
            // Append elements
            this.containerDiv.appendChild(this.imgElement ? this.imgElement : '');
            this.foreignObject.appendChild(wrapperDiv);
            svg.appendChild(this.foreignObject);

            this.reorderSmallerElements(svg);
            this.updateScreenLayout();
        },
        extractBorderRadius(element) {
            if (element.tagName === "rect") {
                const rx = parseFloat(element.getAttribute("rx") || "0");
                const ry = parseFloat(element.getAttribute("ry") || rx);
                return `${rx}px ${rx}px ${ry}px ${ry}px`;
            } else if (element.tagName === "path") {
                let isLegacy = this.svgSrc.includes('v1');
                if(this.svgSrc.includes('mobile')) {
                    if(isLegacy && !this.svgSrc.includes('semirealistic')) {
                        return "32px"
                    }
                    if(this.svgSrc.includes('browser')) {
                        return "0px 0px 17px 17px"
                    }
                    return "52px"
                }
                if(this.svgSrc.includes('tablet')) {
                    if(isLegacy) {
                        return "16px"
                    }
                    if(this.svgSrc.includes('browser')) {
                        return "0px 0px 17px 17px"
                    }
                    return "29px"
                }
                if(this.svgSrc.includes('laptop')) {
                    if(this.svgSrc.includes('browser')) {
                        return "0px 0px 17px 17px"
                    }
                }
                if(this.svgSrc.includes('desktop')) {
                    if(this.svgSrc.includes('browser')) {
                        return "0px 0px 17px 17px"
                    }
                }
                return "0px 0px 0px 0px"; // Default if no border radius is found
            }

            return "0px 0px 0px 0px"; // Default if no border radius is found
        },
        reorderSmallerElements(svg) {
            if (!this.screenArea) return;

            const screenBBox = this.screenArea.getBBox();
            const smallerElements = [];

            svg.querySelectorAll('rect, path, circle').forEach(element => {
                const bbox = element.getBBox();
                if (
                    bbox.width < screenBBox.width &&
                    bbox.height < screenBBox.height &&
                    (element.tagName === 'circle' || //camera
                        ((bbox.width > 118 && bbox.height < 80) || (bbox.height > 118 && bbox.width < 80)) //Notch, island
                    ) && //TODO: Only find the notch with the cameras
                    element !== this.screenArea // Exclude the screen area itself
                ) {
                    smallerElements.push(element);
                }
            });

            // Move smaller elements to the end of the SVG to bring them to the front
            smallerElements.forEach(element => {
                svg.appendChild(element);
            });
        },
        updateScreenLayout() {
            if (!this.foreignObject || !this.screenArea) return;

            const bbox = this.screenArea.getBBox();
            //TODO: better solution
            let screenWidth = bbox.width + 4;
            let screenHeight = bbox.height + 4;
            let screenX = bbox.x - 2;
            let screenY = bbox.y - 2;

            this.foreignObject.setAttribute("x", screenX);
            this.foreignObject.setAttribute("y", screenY);
            this.foreignObject.setAttribute("width", screenWidth);
            this.foreignObject.setAttribute("height", screenHeight);
        },
    }
}
</script>

<!-- Styles for scrollbars and container -->
<style lang="scss">

.mockup-screen {
    pointer-events: all;
    overflow: hidden;
    padding: 2px;
}

.wrapper {
    border-radius: inherit;
    height: 100%;
}

.container-mockup {
    height: 100%;
    pointer-events: all !important;
}

.scrollable-container-mockup {

    /* WebKit Browsers */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    height: 100%;
    overflow-y: auto;
    pointer-events: all !important;
}

.mockup-svg-container {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    height: 100%;

    &.shadow {
        filter: drop-shadow(0px 20px 44px rgba(0, 0, 0, 0.2));
    }

    img {
        border-radius: inherit;
        max-height: unset;
        height: unset;
    }
    svg {
        width: 100%;
        height: 100%;
        max-height: 715px;
        transition: transform 0.3s ease-in-out;
    }
}
</style>
