// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.option-bar-group-container[data-v-77660ef6] {
        padding: 4px;
        gap: 8px;
        background: var(--asphalt90);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/scripts/common/sideBars/components/OptionBarGroup.vue"],"names":[],"mappings":";AAeA;QACQ,YAAY;QACZ,QAAQ;QACR,4BAA4B;QAC5B,kBAAkB;QAClB,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,mBAAmB;QACnB,WAAW;AACnB","sourcesContent":["\n\n\n.option-bar-group-container {\n        padding: 4px;\n        gap: 8px;\n        background: var(--asphalt90);\n        border-radius: 4px;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
