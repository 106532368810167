<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
        :delay-animation="animationDelay"
    >
        <template #animationContent>
            <div v-if="columnMedia.mockupSettings">
                <MockupContainer :media="columnMedia" />
            </div>
            <PlaceColumnMediaContent v-else :section="section" :column-media="columnMedia" :placeholder-image="placeholderImage"/>
        </template>
    </AnimationWrapper>
</template>

<script>
    import PlaceColumnMediaContent from "./PlaceColumnMediaContent.vue";
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";
    import MockupContainer from "@/scripts/common/components/MockupContainer.vue";

    export default {
        name: "ColumnImageHelper",
        components: {MockupContainer, AnimationWrapper, PlaceColumnMediaContent},
        props: ['section', 'columnMedia', 'placeholderImage', 'index', 'totalColumns'],
        computed: {
            animationDelay() {
                if (this.index || this.index === 0) {
                    if(this.$store.getters.animationSettings.animationStyle === "SCALE") {
                        return 0;
                    } else if(this.$store.getters.animationSettings.animationStyle === "PAN_LEFT") {
                        return (this.totalColumns - 1 - this.index) * 0.1;
                    } else {
                        return this.index * 0.1;
                    }
                } else {
                    return 0;
                }
            }
        }
    }
</script>