// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button-container {
span[data-v-7b0214a1] {
            font:var(--editor-ui-b2-regular);
            color: var(--asphalt40);
}
}
`, "",{"version":3,"sources":["webpack://./src/scripts/view/review/modals/loginModals/FollowUpLogin.vue"],"names":[],"mappings":";AAoGI;AACI;YACI,gCAAgC;YAChC,uBAAuB;AAC3B;AACJ","sourcesContent":["\n    .button-container {\n        span {\n            font:var(--editor-ui-b2-regular);\n            color: var(--asphalt40);\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
