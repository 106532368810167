<template>
    <div :class="['ufo-input', {dark: darkScheme}]">
        <label v-if="label" class="inside-label" :class="{'focus' : focused || modelValue}">{{ label }}</label>
        <input :type="type"
               :name="name"
               :value="modelValue"
               :placeholder="(focused || !label) ? placeholder : label"
               :readonly="readOnly"
               :disabled="disabled"
               :maxlength="maxLength"
               :class="{'error alert' : hasError, 'down': focused || modelValue }"
               :min="range.min"
               :max="range.max"
               @hover="handleHover"
               @input="handleInput"
               @focus="handleFocus"
               @blur="blur"
               @keyup="handleKeyup"
               @keydown="handleKeydown"
               @mouseenter="handleMouseEnter"
               @mouseleave="handleMouseLeave"
        />
        <div v-if="hasAssistSlot && displayAssist" class="assist">
            <slot name="assist"></slot>
        </div>
        <div v-if="hasFeedbackSlot && displayFeedback" class="feedback">
            <slot name="feedback"></slot>
        </div>
        <div v-if="hasAlertSlot && displayAlert" class="alert-message">
            <slot name="alert"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'input-field',
        emits: ['update:modelValue', 'input', 'keyup', 'keydown', 'mouseenter', 'mouseleave', 'focus', 'blur', 'click'],
        inheritAttrs: false,
        props: {
            label: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: 'text'
            },
            name: {
                type: String,
                default: null
            },
            modelValue: {
                type: [String, Number],
                default: null
            },
            placeholder: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: null
            },
            hasError: {
                type: Boolean,
                default: null
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            displayAssist: {
                type: Boolean,
                default: true
            },
            displayFeedback: {
                type: Boolean,
                default: true
            },
            displayAlert: {
                type: Boolean,
                default: true
            },
            maxLength: {
                type: Number,
                default: null
            },
            range: {
                type: Object,
                default: () => ({
                    min: null,
                    max: null
                })

            },
            darkScheme: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                focused: false,
            }
        },
        computed: {
            hasAssistSlot() {
                return !!this.$slots['assist']
            },
            hasFeedbackSlot() {
                return !!this.$slots['feedback']
            },
            hasAlertSlot() {
                return !!this.$slots['alert']
            }
        },
        methods: {
            handleInput(e) {
                this.$emit('update:modelValue', e.target.value);
                this.$emit('input', e);
            },
            handleKeyup(e) {
                this.$emit('keyup', e);
            },
            handleKeydown(e) {
                this.$emit('keydown', e);
            },
            handleMouseEnter(e) {
                this.$emit('mouseenter', e);
            },
            handleMouseLeave(e) {
                this.$emit('mouseleave', e);
            },
            handleFocus() {
                this.focused = true;
                this.$emit('focus');
            },
            handleHover() {
                this.hover = true;
            },
            blur(e) {
                this.focused = false;
                this.$emit('blur', e);
            },
            click() {
                this.$emit('click');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ufo-input {
        --bgColor: var(--asphalt10);
        --disabledBgColor: var(--asphalt10);

        --outlineColor: var(--asphalt30);
        --hoverOutlineColor: var(--asphalt50);
        --focusOutlineColor: var(--asphalt100);
        --disabledOutlineColor: var(--asphalt20);

        --labelColor: var(--asphalt50);
        --disabledLabelColor: var(--asphalt40);

        --textColor: var(--asphalt100);

        --warningcolor: var(--error50);
        --successColor: var(--success60);

        &.dark {
            --bgColor: var(--asphalt95);
            --disabledBgColor: var(--asphalt90);

            --outlineColor: var(--asphalt70);
            --hoverOutlineColor: var(--asphalt50);
            --focusOutlineColor: var(--asphalt10);
            --disabledOutlineColor: var(--asphalt80);

            --labelColor: var(--asphalt40);
            --disabledLabelColor: var(--asphalt50);

            --textColor: var(--asphalt10);

            --warningcolor: var(--error50);
            --successColor: var(--success60);
        }

        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 4px;

        font: var(--editor-ui-b2-regular);
    }

    .inside-label {
        line-height: 1;
        display: none;
        color: var(--labelColor);
        margin-bottom: 0;

        &.focus {
            font: var(--editor-ui-b3-regular);
            position: absolute;
            display: block;
            left: 12px;
            top: -10px;
            padding: 0 4px;
            background: var(--bgColor);
        }
    }

    input {
        line-height: 24px;
        margin: 0;
        height: 48px;
        padding: 14px 16px;
        background-color: var(--bgColor);
        border: none;
        outline: 1px solid var(--outlineColor);
        border-radius: 4px;
        color: var(--textColor);

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--labelColor);
            opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: var(--labelColor);
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: var(--labelColor);
        }

        &:hover {
            outline-color: var(--hoverOutlineColor);
        }

        &:focus {
            outline-color: var(--focusOutlineColor);
        }

        &[readonly] {
            background-color: var(--disabledBgColor);
            outline-color: var(--disabledBgColor);
            color: var(--labelColor);
            cursor: pointer;
            &:focus {
                outline-color: var(--focusOutlineColor);
            }
        }

        &[disabled],
        &[disabled]:hover{
            color: var(--disabledLabelColor);
            background-color: var(--disabledBgColor);
            outline-color: var(--disabledOutlineColor);
        }

        &[disabled] {
            color: var(--disabledLabelColor);
            background-color: var(--disabledBgColor);
            cursor: not-allowed;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: var(--disabledLabelColor);
                opacity: 1; /* Firefox */
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: var(--disabledLabelColor);
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: var(--disabledLabelColor);
            }
        }

        &.alert {
            outline-color: var(--redBright);
        }

        &[type="number"] {
            padding: 0  28px 0 12px;
            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            -moz-appearance: textfield;
        }
    }

    .assist {
        font: var(--editor-ui-b3-regular);
        margin-top: 8px;
    }
    .alert-message {
        font: var(--editor-ui-b3-regular);
        margin-top: 8px;
        color: var(--redBright);
    }
    .feedback {
        font: var(--editor-ui-b3-regular);
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);

        .success {
            color: var(--success60);
        }
    }
</style>