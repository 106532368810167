<template>
    <ViewNavBar />
    <div class="ufo--portfolio-page" :style="[getTextStyles]">
        <div class="ufo--page-sections">
            <SectionLoader :sections="project.sections" />
        </div>
        <div v-if="!iframeUrl" class="project-footer">
            <!-- TODO: not sure I can delete this <PortfolioFooter v-if="isFooterEnabled"/> -->
            <div v-if="portfolio.settings.showFooterOnPortfolio === null && portfolio.settings.showFooterOnProjects === null">
                <div class="footer-info" :class="{bg: resolveColor(project.designSettings.colorStyles.background, project.designSettings.colorPalette).toLowerCase() !== '#ffffff'}">
                    <div class="grid info" :class="{'project-list': footerProjects.length}">
                        <div class="project-owner">
                            <div class="owner" @click="navigateBack()">
                                <div v-if="user.image.url" class="owner-picture">
                                    <img loading="lazy" height="60" :src="user.image.url" alt="User image"/>
                                </div>
                                <div class="owner-info">
                                    <div v-if="user.name" class="owner-name">{{user.name}}</div>
                                    <div v-if="user.title" class="owner-company">{{user.title}}</div>
                                </div>
                            </div>
                            <div class="footer-links">
                                <a v-bind:key="page" v-for="page in portfolioPages" class="footer-btn" :href="footerHref(page)">{{ page.name }}</a>
                            </div>
                        </div>
                        <div v-if="footerProjects && footerProjects.length" class="project-owner-projects" :class="{'long-list': footerProjects.length && (footerProjects.length > 6 || footerProjects.sort(a, b => a.name < b.name)[0].length === 0) }">
                            <div class="title-line">See more of my work:</div>
                            <div class="owner-projects-content">
                                <div v-bind:key="fProject" v-for="fProject in footerProjects" @click="navigateToProjectFooter(portfolio.ownDomainAddress ? portfolio.ownDomainAddress : '', portfolio.shortId, fProject.shortId)" class="f-project related-project">
                                    <div class="image">
                                        <ProjectThumbnail v-if="fProject.settings.usingGeneratedThumbnail" :caseStudy="fProject" force-squared />
                                        <img v-else-if="fProject.image.url" loading="lazy" width="70" :src="fProject.image.croppedImageUrl ? fProject.image.croppedImageUrl : fProject.image.url" :alt="fProject.name">
                                        <img v-else-if="fProject.sections[0].type === 'MainHeader' && fProject.sections[0].image && fProject.sections[0].image" loading="lazy" width="70" :src="fProject.sections[0].image.croppedImageUrl ? fProject.sections[0].image.croppedImageUrl : fProject.sections[0].image.url" :alt="fProject.name">
                                        <img v-else-if="!fProject.sections[0].backgroundColor" loading="lazy" width="70" src="/example_project_images/v2/empty-project-thumbnail.jpg" :alt="fProject.name">
                                    </div>
                                    <div v-if="footerProjects.length && (footerProjects.length < 7)" class="title">
                                        <h4>{{ getProjectName(fProject) }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- UXfolio branding -->
            <div v-if="user.accountSettings.folioLogo" class="footer-share">
                <div class="grid">
                    <div class="branding">
                        <a href="https://uxfol.io/?utm_campaign=branding_header" rel="nofollow" class="ufo--branding floating">
                            Made with <UXFolioLogo />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-to-top" @click="scrollToTop" :class="{'nav-on': portfolio.settings && portfolio.settings.showNavOnCollectionItems }"></div>
    </div>
</template>

<script>
import {useHead} from '@unhead/vue'
import SectionLoader from "./sections/SectionLoader.vue";
import UXFolioLogo from "../components/UXFolioLogo.vue";
import ProjectThumbnail from "../../sections/projectGrid/ProjectThumbnail.vue";
import {resolveColor} from "@/scripts/common/helpers.js";
import ViewNavBar from "./ViewNavBar.vue";
import {
    convertRichTextHTMLToPlainText
} from "@/scripts/common/components/textEditor/utilities/textConverterUtilities.js";

let myPageHead = null;

export default {
    name: "ProjectView",
    components: {
        ViewNavBar,
        ProjectThumbnail,
        UXFolioLogo,
        SectionLoader,
    },
    mounted() {
        let head = {
            meta:[]
        }

        if(document.title !==  this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : '')) {
            head.title = this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : '');
        }

        myPageHead = useHead(head);

        this.$nextTick(() => {
            document.addEventListener('scroll', this.scrollDistance);
        })
    },
    unmounted() {
        myPageHead.dispose();
    },
    beforeUpdate() {
        myPageHead.patch({
            title: this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : ''),
        })
    },
    methods: {
        resolveColor,
        footerHref(page) {
            if(page.type === "file") {
                return page.settings.link.url
            }
            else if(page.type === 'home') {
                return '/' + this.portfolio.shortId
            }
            else {
                return '/p/' + (!this.portfolio.ownDomainAddress ? this.portfolio.shortId + "/" : '') + page.shortId
            }
        },
        navigateToProjectFooter(ownDomainAddress, portfolioShortId, projectShortId) {
            if(!!ownDomainAddress) {
                window.location.href = "/p/" + projectShortId;
            } else {
                window.location.href = "/p/" + portfolioShortId + "/" + projectShortId;
            }
        },
        navigateBack() {
            if(this.portfolio.ownDomainAddress) {
                window.location.href = window.location.protocol + "//" + this.portfolio.ownDomainAddress;
            }
            else {
                let location = '/' + this.portfolio.shortId;
                window.location.href = window.location.origin + location;
            }
        },
        scrollToTop() {
            let isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
            if (isSmoothScrollSupported) {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                this.smoothScroll(0, -1);
            }
        },
        smoothScroll(targetOffset, direction) {
            let currentOffset = window.pageYOffset || document.body.scrollTop;
            let difference = Math.abs(currentOffset - targetOffset);
            if (!difference) return;
            let frames = parseInt(400 / 1000 * 60);
            let tick = 400 / frames;
            let perFrame = difference / frames;
            let timer = setInterval(() => {
                if (direction > 0) {
                    if (window.pageYOffset >= targetOffset) {
                        scrollBy(0, (targetOffset - difference) * direction);
                        clearInterval(timer);
                        return;
                    }
                } else {
                    if (window.pageYOffset <= 0) {
                        scrollBy(0, difference * direction);
                        clearInterval(timer);
                        return;
                    }
                }
                scrollBy(0, perFrame * direction);
                if (window.pageYOffset + window.innerHeight === document.body.scrollHeight) clearInterval(timer);
            }, tick);
        },
        scrollDistance() {
            if (window.pageYOffset > 1200) {
                if(document.querySelector('.scroll-to-top')?.classList.contains('visible')) return;
                document.querySelector('.scroll-to-top')?.classList.add("visible");
            } else {
                document.querySelector('.scroll-to-top')?.classList.remove("visible");
            }
        },
        getProjectName(project) {
            let headerTitle = project.sections[0];
            return project.name !== null ? project.name : convertRichTextHTMLToPlainText(headerTitle.title.text)
        }
    },
    computed: {
        iframeUrl() {
            return window.location.pathname.includes('/uxs-headless/\'')
        },
        user() {
            return this.$store.state.view.user
        },
        portfolio() {
            return this.$store.state.view.portfolio
        },
        project() {
            return this.$store.state.view.project
        },
        portfolioPages() {
          return this.$store.state.view.portfolioPages
        },
        footerProjects() {
            return this.$store.state.view.footerProjects
        },
        getTextStyles() {
            return this.$store.getters.getAllTextStylesInCss;
        },
        isFooterEnabled() {
            let avalibleInCaseStudy = this.project.type === 'caseStudy' && this.portfolio.settings.showFooterOnProjects && this.portfolio.portfolioFooter
            let avalibleInPublicPage = this.project.type !== 'caseStudy' && this.portfolio.settings.showFooterOnPortfolio && !this.footerDisabledInThemes.includes(this.portfolioTemplate) && this.portfolio.portfolioFooter;
            return avalibleInCaseStudy || avalibleInPublicPage
        },
        getUrl() {
            return this.project.type === 'home' ?
                "https://" + (this.portfolio.ownDomainAddress ? this.portfolio.ownDomainAddress : "uxfol.io/" + this.portfolio.shortId) :
                "https://" + (this.portfolio.ownDomainAddress ? this.portfolio.ownDomainAddress : "uxfol.io") + "/p/" + this.portfolio.shortId + "/" + this.project.shortId;
        },
    }
}
</script>