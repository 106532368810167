//Editor
import * as VueRouter from 'vue-router';
import {
    DesignPanelTypesEnum, PagesPanelPathsEnum,
    PagesPanelTypesEnum,
    SettingsPanelPathsEnum,
    SettingsPanelTypesEnum
} from "../common/enums.js";

// page components
const PortfolioEditor = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioEditor/PortfolioEditor.vue')
const HomePage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioEditor/pages/HomePage.vue')
const AboutPage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioEditor/pages/AboutPage.vue')
const ContactPage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioEditor/pages/ContactPage.vue')
const CustomPage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioEditor/pages/CustomPage.vue')
const OnBoardingPage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioManager/OnBoardingPage.vue')
const NotificationsPage = () => import(/* webpackChunkName: "editor-pages" */ '../nonEditorPages/NotificationsPage.vue')
const AccountSettingsPage = () => import(/* webpackChunkName: "editor-pages" */ '../nonEditorPages/accountSettings/AccountSettingsPage.vue')
const PortfolioManagerPage = () => import(/* webpackChunkName: "editor-pages" */ '../portfolioManager/PortfolioManagerPage.vue')
const PaymentApp = () => import(/* webpackChunkName: "editor-pages" */ '../paymentCenter/PaymentApp.vue')
const Prices = () => import(/* webpackChunkName: "editor-pages" */ '../nonEditorPages/Prices.vue')


//Account settings pages
const AccountSettingsSubPage = () => import(/* webpackChunkName: "account-settings-sub-pages" */ '../nonEditorPages/accountSettings/subPages/AccountSettingsSubPage.vue');
const DomainsSubPage = () => import(/* webpackChunkName: "account-settings-sub-pages" */ '../nonEditorPages/accountSettings/subPages/DomainsSubPage.vue');
const PlanAndPaymentSubPage = () => import(/* webpackChunkName: "account-settings-sub-pages" */ '../nonEditorPages/accountSettings/subPages/PlanAndPaymentSubPage.vue');
const PortfolioSettingsSubPage = () => import(/* webpackChunkName: "account-settings-sub-pages" */ '../nonEditorPages/accountSettings/subPages/PortfolioSettingsSubPage.vue');

// portfolio manager and template selector components
const PortfoliosList = () => import(/* webpackChunkName: "template-selector-pages" */ '../portfolioManager/portfolioManager/PortfoliosList.vue')
const AllProjectsList = () => import(/* webpackChunkName: "template-selector-pages" */ '../portfolioManager/portfolioManager/ProjectsList.vue')
const TemplateList = () => import(/* webpackChunkName: "template-selector-pages" */ '../portfolioManager/portfolioManager/TemplateList.vue')
const TemplatePreview = () => import(/* webpackChunkName: "template-selector-pages" */'../portfolioManager/portfolioManager/TemplatePreview.vue')


// design panels
const DesignPanel = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/Design.vue')
const DesignMainPanel = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/_DesignMainPanel.vue')
const Templates = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/Templates.vue')
const Colors = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/Colors.vue')
const AdvancedColorStyles = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/AdvancedColorStyles.vue')
const ColorPaletteEdit = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/ColorPaletteEditor.vue')
const TextStyles = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/Fonts.vue')
const AdvancedTextStyles = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/AdvancedTextStyles.vue')
const Buttons = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/Buttons.vue')
const Animations = () => import(/* webpackChunkName: "design-panel" */ '../common/sideBars/design/Animations.vue')
const Spacing = () => import(/* webpackChunkName: "design-panel" */'../common/sideBars/design/Spacing.vue')

// settings panels
const SettingsPanel = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/Settings.vue')
const SettingsMainPanel = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/_SettingsMainPanel.vue')
const SocialMediaLinks = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/SocialMediaLinks.vue')
const SocialLinkEdit = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/EditSocialLink.vue')
const PortfolioLogo = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/PortfolioLogo.vue')
const Favicon = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/Favicon.vue')

// pages panels
const PagesPanel = () => import(/* webpackChunkName: "pages-panel" */ '../common/sideBars/Pages.vue')
const AddNewPage = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/pages/AddNewPage.vue')
const ExternalLinkEdit = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/pages/EditExternalLink.vue')
const ResumeEdit = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/pages/EditFile.vue')


import MailtoLinkEdit from "../common/sideBars/pages/EditMailtoLink.vue";
import PageWrapper from "../view/portfolio/PageWrapper.vue";
import PortfolioWrapper from "../view/portfolio/PortfolioWrapper.vue";

//TemplatePreview
import TemplatePreviewFullSize from "../view/templatePreview/TemplatePreviewFullSize.vue";
import TemplatePreviewHomePage from "../view/templatePreview/pages/TemplatePreviewHomePage.vue";
import TemplatePreviewAboutPage from "../view/templatePreview/pages/TemplatePreviewAboutPage.vue";
import TemplatePreviewContactPage from "../view/templatePreview/pages/TemplatePreviewContactPage.vue";
import ReviewWrapper from "../view/review/ReviewWrapper.vue";

const Share = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/Share.vue')
const DomainAndUrl = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/DomainAndUrl.vue')
const CaseStudyUrl = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/CaseStudyUrl.vue')
const Archive = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/Archive.vue')
const PasswordProtection = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/PasswordProtection.vue')
const ThumbnailSettings = () => import(/* webpackChunkName: "settings-panel" */'../common/sideBars/settings/ThumbnailSettings.vue')
const HeaderFooter = () => import(/* webpackChunkName: "settings-panel" */ '../common/sideBars/settings/HeaderFooter.vue')

//payment pages
const PaymentSuccess = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/components/feedbackPages/PaymentSuccess.vue')
const PaymentCenter = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/PaymentCenter.vue')
const PaymentFailure = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/components/feedbackPages/PaymentFailure.vue')
const PaymentSecondaryAuth = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/PaymentSecondaryAuth.vue')
const PaymentPromoPage = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/PaymentPromoPage.vue')
const CardDeleted = () => import(/* webpackChunkName: "payment-pages" */ '../paymentCenter/components/feedbackPages/CardDeleted.vue')

// domain related pages
const DomainCenter = () => import(/* webpackChunkName: "domain-pages" */ '../domainCenter/DomainCenter.vue')
const DomainApp = () => import(/* webpackChunkName: "domain-pages" */ '../domainCenter/DomainApp.vue')
const DomainSearch = () => import(/* webpackChunkName: "domain-pages" */ '../domainCenter/components/DomainSearch.vue')
const ConnectDomain = () => import(/* webpackChunkName: "domain-pages" */ '../domainCenter/components/ConnectDomain.vue')
const DomainTransfer = () => import(/* webpackChunkName: "domain-pages" */ '../domainCenter/components/DomainTransfer.vue')

// url related pages
const UrlCenter = () => import(/* webpackChunkName: "url-pages" */ '../urlCenter/UrlCenter.vue')
const EditUrl = () => import(/* webpackChunkName: "url-pages" */ '../urlCenter/EditUrl.vue')
const NotFound = () => import(/* webpackChunkName: "url-pages" */ '../common/404.vue')

//Public Pages
const Index = () => import(/* webpackChunkName: "public-pages" */ '../view/Index.vue')
const IndexWrapper = () => import(/* webpackChunkName: "public-pages" */ '../view/portfolio/LandingWrapper.vue')
const Pricing = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/Pricing.vue')
const ExamplesPage = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/ExamplesPage.vue')
const ExampleFullPage = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/ExampleFullPage.vue')
const EduPartnership = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/EduPartnership.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/PrivacyPolicy.vue')
const TermsAndConditions = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/TermsAndConditions.vue')
const Imprint = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/Imprint.vue')
const ReviewSubmitPage = () => import(/* webpackChunkName: "public-pages" */ '../view/publicPages/ReviewSubmitPage.vue')


//Settings Pages
const SettingsEditor = () => import(/* webpackChunkName: "settings" */ '../settingsEditor/SettingsEditor.vue')
const CopySettings = () => import(/* webpackChunkName: "settings" */ '../settingsEditor/views/EditCopyIdeas.vue')
const ImageIdeasSettings = () => import(/* webpackChunkName: "settings" */ '../settingsEditor/views/EditImageIdeas.vue')
const EditFontView = () => import(/* webpackChunkName: "settings" */ '../settingsEditor/views/EditFontView.vue')
const EditSurveyModalSettings = () => import(/* webpackChunkName: "settings" */  "../settingsEditor/views/EditSurveyModalSettings.vue");
const SpamFilterSettings = () => import(/* webpackChunkName: "settings" */ "../settingsEditor/views/EditSpamFilterSettings.vue")


//Admin page
const AdminSite = () => import(/* webpackChunkName: "admin" */ '../adminSite/AdminSite.vue')

const designPanelRoutes =  {
    path: 'style',
    components: {sidePanel: DesignPanel},
    props: true,
    children :[
        {
            path: '',
            components: {sidePanelSubView: DesignMainPanel},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.MAIN_PANEL,
                    title: 'Design',
                    saveButtonLabel: 'Save',
                    previousSettings: null,
                    prevAccentColor: null,
                    showSecondaryTab: false,
                    hideActionButtons: true,
                    hideBackButton: true,
                    allowSave: false,
                    updateParentPortfolio: false,
                    tabPills: null,
                    activeTab: null
                }
            }
        },
        {
            path: 'change-template',
            components: {sidePanelSubView: Templates},
            meta: {
                sidebarSettings: {
                    panel: DesignPanelTypesEnum.TEMPLATES,
                    title: 'Templates',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        },
        {
            path: 'colors',
            components: {sidePanelSubView: Colors},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.COLORS,
                    title: 'Colors',
                    hideBackButton: false,
                    hideActionButtons: false,
                    tabPills: [
                        {
                            label: 'Presets',
                            type: 'presetColors'
                        },
                        {
                            label: 'Custom',
                            type: 'customColors'
                        }
                    ]
                }
            },
            children: [
                {
                    path: 'heading-colors',
                    components: {sidePanelOverlayView: AdvancedColorStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_HEADING_COLOR_SETTINGS,
                            title: "Edit headings",
                            goBackPath: `/${DesignPanelTypesEnum.COLORS}`
                        }
                    }
                },
                {
                    path: 'paragraph-colors',
                    components: {sidePanelOverlayView: AdvancedColorStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_PARAGRAPH_COLOR_SETTINGS,
                            title: "Edit paragraphs",
                            goBackPath: `/${DesignPanelTypesEnum.COLORS}`
                        }
                    }
                },
                {
                    path: 'navigation-colors',
                    components: {sidePanelOverlayView: AdvancedColorStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_NAVIGATION_COLOR_SETTINGS,
                            title: "Edit navigation",
                            goBackPath: `/${DesignPanelTypesEnum.COLORS}`
                        }
                    }
                },
                {
                    path: 'edit-color-palette',
                    components: {sidePanelOverlayView: ColorPaletteEdit},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.EDIT_COLOR_PALETTE,
                            title: "Edit color palette",
                            goBackPath: `/${DesignPanelTypesEnum.COLORS}`,
                            tabPills: [
                                {
                                    label: 'Edit',
                                    type: 'paletteEditor'
                                },
                                {
                                    label: 'Generate',
                                    type: 'paletteGenerator'
                                }
                            ],
                            activeTab: 'paletteEditor'
                        }
                    }
                },
            ]
        },
        {
            path: 'fonts',
            components: {sidePanelSubView: TextStyles},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.FONTS,
                    title: 'Fonts',
                    hideBackButton: false,
                    hideActionButtons: false,
                    tabPills: [
                        {
                            label: 'Presets',
                            type: 'presetTextStyles'
                        },
                        {
                            label: 'Custom',
                            type: 'customTextStyles'
                        }
                    ],
                    activeTab: 'customTextStyles'
                }
            },
            children: [
                {
                    path: 'headings',
                    components: {sidePanelOverlayView: AdvancedTextStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_HEADING_STYLE_SETTINGS,
                            title: "Edit headings",
                            goBackPath: `/${DesignPanelTypesEnum.FONTS}`
                        }
                    }
                },
                {
                    path: 'paragraphs',
                    components: {sidePanelOverlayView: AdvancedTextStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_PARAGRAPH_STYLE_SETTINGS,
                            title: "Edit paragraphs",
                            goBackPath: `/${DesignPanelTypesEnum.FONTS}`
                        }
                    }
                },
                {
                    path: 'navigation-buttons',
                    components: {sidePanelOverlayView: AdvancedTextStyles},
                    meta: {
                        sidePanelSettings: {
                            panel: DesignPanelTypesEnum.ADVANCED_NAVIGATION_STYLE_SETTINGS,
                            title: "Edit navigation",
                            goBackPath: `/${DesignPanelTypesEnum.FONTS}`
                        }
                    }
                }
            ]
        },
        {
            path: 'button-settings',
            components: {sidePanelSubView: Buttons},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.BUTTONS,
                    title: 'Buttons',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        },
        {
            path: 'animation-settings',
            components: {sidePanelSubView: Animations},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.ANIMATION_SETTINGS,
                    title: 'Animations',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        },
        {
            path: 'spacing',
            components: {sidePanelSubView: Spacing},
            meta: {
                sidePanelSettings: {
                    panel: DesignPanelTypesEnum.SPACING,
                    title: 'Spacing',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        }
    ]
};

const settingsPanelRoutes = {
    path: 'settings',
    components: {sidePanel: SettingsPanel},
    props: true,
    children: [
        {
            path: '',
            components: {sidePanelSubView: SettingsMainPanel},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.MAIN_PANEL,
                    title: 'Settings',
                    previousSettings: null,
                    prevAccentColor: null,
                    saveButton: 'Save',
                    showSecondaryTab: false,
                    hideActionButtons: true,
                    hideBackButton: true,
                    allowSave: false,
                    tabPills: null,
                    activeTab: null
                }
            }
        },
        {
            path: 'social-media-links',
            components: {sidePanelSubView: SocialMediaLinks},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.SOCIAL_MEDIA_LINKS,
                    title: 'Social media links',
                    hideActionButtons: true,
                    hideBackButton: false,
                }
            },
            children: [
                {
                    path: 'add-social-link',
                    components: {sidePanelOverlayView: SocialLinkEdit},
                    meta: {
                        sidePanelSettings: {
                            panel: SettingsPanelTypesEnum.EDIT_SOCIAL_LINKS,
                            title: 'Add social link',
                            hideActionButtons: false,
                            goBackPath: `/${SettingsPanelPathsEnum.SOCIAL_MEDIA_LINKS}`
                        }
                    }
                },
                {
                    path: 'edit-social-link',
                    components: {sidePanelOverlayView: SocialLinkEdit},
                    meta: {
                        sidePanelSettings: {
                            panel: SettingsPanelTypesEnum.EDIT_SOCIAL_LINKS,
                            title: 'Edit social link',
                            hideActionButtons: false,
                            goBackPath: `/${SettingsPanelPathsEnum.SOCIAL_MEDIA_LINKS}`
                        }
                    }
                }
            ]
        },
        {
            path: 'portfolio-logo',
            components: {sidePanelSubView: PortfolioLogo},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.PORTFOLIO_LOGO,
                    title: 'Portfolio logo',
                    hideActionButtons: false,
                    hideBackButton: false,
                }
            },
        },
        {
            path: 'share',
            components: {sidePanelSubView: Share},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.SHARE,
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        },
        {
            path: 'domain-and-url',
            alias: 'portfolio-own-domain',
            components: {sidePanelSubView: DomainAndUrl},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.DOMAIN_AND_URL,
                    title: 'Domain & URL',
                    hideActionButtons: true,
                    hideBackButton: false
                }
            }
        },
        {
            path: 'url-slug',
            alias: 'case-study-url',
            components: {sidePanelSubView: CaseStudyUrl},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.URL,
                    title: 'Case study URL',
                    hideActionButtons: true,
                    hideBackButton: false
                }
            }
        },
        {
            path: 'archive',
            components: {sidePanelSubView: Archive},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.ARCHIVE,
                    title: 'Archive',
                    hideActionButtons: true,
                    hideBackButton: false
                }
            }
        },
        {
            path: 'password',
            components: {sidePanelSubView: PasswordProtection},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.PASSWORD,
                    saveButton: 'Save',
                    hideActionButtons: false,
                    hideBackButton: false
                }
            }
        },
        {
            path: 'favicon',
            components: {sidePanelSubView: Favicon},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.FAVICON,
                    title: 'Favicon',
                    hideActionButtons: false,
                    hideBackButton: false
                }
            }
        },
        {
            path: 'basic-details',
            components: {sidePanelSubView: ThumbnailSettings},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.THUMBNAIL_SETTINGS,
                    title: 'Thumbnail settings',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        },
        {
            path: 'display-options',
            components: {sidePanelSubView: HeaderFooter},
            meta: {
                sidePanelSettings: {
                    panel: SettingsPanelTypesEnum.HEADER_FOOTER,
                    title: 'Header & footer',
                    hideBackButton: false,
                    hideActionButtons: false
                }
            }
        }
    ]
}

const pagesPanelRoutes = {
    path: 'pages',
    components: {sidePanel: PagesPanel},
    meta: {
        sidePanelSettings: {
            panel: PagesPanelTypesEnum.MAIN_PANEL,
            title: 'Pages',
            previousSettings: null,
            prevAccentColor: null,
            saveButton: 'Save',
            showSecondaryTab: false,
            hideActionButtons: true,
            hideBackButton: true,
            allowSave: false,
            tabPills: null,
            activeTab: null
        }
    },
    props: true,
    children: [
        {
            path: 'add-new-page',
            components: {sidePanelOverlayView: AddNewPage},
            meta: {
                sidePanelSettings: {
                    panel: PagesPanelTypesEnum.ADD_NEW_PAGE,
                    title: 'Add page or link',
                    hideActionButtons: true,
                    goBackPath: '/'
                }
            }
        },
        {
            path: 'external-link',
            components: {sidePanelOverlayView: ExternalLinkEdit},
            meta: {
                sidePanelSettings: {
                    panel: PagesPanelTypesEnum.NAVIGATION_EXTERNAL_LINK,
                    hideActionButtons: false,
                    goBackPath: '/'
                }
            }
        },
        {
            path: 'mailto-link',
            components: {sidePanelOverlayView: MailtoLinkEdit},
            meta: {
                sidePanelSettings: {
                    panel: PagesPanelTypesEnum.NAVIGATION_MAILTO_LINK,
                    hideActionButtons: false,
                    goBackPath: '/'
                }
            }
        },
        {
            path: 'resume',
            components: {sidePanelOverlayView: ResumeEdit},
            meta: {
                sidePanelSettings: {
                    panel: PagesPanelTypesEnum.RESUME,
                    hideActionButtons: false,
                    goBackPath: '/'
                }
            }
        }
    ]
}


const reviewRoutes = [
    {
        path: '/review/p/:portfolioShortId/:projectShortId',
        component: ReviewWrapper,
        props: { review: true },
        meta: {
            type: 'review'
        }
    },
    {
        path: '/review/:portfolioShortId',
        component: ReviewWrapper,
        props: { review: true },
        meta: {
            type: 'review'
        }
    },
    //Different route for reciever mode for checking and follow up
    {
        path: '/myreviews/p/:portfolioShortId/:projectShortId',
        component: ReviewWrapper,
        props: { review: false },
        meta: {
            type: 'review'
        }
    },
    {
        path: '/myreviews/:portfolioShortId',
        component: ReviewWrapper,
        props: { review: false },
        meta: {
            type: 'review'
        }
    },
]


const projectRouters = [
    {
        path: '/p/:projectShortId',
        component: PageWrapper,
        meta: {
            type: 'view'
        }
    },
    {
        path: "/p/:portfolioShortId/:projectShortId",
        alias: [
            "/portfolio/:portfolioShortId/project/:projectShortId",
        ],
        component: PageWrapper,
        meta: {
            type: 'view'
        }
    },
    {
        path:'/project/:projectShortId/:projectName',
        alias:[
            //TODO: check with Zsuzsi
            "/project/uxs-headless/:projectShortId/:projectName",                // iframe embed on uxstudioteam.com
        ],
        component: PageWrapper,
        meta: {
            type: 'view'
        }
    }
]

const portfolioRouters = [
    {
        path: '/portfolio/:portfolioId',
        alias: [
            //"/portfolio/:portfolioId/:portfolioName", TODO: watch for support
        ],
        component: PortfolioWrapper,
        meta: {
            type: 'view'
        }
    },
    {
        path:"/:shortId",
        component: PortfolioWrapper,
        meta: {
            type: 'view'
        }
    },
]

const globalAndAdminRoutes = [
    {
        path:'/',
        name:'Index',
        props: true,
        component: IndexWrapper,
        children: [
            {
                path:'v1',
                component:IndexWrapper
            }
        ]
    },
    {
        path:'/result',
        name:'Review Result',
        props:true,
        component:ReviewSubmitPage
    },
    {
        path: '/pricing',
        name: 'Pricing',
        props: true,
        component: Pricing,
        meta: {
            title: 'Pricing - UXfolio',
            type: 'landing'
        }
    },
    {
        path:'/examples/:showcaseItemShortId/:showcaseItemName',
        name:'Example',
        props: true,
        component:ExampleFullPage,
        meta: {
            type: 'landing',
            title: 'Examples - UXfolio',
        }
    },
    {
        path:'/examples',
        name:'Examples',
        props: true,
        component: ExamplesPage,
        meta: {
            title: 'Examples - UXfolio',
            type: 'landing',
        }
    },
    {
        path:'/showcase',
        name:'Showcase',
        redirect: {
            name:'Examples',
        },
    },
    {
        path: '/partnership-program',
        name: 'Partnership program',
        alias: '/partnership-program/:submitFeedback',
        component: EduPartnership,
        meta: {
            title: 'Educational partnership - UXfolio',
            type: 'landing'
        }
    },
    {
        path:'/privacy-policy',
        name:'Privacy policy',
        component:PrivacyPolicy,
        meta: {
            type: 'landing',
            title: 'Privacy Policy - UXfolio'
        }
    },
    {
        path:'/terms-and-conditions',
        name: 'Terms and conditions',
        component:TermsAndConditions,
        meta: {
            type: 'landing',
            title: 'Terms and Conditions - UXfolio'
        }
    },
    {
        path:'/imprint',
        name:'Imprint',
        component: Imprint,
        meta: {
            type: 'landing',
            title: 'Imprint - UXfolio'
        }
    },
    //Admin
    {
        path:'/admin',
        name:'adminPage',
        type: 'landing',
        meta: {
            title: 'UXfolio - Admin'
        },
        component: AdminSite
    },
    //Settings
    {
        path: '/settings',
        name: 'settingsEditorApp',
        meta: {
            title: 'UXfolio - Settings'
        },
        component: SettingsEditor,
        redirect: {
            name:'copy'
        },
        children: [
            {
                name:'copy',
                path: 'copy',
                component: CopySettings,
            },
            {
                name:'image-ideas',
                path: 'image-ideas',
                component: ImageIdeasSettings
            },
            {
                name:'fonts',
                path:'fonts',
                component: EditFontView
            },
            {
                name:'survey-modal-settings',
                path:'survey-modal-settings',
                component: EditSurveyModalSettings
            },
            {
                name:'spam-filter-settings',
                path:'spam-filter-settings',
                component: SpamFilterSettings
            }
        ]
    },
];

const editorRoutes = [
    {
        path: '/home',
        name: 'HomePageEditorApp',
        meta: {
            title: 'Editor - UXfolio'
        },
        component: PortfolioEditor,
        children: [
            {
                path: 'portfolios',
                component: PortfolioManagerPage,
                props: true,
                meta: {
                    title: 'Portfolio manager - UXfolio',
                    nonEditorPage: true
                },
                children: [
                    {
                        path: '',
                        name: 'Portfolio manager - UXfolio',
                        component: PortfoliosList,
                        props: true
                    },
                    {
                        path: 'create-new-portfolio',
                        component: TemplateList,
                        props: true
                    },
                    {
                        path: 'create-new-portfolio/template-preview',
                        component: TemplatePreview,
                        props: true
                    },
                    {
                        path: 'create-new-portfolio/select-projects',
                        component: AllProjectsList,
                        props: true
                    },
                ]
            },
            {
                path: 'portfolios/:portfolioId',
                name: 'Home Page Editor',
                component: HomePage,
                alias: 'portfolios/:portfolioId/style',
                meta: {
                    title: 'Editor - UXfolio'
                },
                children: [
                    designPanelRoutes,
                    settingsPanelRoutes,
                    pagesPanelRoutes
                ]
            },
            {
                path: 'portfolios/:portfolioId/about',
                component: AboutPage,
                children: [
                    designPanelRoutes,
                    settingsPanelRoutes,
                    pagesPanelRoutes
                ]
            },
            {
                path: 'portfolios/:portfolioId/contact',
                component: ContactPage,
                children: [
                    designPanelRoutes,
                    settingsPanelRoutes,
                    pagesPanelRoutes
                ]
            },
            {
                path: 'portfolios/:portfolioId/p/:projectShortId',
                component: CustomPage,
                children: [
                    designPanelRoutes,
                    settingsPanelRoutes,
                    pagesPanelRoutes
                ]
            },
            {
                path: 'onboarding',
                components: {onBoarding: OnBoardingPage},
                props: true,
                meta: {
                    title: 'Onboarding - UXfolio',
                    nonEditorPage: true,
                },
                children: [
                    {
                        path: '',
                        component: TemplateList,
                        props: true
                    },
                    {
                        path: 'template-preview',
                        component: TemplatePreview,
                        props: true
                    },
                ]
            },
            {
                path: 'settings',
                name: 'Account Settings',
                redirect: { path: "/home/settings/account-settings" },
                component:AccountSettingsPage,
                props: true,
                meta: {
                    title: 'Account - UXfolio',
                    nonEditorPage: true,
                },
                children:[
                    {
                        path: 'account-settings',
                        name: 'Account settings',
                        components: {accountSettingsSubView: AccountSettingsSubPage},
                    },
                    {
                        path: 'domains',
                        name: 'Domains',
                        components: {accountSettingsSubView: DomainsSubPage},
                    },
                    {
                        path: 'plan-and-payment',
                        name: 'Plan and payment',
                        components: {accountSettingsSubView: PlanAndPaymentSubPage},
                    },
                    {
                        path: 'portfolio-settings',
                        name: 'Portfolio settings',
                        components: {accountSettingsSubView: PortfolioSettingsSubPage},
                    }
                ]
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: NotificationsPage,
                props: true,
                meta: {
                    title: 'Notifications - UXfolio',
                    nonEditorPage: true,
                },
            },
            {
                path: 'prices',
                name: 'Prices',
                component: Prices,
                props: true,
                meta: {
                    title: 'Prices - UXfolio',
                    nonEditorPage: true,
                },
            },
            {
                path: 'payment',
                name: 'Payment Center',
                component: PaymentCenter,
                props: true,
                meta: {
                    title: 'Payment - UXfolio',
                    nonEditorPage: true,
                },
                children:[
                    {
                        path: '',
                        name: 'Payment',
                        component: PaymentApp,
                        props: (route) => ({
                            ...route.params
                        })
                    },
                    {
                        path: 'auth',
                        name: 'Auth',
                        component: PaymentSecondaryAuth,
                        props: true
                    },
                    {
                        path: 'success',
                        name: 'Success',
                        component: PaymentSuccess,
                        props: true
                    },
                    {
                        path: 'failure',
                        name: 'Failure',
                        component: PaymentFailure,
                        props: true
                    },
                    {
                        path: 'deleted',
                        name: 'Deleted',
                        component: CardDeleted,
                        props: true
                    },
                    {
                        path: 'promo',
                        name: 'Promo',
                        component: PaymentPromoPage,
                        props: true
                    }
                ]
            },
            {
                path: 'domain',
                name: 'Domain center',
                component: DomainCenter,
                props: true,
                meta: {
                    title: 'Domain editor - Uxfolio',
                    nonEditorPage: true,
                },
                children: [
                    {
                        path: 'manage',
                        name: 'Domain manager',
                        component: DomainApp,
                        props: true

                    },
                    {
                        path: 'search',
                        name: 'Domain search',
                        component: DomainSearch,
                        props: true
                    },

                    {
                        path: 'connect',
                        name: 'Connect domain',
                        component: ConnectDomain,
                        props: true
                    },
                    {
                        path: 'transfer',
                        name: 'Transfer domain',
                        component: DomainTransfer,
                        props: true
                    },
                ]
            },
            {
                path: 'url',
                name: 'Url center',
                component: UrlCenter,
                props: true,
                meta: {
                    title: 'URL editor - Uxfolio',
                    nonEditorPage: true,
                },
                children: [
                    {
                        path: 'edit',
                        name: 'Edit url',
                        component: EditUrl,
                        props: true,
                    }
                ]
            },
        ]
    },
    {
        path:'/p/templates/:templateId',
        alias:['/templates/:templateId'],
        name:'TemplatePreviewFullSize',
        component: TemplatePreviewFullSize,
        props:true,
        children: [
            {
                path:'',
                name:'TemplatePreviewHome',
                component:TemplatePreviewHomePage
            },
            {
                path:'about',
                name:'TemplatePreviewAbout',
                component:TemplatePreviewAboutPage
            },
            {
                path:'contact',
                name:'TemplatePreviewContact',
                component:TemplatePreviewContactPage
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound
    },
    {
        path: '/projects/*',
        name: "LegacyProjectEditUrl",
        component: NotFound
    }
];

const routes = [...projectRouters, ...globalAndAdminRoutes, ...portfolioRouters, ...editorRoutes, ...reviewRoutes];

let MainRouter;

export default MainRouter = VueRouter.createRouter({
    history:VueRouter.createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        let pathArray = to.path.split('/');
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        if(!pathArray.includes('home') && pathArray.includes('p') && to.meta.type !== 'view') {
            // always scroll to top if not in editor
            return { top: 0 }
        }
        if (to.meta.type === 'landing') {
            return { top: 0 }
        }
        if (pathArray.includes('prices')) {
            return  { top: 0 }
        }
    },
})

MainRouter.beforeEach((to, from, next) => {
    let pathArray = to.path.split('/');

    if((pathArray.includes('home') && !pathArray.includes('p')) || to.meta.type === 'landing') {
        document.title = to.meta.title || 'UXfolio - Fast & easy UX portfolio building';
    }
    next();
});

//If we are already on the review route keep the logic.
MainRouter.beforeEach((to, from, next) => {
    if (from.path.startsWith('/review') && !to.path.startsWith('/review')) {
        next(`/review${to.path}`);
    }
    else if (from.path.startsWith('/myreviews') && !to.path.startsWith('/myreviews')) {
        next(`/myreviews${to.path}`);
    }
    else {
        next();
    }
});