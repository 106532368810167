<template>
    <button @click="$emit('buttonClicked')"
            :class="[{'selected' : active}, {'has-tooltip' : tooltip}, {dark:darkScheme}, customClass]"
            :disabled="disabled"
            :tooltip="tooltip"
            :tooltip-position="tooltipPosition"
    >
        <Icon v-if="iconName" :icon-name="iconName" />
        <InlineSvg v-else :src="svg" />
        <span v-if="label">{{ label }}</span>
    </button>
</template>

<script>
    import Icon from "../../common/Icon.vue";
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: "nav-bar-pill",
        emits: ["buttonClicked"],
        props: {
            label: {
                default: null,
                type: String,
            },
            iconName: {
                default: null,
                type: String,
            },
            svg: {
                default: null,
            },
            width: {
                default: 24,
                type: Number
            },
            height: {
                default: 24,
                type: Number
            },
            active: {
                default: false,
                type: Boolean
            },
            tooltip: {
                default: null,
                type: String
            },
            tooltipPosition: {
                default: 'bottom',
                type: String
            },
            disabled: {
                default: false,
                type: Boolean
            },
            customClass: {
                type: String,
                default: null,
            },
            darkScheme: {
                type: Boolean,
                default:false
            }
        },
        components: {
            InlineSvg, Icon
        }
    }
</script>

<style lang="scss" scoped>
    button {
        font: var(--editor-ui-b2-medium);
        color: var(--asphalt60);
        padding: 4px 8px;
        height: 36px;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:focus {
            outline: none;
        }

        &.dark {
            color: var(--asphalt40);

            &:hover,
            &.selected {
                color: var(--asphalt10);
            }
        }

        &:disabled {
            color: var(--asphalt50);
            &:hover {
                color: var(--asphalt50);
                cursor: not-allowed;
            }
        }

        &:hover,
        &.selected {
            color: var(--asphalt90);
        }

        &.selected {
            background-color: var(--asphalt90);
        }

        &.large {
            height: 44px;
            svg {
                width: 20px;
                height: 20px;
            }
            span {
                margin-left: 6px;
            }
        }

        svg {
            width: 20px;
            height: 20px;
        }

        span {
            display: none;
            font-size: 13px;
            @media (min-width: 640px) {
                display: inline;
                margin-left: 6px;
            }
        }
    }
</style>