import {resolveColor} from "@/scripts/common/helpers.js";

const styleProperties = [
    { varName: '--hero-h1', styleKey: 'heroTitle', colorKey: null },
    { varName: '--hero-h2', styleKey: 'heroSubtitle', colorKey: null  },
    { varName: '--hero-nav-button', styleKey: 'navigation', colorKey: null  },
    { varName: '--hero-p1', styleKey: 'heroParagraph', colorKey: null  },
    { varName: '--section-h1', styleKey: 'h1', colorKey: null  },
    { varName: '--section-h2', styleKey: 'h2', colorKey: null  },
    { varName: '--section-h3', styleKey: 'h3', colorKey: null  },
    { varName: '--section-h4', styleKey: 'h4', colorKey: null  },
    { varName: '--section-p1', styleKey: 'p1', colorKey: null  },
    { varName: '--section-p2', styleKey: 'p2', colorKey: null  },
    { varName: '--thumbnail-title', styleKey: 'projectTitle', colorKey: null  },
    { varName: '--thumbnail-subtitle', styleKey: 'projectSubtitle', colorKey: null  },
    { varName: '--cta-button', styleKey: 'button', colorKey: 'buttonText' },
    { varName: '--page-title', styleKey: 'pageTitle', colorKey: 'heroSubtitle' },
];

const portfolioHeroStyleProperties = [
    { varName: '--portfolio-hero-h1', styleKey: 'heroTitle', colorKey: null  },
    { varName: '--portfolio-hero-h2', styleKey: 'heroSubtitle', colorKey: null  },
    { varName: '--portfolio-hero-nav-button', styleKey: 'navigation', colorKey: null  },
    { varName: '--portfolio-hero-p1', styleKey: 'heroParagraph', colorKey: null  },
];

function addStylesToCssVars (varNamePrefix, styleKey, colorKey, fontStyles, colorStyles, colorPalette, ctaStyle) {
    let cssVars = {};

    const style = fontStyles[styleKey];

    // colors
    if(!colorKey) {
        cssVars[`${varNamePrefix}-color`] = resolveColor(colorStyles[styleKey], colorPalette)
    } else {
        cssVars[`${varNamePrefix}-color`] = resolveColor(colorStyles[colorKey], colorPalette);
    }

    // add hover version if the item is navigation related
    if(varNamePrefix === '--hero-nav-button' || varNamePrefix === '--portfolio-hero-nav-button') {
        cssVars[`${varNamePrefix}-hover-color`] = resolveColor(colorStyles.navigationHover, colorPalette);
    }

    // add extra color in case of a CTA button
    if(varNamePrefix === '--cta-button') {
        cssVars['--cta-background-color'] = resolveColor(colorStyles.buttonFill, colorPalette);
        cssVars['--cta-outline-color'] = resolveColor(colorStyles.buttonOutline, colorPalette);
        cssVars['--cta-hover-color'] = resolveColor(colorStyles.buttonFill, colorPalette) + 'CC';
        cssVars['--cta-stroke-strength'] = ctaStyle && ctaStyle.stroke && ctaStyle.stroke.strength ? `${ctaStyle.stroke.strength}px` : null;
    }

    // text styles
    cssVars[`${varNamePrefix}-font`] = `'${style.font}'`;
    cssVars[`${varNamePrefix}-height`] = getCalculatedLineHeight(style);
    cssVars[`${varNamePrefix}-height-mobile`] = getCalculatedLineHeight(style, true);
    cssVars[`${varNamePrefix}-height-tablet`] = getCalculatedLineHeight(style, false, true);
    cssVars[`${varNamePrefix}-size`] = `${style.size}px`;
    cssVars[`${varNamePrefix}-size-mobile`] = `${getCalculatedMobileFontSize(style.size)}px`;
    cssVars[`${varNamePrefix}-size-tablet`] = `${getCalculatedTabletFontSize(style.size)}px`;
    cssVars[`${varNamePrefix}-style`] = style.fontStyle ? `${style.fontStyle}` : null;
    cssVars[`${varNamePrefix}-weight`] = `${style.fontWeight}`;

    return cssVars
}

function getCalculatedLineHeight(style, isMobile = false, isTablet = false) {
    const fontSize = isMobile ? getCalculatedMobileFontSize(style.size) : isTablet ? getCalculatedTabletFontSize(style.size) : style.size;
    const lineHeight = style.lineHeight;
    const relativeLineHeight = style.relativeLineHeight; // relative line height is the unitless line-height value
    let calculatedLineHeight;

    if ((!relativeLineHeight && !lineHeight) ||
        (lineHeight && lineHeight.toLowerCase === "auto") ||
        isTablet ||
        isMobile
    ) {
        // if the font is larger than 72px set the line height to a fixed 105%
        if(fontSize > 72) {
            return 1.05
        }
        calculatedLineHeight = Math.round( (0.015 * Math.pow(fontSize, 2) - 2.067 * fontSize + 175.991) ) / 100;
    } else {
        calculatedLineHeight = !!relativeLineHeight ? relativeLineHeight : Math.round(lineHeight / fontSize * 100) / 100;
    }

    if(lineHeight && lineHeight.toLowerCase === "auto") {
        return calculatedLineHeight < 1.05
            ? 1.05
            : calculatedLineHeight > 1.5
                ? 1.5
                : calculatedLineHeight;
    } else {
        return calculatedLineHeight
    }

}

function getCalculatedMobileFontSize(desktopFontSize) {
    if(desktopFontSize < 15) {
        return desktopFontSize
    }

    let mobileFontSize = 0.4 * desktopFontSize + 9.6;

    if(mobileFontSize < 12) {
        return 12
    }

    return Math.round(mobileFontSize * 100) / 100
}

function getCalculatedTabletFontSize(desktopFontSize) {
    if(desktopFontSize < 15) {
        return desktopFontSize
    }

    let mobileFontSize = 0.6 * desktopFontSize + 6.4;

    if(mobileFontSize < 12) {
        return 12
    }

    return Math.round(mobileFontSize * 100) / 100
}

export function getAllTextStyles(fontStyles, colorStyles, colorPalette, ctaStyle) {
    let vars = {}
    styleProperties.forEach(property => {
        const { varName, styleKey, colorKey } = property;
        vars = {
            ...vars,
            ...addStylesToCssVars(varName, styleKey, colorKey, fontStyles, colorStyles, colorPalette, ctaStyle)
        };
    })
    return vars
}

export function getPortfolioHeroTextStyles(fontStyles, colorStyles, colorPalette) {
    let vars = {}
    portfolioHeroStyleProperties.forEach(property => {
        const { varName, styleKey, colorKey } = property;
        vars = {
            ...vars,
            ...addStylesToCssVars(varName, styleKey, colorKey, fontStyles, colorStyles, colorPalette, null)
        }
    })
    return vars
}

export function getCssVarsFromColorPalette(colorPalette) {
    let vars = {}
    if(colorPalette.colors) {
        colorPalette.colors.forEach((color, index) => {
            vars[`--paletteColor${index+1}`] = color
        })
    }
    return vars
}