<template>
    <div v-if="$store.state.project && $route?.meta?.type !== 'review' && isOwnerIsLoggedIn" :class="['ufo--nav-bar', 'dark', 'view-nav-bar']" >

        <div class="left">
            <div @click.stop="goHome" class="logo">
                <InlineSvg :src="logo" class="dark" />
            </div>

            <div>
                <NavBarPill label="Back to editor" icon-name="back" @button-clicked="goBack" dark-scheme />
            </div>
        </div>

        <div class="mid">
            <UrlEditNavContainer variant="view" />
        </div>
    </div>
</template>

<script>

import InlineSvg from "vue-inline-svg";
import uxFolioLogoSymbol from "../../../images/logo-symbol-white.svg";
import NavBarPill from "../../common/editorNavBar/NavBarPill.vue";
import UrlEditNavContainer from "./common/UrlEditNavContainer.vue";

export default {
    name: "view-nav-bar",
    components: {
        UrlEditNavContainer,
        NavBarPill,
        InlineSvg
    },
    data() {
        return {
            isSmallScreen: false,
            logo: uxFolioLogoSymbol,
            shareDropdown:false
        }
    },
    async beforeMount() {
        this.setScreenSize();

        // Fix for missing store state
        if (!this.$store.state?.portfolioPages?.length && this.$store.state?.view?.portfolioPages?.length) {
            this.$store.state.portfolioPages = this.$store.state.view.portfolioPages
        }
    },
    mounted() {
        window.addEventListener("resize", this.setScreenSize, true);
        this.emitter.on('open-navigation-popover-review', () => {
            this.openNavigationDropdown('review')
        });
    },
    unmounted() {
        window.removeEventListener("resize", this.setScreenSize, true);
    },
    methods: {
        goHome() {
            this.$store.commit("setPageToEdit", "home");
            this.$router.push('home');
        },
        goBack() {
            if(this.isHomePage) {
                this.$store.commit("setPageToEdit", "home");
                this.$router.replace(`/home/portfolios/${this.$store.state.portfolio.shortId}`);
            } else {
                if (this.isPortfolioPage) {
                    this.$store.commit("setPageToEdit", this.$store.state.project.shortId);
                }
                this.$router.replace(`/home/portfolios/${this.$store.state.portfolio.shortId}/p/${this.$store.state.project.shortId}`);
            }
        },
        setScreenSize() {
            this.isSmallScreen = window.innerWidth < 1024
        },
    },
    computed: {
        isCaseStudy() {
            return !!this.$store.state.project && this.$store.getters.isCaseStudy;
        },
        isPortfolioPage() {
            return this.$store.state.portfolioPages.some(p => p.shortId === this.$store.state.project.shortId)
        },
        isHomePage() {
            return this.$store.getters.isHomePage;
        },
        isOwnerIsLoggedIn() {
            return this.$store.state.view.loggedInUserId === this.$store.state.project.ownerId
        }
    }
}
</script>

<style lang="scss">
#app:has(.view-nav-bar) .sticked {
    top: 60px;
}

</style>

<style lang="scss" scoped>
.ufo--nav-bar {
    height: 60px;
    background-color: var(--asphalt10);
    padding: 12px 30px;

    @media (min-width: 1024px) {
        padding: 12px 30px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    z-index: 103;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;

    &.dark {
        background-color: var(--asphalt100);
    }

    > .left,
    > .right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        @media (min-width: 640px) {
            gap: 8px;
        }

        .publish {
            margin: 0 16px 0 8px;
        }
    }
    > .left {
        margin-right: auto;
    }
    > .right {
        margin-left: auto;
    }

    .mid {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 12px;
    }

    &.dark {
        .logo {
            color: var(--asphalt20);
        }
    }
}

.logo {
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
    color: var(--asphalt90);
    @media (min-width: 1024px) {
        margin-right: 24px;
    }
}

.ufo-cta {
    .btn-content {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.account-name {
    width: 140px;
    text-align: center;
    @media (min-width: 1280px) {
        width: 234px;
    }
    @media (min-width: 1440px) {
        width: 326px;
    }
    height: 36px;
    padding: 0 12px;
    margin: 0;
    border-radius: 4px;
    background-color: var(--asphalt100);
    line-height: 36px;
    color: var(--asphalt10);
    font-size: 14px;
    font-family: var(--primarySans);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>