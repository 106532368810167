import { store as folioStore } from "../FolioStore.js";
import PortfoliosAPI from "../../api/portfolios/PortfoliosAPI.js";
import NotificationsAPI from "../../api/notification/NotificationsAPI.js";
import ProjectsAPI from '../../api/projects/ProjectsAPI.js';
import UrlHelper from "../../common/UrlHelper.js";
import DefaultTextStylesHelper from "../../common/templateDefaults/DefaultTextStylesHelper.js";
import Router from "../../router/Router.js"
import SettingsFontAPI from "../../api/settings/SettingsFontAPI.js";
import {event, pageview} from "vue-gtag";
import {specificFontsLoader} from "../../settingsEditor/_helpers/fontLoaders.js";
import GoogleFontsHelper from "../../common/GoogleFontsHelper.js";
import {UserActions} from "./UserActions.js";
import {ChecklistInteractionEnum, PageTypesEnum, SettingsPanelPathsEnum} from "../../common/enums.js";
import {resolveColor} from "@/scripts/common/helpers.js";
import MixpanelService from "../../analytics/mixpanel.js";

const store = folioStore.state;
const getters = folioStore.getters;

export const PortfolioActions = {

    // get portfolio
    getPortfolio: async function (portfolioId) {
        await PortfoliosAPI.getPortfolio(!!portfolioId ? portfolioId : store.portfolio._id)
            .then((response) => {
                store.portfolio = response.data;
                store.selectedPortfolio = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    },


    // get portfolios
    getPortfolios: async function (portfolioId) {
        const routerParams = Router.currentRoute.value.params;
        let currentPortfolioId = portfolioId ? portfolioId : !!routerParams.portfolioId ? routerParams.portfolioId : store.user.defaultCollectionId;
        await PortfoliosAPI.getPortfolios(store.user._id)
            .then(async (response) => {
                if(!response.data.length) {
                    store.showOnBoardingScreen = true;
                    await Router.push("/home/onboarding")
                }
                else {
                    store.userPortfolios = response.data;
                    store.portfolio = response.data.find(e => e.shortId === currentPortfolioId || e._id === currentPortfolioId);
                    store.selectedPortfolio = response.data.find(e => e.shortId === currentPortfolioId || e._id === currentPortfolioId);

                    await PortfolioActions.getPortfolioPages(store.portfolio._id);
                    await PortfolioActions.getCaseStudyPages(store.portfolio._id);

                    let fontStyles = Object.entries(store.portfolio.designSettings.fontStyles).map((i) => i[1]);
                    GoogleFontsHelper.downloadSingleFonts(Object.entries(fontStyles).map((i) => i[1]));
                    //download unique fonts
                    await specificFontsLoader(fontStyles)
                    store.portfolioSetupComplete = true;
                }
            })
            .catch(error => {
                console.log("Error while getting portfolios", error);
                Router.push('/home')
                UserActions.refreshData();
            })
            .finally(() => {
                // if not a utility page and the portfolioID is missing than go home
                if(Router.currentRoute.value.fullPath !== "/home/onboarding" &&
                    Router.currentRoute.value.fullPath !== "/home/portfolios" &&
                    Router.currentRoute.value.fullPath !== "/home/notifications" &&
                    Router.currentRoute.value.fullPath !== "/home/prices" &&
                    !Router.currentRoute.value.fullPath.includes("/home/settings") &&
                    !Router.currentRoute.value.fullPath.includes("home/payment") &&
                    !Router.currentRoute.value.fullPath.includes("home/domain") &&
                    !Router.currentRoute.value.fullPath.includes("home/url") &&
                    !Router.currentRoute.value.fullPath.includes("/reviews") &&
                    !Router.currentRoute.value.params.portfolioId &&
                    Router.currentRoute.value?.meta?.type !== 'view'    // Fix for View -> Back to editor -> View redirects
                ) {
                    if(store.portfolioPages) {
                        folioStore.commit("setPageToEdit", "home");
                    }
                    if(store.portfolio) {
                        Router.push(`/home/portfolios/${store.portfolio.shortId}`)
                    }

                }
            });
    },

    // get navigation items
    getPortfolioPages: async function(id, setHomePage) {
        if(id || store.portfolio) {
            try {
                let response = await ProjectsAPI.getPortfolioPages(!!id ? id : store.portfolio._id)

                store.portfolioPages = response.data.sort((a,b) => { return a.order - b.order } )
                if(setHomePage) {
                    store.project = store.portfolioPages.find(e => e.type === "home");
                }
            }
            catch (e) {
                console.log('Error while getting portfolio pages.')
                //TODO: error
            }
        }
    },

    // get case study projects
    getCaseStudyPages: async function(portfolioId) {
        if(portfolioId || store.portfolio) {
            try {
                let response = await ProjectsAPI.getCaseStudyPages(!!portfolioId ? portfolioId : store.portfolio._id)
                store.caseStudyPages = response.data.sort((a,b) => { return a.order - b.order } )
                if(!store.project) store.project = store.caseStudyPages.filter(p => p.type === "home");
            }
            catch (e) {
                console.log('Error while getting case studies')
            }
        }
    },

    // get all case study projects
    getAllCaseStudyPages: async function(userId) {
        if( userId || store.user) {
            try {
                let response = await ProjectsAPI.getAllProject(!!userId ? userId : store.user._id, true)
                store.allCaseStudyPages = response.data.filter(p => !p.deletedAt && p.type === "caseStudy");
            }
            catch (e) {
                console.log('Error while getting All case studies')
            }
        }
    },

    // create new page
    createPage(projectType, name, link) {
        store.savingProject = true;

        if (projectType === 'file') {
            if(store.user.checklistStatus && !store.user.checklistStatus.steps.resume) {
                folioStore.commit('updateChecklistItem', ChecklistInteractionEnum.RESUME);
            }
        }

        let designSettings = DefaultTextStylesHelper.getDesignSettingsByTheme(store.portfolio.templateSettings.theme);
        ProjectsAPI.createProject(
            store.user._id,
            store.portfolio._id,
            projectType,
            store.portfolioPages.filter(e => !e.deletedAt).length,
            name,
            link,
            null, // suggestions
            false, //is short case study
            designSettings,
            store.portfolio.templateSettings.defaultCaseStudyValues,
            store.portfolio.templateSettings.defaultThumbnailData

        )
            .then(response => {
                if(projectType === 'about' || projectType === 'contact' || projectType === 'blank') {
                    store.project = response.data;
                }
                folioStore.commit("addNewPageToList", response.data);
            })
            .catch(error => {
                PortfolioActions.handleCommonApiError(error);
            })
            .finally(() => {
                if(projectType === 'blank') PortfolioActions.trackEditEvent('Create Blank page');
                // PortfolioActions.getPortfolioPages();
                // PortfolioActions.getCaseStudyPages();
                // PortfolioActions.getAllCaseStudyPages();
                store.savingProject = false;
                if(projectType === 'about' || projectType === 'contact' || projectType === 'blank') {
                    Router.push(`/home/portfolios/${store.portfolio.shortId}/p/${store.project.shortId}/pages`)
                }
            });
    },

    // get page by short id
    async getPageByShortId(shortId) {
        ProjectsAPI.getProjectByShortId(store.portfolio.shortId, shortId)
            .then( response => {
                store.project = response.data;
                folioStore.commit("setAllowedSections", store.project.type);
            })
            .catch( error => {
                console.log(error)
                // page not found, go to home page
                folioStore.commit("setPageToEdit", "home");
                Router.push(`/home/portfolios/${store.portfolio.shortId}`)
            })
            .finally(() => {
                if(store.project) {
                    folioStore.commit("updateExistingStatSectionStyles");
                    folioStore.commit("updateExistingHeaderSectionStyle");
                    folioStore.commit("updateExistingHeaderSectionStyle");
                }
            });
    },

    updateProjectByShortId(shortId, titles, settings) {
        ProjectsAPI.updateProjectByShortId(store.portfolio.shortId, shortId, titles, settings)
            .then(response => {
                store.portfolioPages[store.portfolioPages.findIndex(e => e.shortId === shortId)] = response.data
            })
            .catch( error => {
                console.log(error)
            })
    },

    // delete page
    deletePage(pageId) {
        store.portfolioPages.find(e => e._id === pageId).deletedAt = 1;
        PortfolioActions.archivePage(pageId);
        PortfolioActions.reorderPages(true);
    },

    // reorder pages
    reorderPages(save) {
        const filteredMenuItems = store.portfolioPages.filter(e => !e.deletedAt)
        store.portfolioPages.forEach((e, index) => {
            if(!e.deletedAt) e.order = index
            if(!!e.deletedAt) e.order = filteredMenuItems.length + index
        })
        store.portfolioPages.sort((a, b) => { return a.order - b.order });

        if(save) PortfolioActions.updateNavItemsOrder();
    },

    // archive page
    archivePage(pageId) {
        ProjectsAPI.archiveProject(pageId)
            .then( response => {
                store.portfolioPages.find(e => e._id === pageId).deletedAt = !!response.data.deletedAt ? response.data.deletedAt : null;
                store.portfolioPages.find(e => e._id === pageId).draft = true;
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                store.selectedProject = null;
                if( Router.currentRoute.value.fullPath.indexOf('/settings/archive') > -1 ) return
                if( !!store.portfolioPages.find(e => e._id === pageId && !e.deletedAt && (e.type === 'about' || e.type === 'contact' || e.type === 'blank') ) ) {
                    store.project = store.portfolioPages.find(e => e._id === pageId)
                    Router.push(`/home/portfolios/${store.portfolio.shortId}/p/${store.project.shortId}/pages`)
                } else if ( !!store.portfolioPages.find(e => e._id === pageId && !e.deletedAt && e.type === 'file') ) {
                    Router.back();
                }
            });
    },

    updatePageStyle() {
        store.savingProject = true;
        PortfoliosAPI.editPortfolio(store.portfolio)
            .then(response => {
                store.portfolio = response.data;
                store.selectedPortfolio = response.data;
                //PortfolioActions.updatePortfolioInUserObj();
            })
            .catch(error => { console.log(error) })
            .finally(() => {store.savingProject = false});
    },

    async updateNavButtonStyle(buttonStyle) {
        return await ProjectsAPI.updateNavButtonStyle(
            store.user._id,
            store.portfolio._id,
            buttonStyle
    )},

    updatePortfolio(field, skipStoreUpdate, keepModalOpen) {
        if(field) PortfolioActions.trackEditEvent(field);
        store.savingProject = true;
        PortfoliosAPI.editPortfolio(store.portfolio)
            .then(response => {
                if(!skipStoreUpdate) {
                    store.portfolio = response.data;
                    store.selectedPortfolio = response.data;
                    folioStore.commit("updatePortfolioInUserPortfolios", response.data);
                }
            })
            .catch(error => {
                console.log(error)
                store.activeModal = null;
                const errorObject = error.response.data;
                if(error.response.status === 405 && (errorObject.code === 1007)) {
                    folioStore.commit("setCurrentPlan", errorObject.currentSubscriptionPlan);
                    folioStore.commit("showAlertMessage", {
                        message: 'Upgrade to Full access to use this feature.',
                        type: 'alert',
                        interval: 10,
                    });
                }
            })
            .finally(() => {
                if(!keepModalOpen) {
                    store.activeModal = null;
                }
                store.savingProject = false;
                folioStore.commit("updateSidePanel", {allowSave: false});
            });
    },

    setPortfolioGlobalSpacing() {
        store.savingProject = true;
        PortfoliosAPI.setPortfolioGlobalSpacing(store.project)
            .then(response => {
                store.project = response.data.find(e => e._id === store.project._id);
                store.portfolioPages = response.data;
            })
            .catch(error => { console.log(error) })
            .finally(() => {store.savingProject = false});
    },

    setTemplateForPortfolio() {
        store.savingProject = true;
        PortfoliosAPI.setTemplateForPortfolio(store.portfolio)
            .then(response => {
                store.portfolio = response.data;
                store.selectedPortfolio = response.data;
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                store.savingProject = false;
            });
    },

    updateNavItemsOrder() {
        let pages = []
        store.portfolioPages.forEach((e, index) => pages.push({'projectId': e._id, 'order': e.order}))
        store.savingProject = true;
        ProjectsAPI.updatePortfolioPagesOrder(store.portfolio._id, pages)
            .then(response => {})
            .catch(error => console.log(error))
            .finally(() => {
                // update portfolio to keep navItems in sync
                PortfolioActions.updatePortfolio()
                store.savingProject = false
                folioStore.commit("updateSidePanel", {allowSave: false});
            })
    },

    updateCaseStudiesOrder() {
        store.savingProject = true;
        ProjectsAPI.updateCaseStudyPagesOrder(store.portfolio._id, store.caseStudyPages)
            .then(response => {
                PortfolioActions.getCaseStudyPages();
            })
            .catch(error => console.log(error))
            .finally(() => {
                store.savingProject = false
            })
    },

    setDefaultPagesForPortfolio(portfolio) {
        store.savingProject = true;
        PortfoliosAPI.addDefaultPortfolioPages(portfolio)
            .then(response => {
                store.portfolioPages = response.data.sort((a,b) => { return a.order - b.order } );
            })
            .catch(error => {console.log(error)})
    },

    getPortfolioTemplateSettings() {
        store.savingProject = true;
        PortfoliosAPI.getPortfolioTemplateSettings(store.portfolio._id)
            .then(response => {
                store.portfolio.templateSettings = response.data;
            })
            .catch(error => {console.log(error)})
            .finally(() => {
                store.savingProject = false;
            })
    },

    getPortfolioTemplateAndDesignSettings() {
        store.savingProject = true;
        PortfoliosAPI.getPortfolio(store.portfolio._id)
            .then(response => {
                store.portfolio.templateSettings = response.data.templateSettings;
                store.portfolio.designSettings = response.data.designSettings;

                const target = document.querySelector("body");
                target.style.backgroundColor = resolveColor(response.data.designSettings.colorStyles.background, response.data.designSettings.colorPalette);
            })
            .catch(error => {console.log(error)})
            .finally(() => {
                store.savingProject = false;
            })
    },

    getPortfolioSettings() {
        store.savingProject = true;
        PortfoliosAPI.getPortfolioSettings(store.portfolio._id)
            .then(response => {
                store.portfolio.settings = response.data;
            })
            .catch(error => {console.log(error)})
            .finally(() => {
                store.savingProject = false;
            })
    },

    switchPortfolioDraft(portfolio) {
        PortfolioActions.trackEditEvent(`${portfolio.draft ? 'Publish' : 'Unpublish'} portfolio`);
        PortfoliosAPI.setPortfolioDraft(portfolio._id, !portfolio.draft)
            .then(response => {
                if(response.data === true) {
                    PortfolioActions.getPortfolioPages();
                }
                    folioStore.commit("showAlertMessage", {
                        message: portfolio.draft
                            ? 'Your portfolio is now public'
                            : 'Your portfolio is set to draft',
                        interval: 5,
                        type: 'success'
                    });

                    store.portfolio.draft = !store.portfolio.draft;
            }).catch(error => {
            store.activeModal = null;
            if(error.response && error.response.data) {
                let errorObject = error.response.data;
                if(error.response.status === 405 && (errorObject.code === 1001 || errorObject.code === 1002)) {
                    store.currentSubscriptionPlan = errorObject.currentSubscriptionPlan;
                    folioStore.commit("showAlertMessage", {
                        message: 'Publishing failed. Please try again!',
                        interval: 5,
                    });
                }
            }
        })
            .finally(() => store.selectedPortfolio = null)
    },

    switchProjectDraft(project) {
        PortfolioActions.trackEditEvent(`${project.draft ? 'Publish' : 'Unpublish'} project`);
        ProjectsAPI.setProjectDraft(project._id, !project.draft)
            .then(response => {
                if(response.data === true) {
                    PortfolioActions.getCaseStudyPages();
                }

                folioStore.commit("showAlertMessage", {
                    message: project.draft
                        ? store.portfolio?.draft
                            ? 'Case study published. It will be visible once your portfolio is public.'
                            : 'Your case study is now public'
                        : 'Your case study is set to draft',
                    interval: 5,
                    type: 'success'
                });

                if(getters.isCaseStudy) {
                    store.project.draft = !store.project.draft;
                }
            }).catch(error => {
                store.activeModal = null;
                if(error.response && error.response.data) {
                    let errorObject = error.response.data;
                    if(error.response.status === 405 && (errorObject.code === 1001 || errorObject.code === 1002)) {
                        store.currentSubscriptionPlan = errorObject.currentSubscriptionPlan;
                        folioStore.commit("showAlertMessage", {
                            message: 'Publishing failed. Please try again!',
                            type: 'alert',
                            interval: 5,
                        });
                    }
                }
            })
            .finally(() => store.selectedProject = null)
    },

    deletePortfolioImage(imageUrl, modal) {
        store.savingProject = true;
        PortfoliosAPI.deletePortfolioImage(store.portfolio._id, imageUrl)
            .then(response =>{})
            .catch(response => {})
            .finally(() => {
                store.savingProject = false;
                if(!!modal) store.activeModal = null;
            });
    },

    updatePortfolioLogo(gaLabel) {
        store.savingProject = true;
        PortfoliosAPI.updatePortfolioLogo(store.portfolio.templateSettings.logo, store.portfolio._id)
            .then(response => {
                if(gaLabel) PortfolioActions.trackEditEvent(gaLabel);
                store.portfolio.templateSettings.logo = response.data.templateSettings.logo;
            })
            .catch(error => {
                if(error.response.status === 405) {
                    store.currentSubscriptionPlan = error.response.data.currentSubscriptionPlan;
                    folioStore.commit("showAlertMessage", {
                        message: 'Upgrade to Full access to use this feature.',
                        type: 'alert',
                        interval: 10,
                    });
                }
            })
            .finally(() => {
                store.savingProject = false;
                folioStore.commit("navigateBetweenSidePanels", {mainPanel: SettingsPanelPathsEnum.ROOT});
            })
    },

    // notifications actions
    getLatestNotifications: async function () {
        if(store.user) {
            await NotificationsAPI.getAllNotificationForUser(
                store.user._id
            )
                .then(response => {
                    store.notifications = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    clickNotification(notification, alreadySeen) {
        PortfolioActions.trackEditEvent('Click notification in dropdown');
        if(!alreadySeen)  {
            NotificationsAPI.setNotificationSeenBy(
                store.user._id,
                notification.id
            ).then(response => {
                UserActions.refreshData();

            }).catch(error => {
                console.log(error);
            });
        }
        PortfolioActions.openReview(notification);
    },
    setAllNotificationSeen() {
        PortfolioActions.trackEditEvent('Click notification in dropdown');
        NotificationsAPI.setNotificationSeenBy(
            store.user._id,
            null
        ).then(response => {
            store.notifications = response.data;
            UserActions.refreshData();
        }).catch(error => {
            console.log(error);
        });
    },
    async openReview(notification) {
        if(notification.navigation) {
            if(notification.navigation === 'Upgrade') {
                MixpanelService.track("UPGRADE_CTA_CLICKED",
                    {
                        ...MixpanelService.getUrls(),
                        "event_group": MixpanelService.MixpanelEventGroups.USER_LIFECYCLE,
                        "source": "Notification",
                        "context": "Notification",
                        "to": "Payment"
                    }
                )

                if(store.currentSubscriptionPlan === "Free") {
                    await Router.push({path:'/home/payment', state: { subscriptionData: { billingCycle:'monthly' }}});
                }
            }
            else if(notification.navigation === "Account") {
                window.location.href = "/home/settings/account-settings";
            }
            else if(notification.navigation === 'Review') {
                await PortfolioActions.handleReviewRedirect(notification);
            }
            else if(notification.navigation === 'Animations') {
                await Router.push({ path: `/home/portfolios/${store.portfolio.shortId}/style/animation-settings` });
            }
            else if(notification.navigation === 'Pricing') {
                await Router.push({ path: `/home/prices` });
            }
            else {
                window.location.href = "/home/notifications";
            }
        }
    },
    async handleReviewRedirect(notification) {
        if(notification.additionalInfo.type === 'ReviewSubmitted') {
            let url = window.location.origin + "/myreviews/" + notification.additionalInfo.shortId + "?reviewer=" + notification.additionalInfo.fromUserId;
            window.open(url, '_blank').focus();
        }
        else if(notification.additionalInfo.type === 'FollowupSentToOwner') {
            let url = window.location.origin + "/myreviews/" + notification.additionalInfo.shortId + "?reviewer=" + notification.additionalInfo.fromUserId + "&followup=true";
            window.open(url, '_blank').focus();
        }
        else if(notification.additionalInfo.type === 'FollowupSentToReviewer') {
            let url = window.location.origin + "/review/" + notification.additionalInfo.shortId + "?followup=true";
            window.open(url, '_blank').focus();
        }
    },

    handleCommonApiError(error){
        if(!!error.response && error.response.status === 405) {
            store.currentSubscriptionPlan = error.response.data.currentSubscriptionPlan;
            folioStore.commit("showAlertMessage", {
                message: 'Upgrade to Full access to use this feature.',
                type: 'alert',
                interval: 10,
            });
        }
    },

    // tracking actions
    trackPageView(url, label) {
        pageview({
            'page_path':url,
            'page_title':label,
            'page_location':location.href
        });
    },
    trackPaywallEvent(location) {
        let event_action = "Paywall appeared";
        try {
            event(event_action, {
                'location': location,
                'event_label': `User: ${store.user._id}, Portfolio: ${store.portfolio ? store.portfolio._id : 'none'}`
            });
        }
        catch(err) {
            console.log('Error while sending GEVENT: ' + err)
        }
    },
    trackEditEvent(eventAction, eventLabelParam) {
        // [eventCategory], [eventAction], [eventLabel]
        // [eventLabel]: User: [userId], Portfolio: [portfolioId], eventLabelParam
        const editorName =  !!store.sectionEditorSettings.pageToEdit ?
                            `${store.sectionEditorSettings.pageToEdit.charAt(0).toUpperCase() + store.sectionEditorSettings.pageToEdit.slice(1)} page` :
                            'Profile';

        event(eventAction, {
            'event_category': (store.project && store.project.type) !== PageTypesEnum.CASE_STUDY ? `${editorName} editor` : 'Project editor',
            'event_label': eventLabelParam
                ? `User: ${store.user._id}, Portfolio: ${store.portfolio ? store.portfolio._id : 'none'}, ${eventLabelParam}`
                    : `User: ${store.user._id}, Portfolio: ${store.portfolio ? store.portfolio._id : 'none'}`
        });

        event('editor_action', {
            'event_source': (store.project && store.project.type) !== PageTypesEnum.CASE_STUDY ? `${editorName} editor` : 'Project editor',
            'event_name': eventAction,
            'event_params': eventLabelParam
                ? `User: ${store.user._id}, Portfolio: ${store.portfolio ? store.portfolio._id : 'none'}, ${eventLabelParam}`
                : `User: ${store.user._id}, Portfolio: ${store.portfolio ? store.portfolio._id : 'none'}`
        });
    },

    // helper actions
    async getAllAvalibleFonts() {
        await SettingsFontAPI.getFonts("").then(response => {
            store.fonts = response.data;
        }).catch(error => {
            console.log(error);
        });
    },
};

export function TrackPageView(url, label) {
    //TODO: check google analytics
    pageview({
        'page_path':url,
        'page_title':label,
        'page_location':location.href
    });
}