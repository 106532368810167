import {FreeFeatureList, PremiumFeatureList, SubscriptionPlanType, TrialFeatureList} from "./enums.js";

export function hexToHSL(hex) {
    let sliced_hex = hex.slice(0, 7); // Ensure the hex is in the format #RRGGBB
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sliced_hex);
    if (!result) {
        throw new Error("Invalid hex color format");
    }

    let r = parseInt(result[1], 16) / 255;
    let g = parseInt(result[2], 16) / 255;
    let b = parseInt(result[3], 16) / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let delta = max - min;

    // Calculate lightness
    let l = (max + min) / 2;

    let h = 0; // Default hue
    let s = 0; // Default saturation

    if (delta !== 0) {
        // Calculate saturation
        s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

        // Calculate hue
        switch (max) {
            case r:
                h = ((g - b) / delta + (g < b ? 6 : 0)) % 6;
                break;
            case g:
                h = (b - r) / delta + 2;
                break;
            case b:
                h = (r - g) / delta + 4;
                break;
        }

        h *= 60; // Convert to degrees
    }

    return {
        h: Math.round(h),
        s: parseFloat(s.toFixed(2)), // Keep two decimal places
        l: parseFloat(l.toFixed(2))  // Keep two decimal places
    };
}

export function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;

    if (0 <= h && h < 60) {
        r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255).toString(16).padStart(2, "0");
    g = Math.round((g + m) * 255).toString(16).padStart(2, "0");
    b = Math.round((b + m) * 255).toString(16).padStart(2, "0");

    return `#${r}${g}${b}`;
}

export function hslObjectToCSS(hsl) {
    const h = Math.round(hsl.h);
    const s = Math.round(hsl.s * 100); // Convert to percentage
    const l = Math.round(hsl.l * 100); // Convert to percentage
    return `hsl(${h}, ${s}%, ${l}%)`;
}

export function adjustLightness(hsl, adjustment) {
    const changeInLightness = adjustment * 0.01;
    const adjustedObject = {...hsl}
    adjustedObject.l = Math.max(0, Math.min(1, hsl.l + changeInLightness));
    return adjustedObject;
}
export function hslToCSS(hsl) {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
}

export function blendColors(hexTopColor, hexBottomColor, topAlpha) {
    // Convert hex color to RGB
    function hexToRGB(hex) {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(h => h + h).join('');
        }
        return {
            r: parseInt(hex.substring(0, 2), 16),
            g: parseInt(hex.substring(2, 4), 16),
            b: parseInt(hex.substring(4, 6), 16)
        };
    }

    // Get RGB values for both colors
    const colorA = hexToRGB(hexTopColor);
    const colorB = hexToRGB(hexBottomColor);

    // Calculate the resulting color
    const r = Math.round(colorA.r * topAlpha + colorB.r * (1 - topAlpha));
    const g = Math.round(colorA.g * topAlpha + colorB.g * (1 - topAlpha));
    const b = Math.round(colorA.b * topAlpha + colorB.b * (1 - topAlpha));

    // Convert the result to hex format
    return rgbToHex(r, g, b);
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function hexToRgba(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function parseRgbString(rgbString) {
    const match = rgbString.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/);

    if (match) {
        return {
            r: parseInt(match[1], 10),
            g: parseInt(match[2], 10),
            b: parseInt(match[3], 10),
            a: match[4] !== undefined ? parseFloat(match[4]) : 1, // Default alpha to 1 if not provided
        };
    } else {
        throw new Error("Invalid RGB(A) format. Expected formats: rgb(231, 19, 59) or rgba(231, 19, 59, 0.5)");
    }
}

export function generateSuggestedColors(colorStyles, colorPalette) {
    const bg = resolveColor(colorStyles.background, colorPalette);
    const h1 = resolveColor(colorStyles.h1, colorPalette);
    const buttonFill = colorStyles.buttonFill === 'transparent'
        ? resolveColor(colorStyles.buttonOutline, colorPalette)
        : resolveColor(colorStyles.buttonFill, colorPalette);

    return {
        solid: {
            subtle: [blendColors(h1, bg, 0.08)],
            platform: [blendColors(h1, bg, 0.16)],
            accent: [blendColors(buttonFill, bg, 0.28)],
            accent_platform: [blendColors(buttonFill, bg, 0.48)],
            contrast: [blendColors(h1, bg, 0.72)]
        },
        gradient: {
            subtle: [bg, blendColors(h1, bg, 0.32)],
            platform: [blendColors(h1, bg, 0.16), blendColors(h1, bg, 0.64)],
            accent: [bg, buttonFill],
            accent_platform: [blendColors(buttonFill, bg, 0.24), blendColors(buttonFill, bg, 0.64)],
            contrast: [blendColors(h1, bg, 0.64), h1]
        }
    }
}

export function resolveColor(colorObject, colorPalette) {
    return colorObject.paletteColorIndex !== null && colorObject.paletteColorIndex !== undefined && colorPalette.colors
        ? colorPalette.colors[colorObject.paletteColorIndex]
        : colorObject.primaryColor || colorObject.color;
}

export function isHexColor(color) {
    return typeof color === "string" && color.startsWith("#");
}

export function isRgbColor(color) {
    return typeof color === "string" && color.startsWith("rgb(");
}

export function isCssVariableColor(color) {
    return typeof color === "string" && color.startsWith("var(--");
}


export function isFeatureAvailable(feature, subscriptionType, subscriptionStatus = "canceled") {
    if (subscriptionType !== SubscriptionPlanType.VIP && (subscriptionStatus === "canceled" || subscriptionStatus === 'past_due')) {
        subscriptionType = SubscriptionPlanType.FREE;
    }

    switch (subscriptionType) {
        case SubscriptionPlanType.FREE:
            return FreeFeatureList.includes(feature)
        case SubscriptionPlanType.TRIAL :
            return TrialFeatureList.includes(feature)
        case SubscriptionPlanType.PREMIUM:
        case SubscriptionPlanType.STANDARD:
        case SubscriptionPlanType.VIP:
            return PremiumFeatureList.includes(feature)
        default:
            return false
    }
}

export function getIframeAttributes(media) {
    const { url, width, height } = media;
    if (!url) return {};

    return {
        src: url,
        width: width || "100%",
        style: {
            "aspect-ratio": width && height ? width / height : "16/9"
        }
    };
}