<template>
    <div class="mockup" @click="$emit('click')">
        <div class="ufo-mockup--display">
            <div class="ufo-mockup--frame">
                <Mockup
                    :image-src="defaultImageSrc"
                    :svg-src="mockupSrc"
                    :image-loaded="imageLoaded ? imageLoaded($refs.img) : null"
                    :altTag="altTag"
                    :media="media"
                    :index="index"
                    :class="!mockupData && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                    :allow-animation="allowAnimation"
                    :shadow="media.mockupSettings.shadow"
                    :mockup="mockupData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Mockup from "@/scripts/common/components/Mockup.vue";
import { findMockup } from "@/scripts/common/mockupStore/mockupStore.js";
import { MockupDeviceType, MockupType } from "@/scripts/common/mockupStore/mockupStructure.js";

export default {
    name: "MockupContainer",
    components: { Mockup },
    props: [
        "media",
        "imageLoaded",
        "setLightbox",
        "index",
        "forcedAspectRatio",
        "allowAnimation"
    ],
    emits: ["click"],
    data() {
        return {
            mockupTypeEnum: MockupType
        };
    },
    computed: {
        defaultImageSrc() {
            const { media, project } = this;
            if (media.croppedImageUrl) return media.croppedImageUrl;
            if (!media.url.includes("/example")) return media.url;

            const deviceType = media.mockupSettings ? media.mockupSettings.deviceType : null;
            if (media.url.includes("suggestion-wireframes")) {
                if (project.aiGenerated && deviceType) {
                    let refactoredType = deviceType !== MockupDeviceType.MOBILE ? deviceType.toLowerCase() : "tall"
                    return `/example_project_images/gallery_sample/gallery-sample-${refactoredType}-01-ai.png`;
                }
                if (deviceType === MockupDeviceType.LAPTOP || deviceType === MockupDeviceType.DESKTOP) {
                    return "/example_project_images/v2/suggestion-wireframes-desktop.png";
                }
            }

            if (media.url.includes("gallery-sample-tall") && deviceType) {
                if (project.aiGenerated) {
                    if(deviceType) {
                        let refactoredType = deviceType !== MockupDeviceType.MOBILE ? deviceType.toLowerCase() : ""
                        return this.modifyDefaultAiMockupImageUrl(refactoredType);
                    }
                    else {
                        return this.modifyDefaultAiMockupImageUrl("");
                    }
                } else {
                    if (deviceType === MockupDeviceType.LAPTOP || deviceType === MockupDeviceType.DESKTOP) {
                        return media.url.replace("tall", "desktop").replace("jpg", "png");
                    }
                    if (deviceType === MockupDeviceType.TABLET) {
                        return media.url.replace("tall", "tablet").replace("jpg", "png");
                    }
                }
            }
            return media.url;
        },
        mockupData() {
            return this.media.mockupSettings;
        },
        mockupSrc() {
            const { deviceType, mockupType, version, colorScheme, settings, orientation } = this.mockupData || {};
            return findMockup(deviceType, mockupType, version, colorScheme, settings, orientation);
        },
        altTag() {
            return this.media.altTag || "Image gallery item";
        },
        project() {
            return this.$store.state.project;
        }
    },
    methods: {
        modifyDefaultAiMockupImageUrl(device) {
            let modifiedUrl = device ? this.media.url.replace("tall", device).replace("jpg", "png") : this.media.url.replace("jpg", "png");
            const dotIndex = modifiedUrl.lastIndexOf(".");
            if (dotIndex !== -1) {
                modifiedUrl =
                    modifiedUrl.slice(0, dotIndex) +
                    "-ai" +
                    modifiedUrl.slice(dotIndex);
            }
            return modifiedUrl;
        }
    }
};
</script>

<style scoped lang="scss">

.img-overlay-wrap {
    position: relative;
    display: inline-block;
    transition: transform 150ms ease-in-out;
}

.img-overlay-wrap .img-scroll {
    display: block;
    width: auto;
    aspect-ratio: 744 / 1133;

    img {
        aspect-ratio: 744 / 1133;
    }
}

.img-overlay-wrap svg {
    height: 100%;
    position: absolute;
    width: 111%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mockup {
    height: 100%;
    width: auto;
    .ufo-mockup--display {
        height: 100%;
        .ufo-mockup--frame {
            height: 100%;
        }
    }
}
</style>
