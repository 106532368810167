<template>
    <div v-if="emergencyModeSettings && emergencyModeSettings.emergencyMode && emergencyModeSettings.landingEmergencyBanner" class="alert-banner-container">
        <div class="banner-content-container">
            <div class="banner-text-container">
                <div class="info-icon">
                    <Icon :icon-name="'info'" />
                </div>
                <div class="message">
                    {{ emergencyModeSettings.landingEmergencyBannerText }}
                </div>
            </div>
        </div>
    </div>
    <div v-else class="divider"></div>

    <header>

        <div class="content">
            <div class="logo">
                <router-link to="/">
                    <UXFolioLogo />
                </router-link>
            </div>

            <template v-if="!referral && !comingSoon">
                <nav :class="{'on' : mobileNavOpen}">
                    <component v-for="item in navItems"
                               :key="item.id"
                               :is="!item.external ? 'router-link' : 'a'"
                               :to="!item.external ? item.route : null"
                               :href="item.route"
                    >
                    <span :data-label="item.label">
                        {{ item.label }}
                    </span>
                        <ArrowLargeIcon />
                    </component>
                    <CtaButton type="secondary" label="Log in" class="login" @click="goToLogin()" />
                </nav>
            </template>

            <div class="actions">
                <CtaButton v-if="!referral" type="secondary" label="Log in" class="login" @click="goToLogin()" />
                <CtaButton :id="referral ? 'signup_referral_button_nav' : 'signup_button_nav'" label="Sign up" @click="goToSignup()" />
                <CtaButton type="secondary" class="toggler" @click="toggleMobileNav()">
                    <HamburgerIcon />
                </CtaButton>
            </div>
        </div>

    </header>
</template>

<script>
import UXFolioLogo from "../icons/UXfolioLogo.vue";
import HamburgerIcon from "../icons/HamburgerIcon.vue";
import ArrowLargeIcon from "../icons/ArrowLargeIcon.vue";
import CtaButton from "./CtaButton.vue";
import {useHead} from "@unhead/vue";
import Icon from "../../../common/Icon.vue";
import ApiHelper from "@/scripts/api/ApiHelper.js";
import MixpanelService from "../../../analytics/mixpanel.js";

let head = null;

    export default {
        name: "HeaderPage",
        props: {
          referral: {
              type:Boolean, //This is to remove some buttons for referral page, default it's false
              default:false
          },
          comingSoon: {
              type: Boolean,
              default: false
          },
        },
        components: {
            Icon,
            UXFolioLogo,
            HamburgerIcon,
            ArrowLargeIcon,
            CtaButton
        },
        beforeCreate() {
            head = useHead({
                link: [
                    {rel: "preconnect", href: "https://fonts.googleapis.com"},
                    {rel: "preconnect", href: "https://fonts.gstatic.com", crossorigin: true},
                    {rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"},
                ]
            })
        },
        beforeMount() {
            if (!this.comingSoon) {
                this.getEmergencyModeSettings();
            }
        },
        data() {
            return {
                navItems: [
                    {
                        id: 1,
                        label: "Examples",
                        route: "/examples"
                    },
                    {
                        id: 2,
                        label: "Pricing",
                        route: "/pricing"
                    },
                    {
                        id: 3,
                        label: "Blog",
                        route: "https://blog.uxfol.io/",
                        external: true
                    },
                ],
                mobileNavOpen: false
            }
        },
        methods: {
            goToSignup() {

                const utmCampaignSource = this.$route.fullPath === "/" ? "/landing" : this.$route.fullPath.includes("/referral") ? "/referral" : this.$route.fullPath.includes("/examples/") ? "/examples" : this.$route.fullPath;
                const referral = this.$route.params.referral_id ? '&referral=' + this.$route.params.referral_id : ''

                MixpanelService.track("SIGNUP_CTA_CLICKED",
                    {
                        "event_group": MixpanelService.MixpanelEventGroups.USER_LIFECYCLE,
                        "source": "Landing Nav Bar",
                        "url": utmCampaignSource,
                    }
                )

                window.location = '/registration/' + window.location.host + utmCampaignSource + `?mode=${this.$store.state.view.publicPages.activeMode}` + referral;
            },
            goToLogin() {
                window.location = "/home"
            },
            async getEmergencyModeSettings() {
                let res = await ApiHelper.get('/data/emergency')
                this.$store.state.view.emergencyModeSettings = res.data;
            },
            navigate(evt, route) {
                evt.preventDefault();
                this.toggleMobileNav(false);
                this.$router.push(route);
            },
            scrollTo(evt, hash) {
                evt.preventDefault();
                this.toggleMobileNav(false);

                if(this.$route.fullPath === "/") {
                    this.$router.push({ hash: hash })
                } else {
                    this.$router.push({name: "Index", hash: hash })
                }
            },
            toggleMobileNav(val) {
                this.mobileNavOpen = val !== undefined ? val : !this.mobileNavOpen;
            }
        },
        computed: {
            emergencyModeSettings() {
                return this.$store.state.view.emergencyModeSettings;
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../styles/public-pages/components/header";
</style>

<style lang="scss" global>
.alert-banner-container {
    min-height: 56px;
    width: 100%;
    background-color: #DCFA37;
    display: flex;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 769px) {
        min-height: 56px;
    }

    @media (max-width: 640px) {
        min-height: 70px;
    }

    .banner-content-container {
        @media (max-width: 769px) {
            padding: 0 62px;
        }

        @media (max-width: 640px) {
            padding: 0;
        }

        .banner-text-container {
            padding: 16px;
            display: flex;

            @media (max-width: 640px) {
                padding: 12px 6px;
            }

            .info-icon {
                @media (max-width: 640px) {
                    font-size: 12px;
                }

                svg {
                    height: 23px;
                    color:  #0F0F0F;
                }
            }

            .message {
                margin-left: 8px;
                color: #0F0F0F;
                font-family: var(--primarySans);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;

                @media (max-width: 769px) {
                    font-size: 9px;
                }
            }
        }
    }
}
</style>