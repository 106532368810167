import ApiHelper from '../ApiHelper.js';

const UserApiUrl = "/api/users/";

export default {

    getMyUser() {
        return ApiHelper.get(UserApiUrl + "me");
    },

    createMyUser() {
        return ApiHelper.post(UserApiUrl + "me");
    },

    editUser(user) {
        return ApiHelper.put(UserApiUrl, user);
    },

    getUserSettings(userId) {
        return ApiHelper.get(UserApiUrl + userId + "/settings");
    },

    deleteCV(userId) {
        return ApiHelper.delete(UserApiUrl + userId + "/cv");
    },

    deleteUserImage(userId, imageUrl) {
        return ApiHelper.delete("/api/image/user/delete/" + userId, {url: imageUrl});
    },

    deleteUser(userId) {
        return ApiHelper.delete(UserApiUrl + userId);
    },

    deleteUserAsAdmin(userId) {
        return ApiHelper.delete(UserApiUrl+ "admin/"  + userId);
    },

    updateLoginEmail(userId, newLoginEmail) {
        return ApiHelper.post(UserApiUrl + "updateLoginEmail/" + userId + "/" + newLoginEmail);
    },

    changePassword(userId, newPassword) {
        return ApiHelper.post(UserApiUrl + "passwordChange/" + userId, { newPassword: newPassword } );
    },

    updateUserInDrip(userId, dripEvent) {
        return ApiHelper.post(`${UserApiUrl}dripUpdate/${userId}/${dripEvent}`);
    }
}
