import { store } from "../FolioStore.js";
import UsersAPI from '../../api/users/UserAPI.js';
import {PortfolioActions} from "./PortfolioActions.js";
import {event} from "vue-gtag";
import ApiHelper from "../../api/ApiHelper.js";
import MixpanelService from "@/scripts/analytics/mixpanel.js";

export const UserActions = {
    // create user
    createUser: async function () {
        try {
            let response = await UsersAPI.createMyUser();
            store.state.user = response.data.user;

            await this.getUserSettings();

            event('New user login', {
                'event_category': 'Project editor',
                'event_label': `User: ${store.state.user._id}`
            });
        }
        catch (error) {
            if (error.response && error.response.status === 409) { // User already exist
                window.location.reload();
            }
        }
    },

    // get user
    getUser: async function () {
        try {
            let response = await UsersAPI.getMyUser();
            store.state.user = {...response.data.user, paymentMethods: response.data.userPaymentMethods};
            store.state.emergencyModeSettings = response.data.emergencyModeSettings;
            if (response.data.user.checklistStatus) {
                store.commit("updateChecklistFinished", response.data.user.checklistStatus.finished);
            }
            await UserActions.getUserSettings();
            UserActions.setUserDataInSupportWidget(store.state.user.name, store.state.user.email);
        }
        catch(error) {
            if (error.response.status === 404) {
                await UserActions.createUser();
            }

            //keycloak user also deleted
            if (error.response.status === 403) {
                window.location.href = "/logout"
            }
        }
    },
    async getUserSettings() {
        try {
           let response = await UsersAPI.getUserSettings(store.state.user._id);
            store.state.userSettings = response.data;
            store.state.currentSubscriptionPlan = response.data.currentSubscriptionPlan;

            MixpanelService.identify(store.state.user._id)
            MixpanelService.setUser(store.state.user)
        }
        catch(error) {
            console.log('failed to get user settings', error)
        }
    },

    deleteUserImage(imageUrl) {
        store.state.savingProject = true;
        if(imageUrl) {
            UsersAPI.deleteUserImage(store.state.user._id, imageUrl)
                .then(response =>{})
                .catch(response => {})
                .finally(() => {store.state.savingProject = false;})
        }
    },

    updateUserInDrip(dripEvent) {
        UsersAPI.updateUserInDrip(store.state.user._id, dripEvent)
            .then(response => {})
            .catch(error => {console.log(error)})
    },

    async refreshData() {
        await UserActions.getUser();
        await PortfolioActions.getPortfolios()
        await PortfolioActions.getAllCaseStudyPages()
        await PortfolioActions.getLatestNotifications()
        await PortfolioActions.getAllAvalibleFonts()
    },

    setUserDataInSupportWidget(visitorName, visitorEmail) {
        if(window.fwSettings) {
            FreshworksWidget('identify', 'ticketForm', {
                name: visitorName,
                email: visitorEmail
            });
        }
    },
}
