<template>
    <div ref="scrollable" class="scrollable-container">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: "ScrollableContainer",
        computed: {
            selectedTab() {
                return this.$store.state.sidePanelSettings.activeTab;
            }
        },
        watch: {
            selectedTab() {
                this.$refs.scrollable.scrollTop = 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scrollable-container {
        position: relative;
        flex: 2;
        overflow-y: auto;
        overscroll-behavior: none;
    }
</style>