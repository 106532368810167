<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--stat">
        <ToggleGrid />
        <div class="grid">
            <div :class="['ufo--columns', 'ufo--columns-' + section.statItems.length]">
                    <div v-for="(data, index) in section.statItems" class="ufo--column" :style="{'--numberFontSize': getFontSize(data.data, 80), '--textFontSize': getFontSize(data.description, 30)}">
                        <div class="ufo--column-content">
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div :class="['number', data.data.style && data.data.style.textStyle ? data.data.style.textStyle : 'default']"
                                         :style="{'--numberSize': numberSize(data)}"
                                         v-html="fixText(data.data.text)"
                                    >
                                    </div>
                                </template>
                            </AnimationWrapper>
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div :class="['unit', data.description.style && data.description.style.textStyle ? data.description.style.textStyle : 'default']"
                                         :style="{'--unitSize': unitSize(data) }"
                                         v-html="fixText(data.description.text)"
                                    >
                                    </div>
                                </template>
                            </AnimationWrapper>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";

export default {
    name: "Stats",
    components: {AnimationWrapper, ToggleGrid },
    props: ['section'],
    methods: {
        fixText(data) {
            return data.replaceAll('\n', '<br/>')
        },
        getFontSize(item, def) {
            return `${item.style && item.style.fontSize ? item.style.fontSize : def}px`
        },
        numberSize(item) {
            return item.data.style && item.data.style.fontSize ? item.data.style.fontSize + 'px' : null
        },
        unitSize(item) {
            return item.description.style && item.description.style.fontSize ? item.description.style.fontSize + 'px' : null
        }
    }
}
</script>

<style lang="scss" scoped>
    .ufo-sec {
        .number {
            * {
                font-size: var(--numberSize);
            }
        }
        .unit {
            * {
                font-size: var(--unitsize);
            }
        }
    }
</style>
