<template>
    <div :class="['ufo-sec--bg', {gradient : showGradient}, setCustomClass]"
         :style="[setBackgroundImage, setOverlay]">
        <div v-if="!!section.usingCustomBackground && section.selectedBackgroundStyleId > 0" class="background-wrapper">
            <InlineSvg
                v-for="(layer, i) in selectedBackgroundStyle.layers"
                class="background-svg"
                :src="layer"
                :style="[{
                    color: i === 0
                    ? suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][0]
                        : section.backgroundColor
                    : i === 1 ? suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][1]
                        : section.gradientColor1
                    : suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][1]
                        : section.gradientColor2
                }]"
            />
        </div>
    </div>
</template>

<script>
import {EditedImageTypeEnum, SectionTypesEnum, SuggestedColorsEnum} from "../../common/enums.js";
import InlineSvg from "vue-inline-svg";
import bg_solid from "../../../images/background_generator/components/bg_solid.svg";
import bg_gradient_3 from "../../../images/background_generator/components/bg_gradient_3.svg";
import bg_gradient_4 from "../../../images/background_generator/components/bg_gradient_4.svg";
import bg_gradient_5 from "../../../images/background_generator/components/bg_gradient_5.svg";
import bg_gradient_6 from "../../../images/background_generator/components/bg_gradient_6.svg";
import bg_gradient_7 from "../../../images/background_generator/components/bg_gradient_7.svg";
import bg_gradient_8_a from "../../../images/background_generator/components/bg_gradient_8_a.svg";
import bg_gradient_8_b from "../../../images/background_generator/components/bg_gradient_8_b.svg";
import bg_gradient_9_a from "../../../images/background_generator/components/bg_gradient_9_a.svg";
import bg_gradient_9_b from "../../../images/background_generator/components/bg_gradient_9_b.svg";
import {generateSuggestedColors} from "../../common/helpers.js";

export default {
    name: 'section-background-image',
    components: {InlineSvg},
    props: {
        section: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            EditedImageTypeEnum: EditedImageTypeEnum,
            bgStyles: [
                {
                    id: '0',
                    thumbnail: 'bg_style_1',
                    layers: [bg_solid],
                    type: 'none'
                },
                {
                    id: '1',
                    thumbnail: 'bg_style_2',
                    layers: [bg_solid],
                    type: 'solid'
                },
                {
                    id: '2',
                    thumbnail: 'bg_style_3',
                    layers: [bg_solid, bg_gradient_3],
                    type: 'gradient'
                },
                {
                    id: '3',
                    thumbnail: 'bg_style_4',
                    layers: [bg_solid, bg_gradient_4],
                    type: 'gradient'
                },
                {
                    id: '4',
                    thumbnail: 'bg_style_5',
                    layers: [bg_solid, bg_gradient_5],
                    type: 'gradient'
                },
                {
                    id: '5',
                    thumbnail: 'bg_style_6',
                    layers: [bg_solid, bg_gradient_6],
                    type: 'gradient'
                },
                {
                    id: '6',
                    thumbnail: 'bg_style_7',
                    layers: [bg_solid, bg_gradient_7],
                    type: 'gradient'
                },
                {
                    id: '7',
                    thumbnail: 'bg_style_8',
                    layers: [bg_solid, bg_gradient_8_a, bg_gradient_8_b],
                    type: 'gradient'
                },
                {
                    id: '8',
                    thumbnail: 'bg_style_9',
                    layers: [bg_solid, bg_gradient_9_a, bg_gradient_9_b],
                    type: 'gradient'
                },
            ],
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.section.selectedBackgroundStyleId > 1) {
                this.modifySvgElements();
            }
        });
    },
    watch: {
        'section.selectedBackgroundStyleId': function() {
            this.modifySvgElements();
        }
    },
    computed: {
        selectedBackgroundStyle() {
            return this.bgStyles.find(e => e.id === this.section.selectedBackgroundStyleId);
        },
        showGradient() {
            return !!(
                this.section.type === SectionTypesEnum.MAIN_HEADER &&
                this.$store.state.project.settings.displayOptions &&
                this.$store.state.project.settings.displayOptions.gradient);
        },
        setBackgroundImage() {
            let url = null;
            //TODO: legacy magic
            //if (!this.section.backgroundColor || this.section.image && this.section.backgroundColor && this.section.backgroundColor.length === 8) {
            if (this.section.image && !this.section.usingCustomBackground) {
                url = this.section.image.croppedImageUrl
                    ? this.section.image.croppedImageUrl
                    : (this.section.image.url
                        ? this.section.image.url
                        : !this.section.image.url && this.section.type === SectionTypesEnum.MEDIA
                            ? '/dist/images/empty-case-study-cover.jpg'
                            : null);
            }
            return url ? {backgroundImage: `url(${url})`} : null
        },
        setCustomClass() {
            return [this.showOverlay ? 'overlay' : null, this.showBlur ? 'blur' : null].toString().replace(',', ' ');
        },
        showOverlay() {
            return !!this.section.overlay && this.section.overlay.a > 0 && !this.section.usingCustomBackground;
        },
        showBlur() {
            return !!this.section.blur && !this.section.usingCustomBackground;
        },
        setOverlay() {
            return {'--colorOverlay': this.setDim, '--blur': this.setBlur}
        },
        setDim() {
            if (!!this.section.overlay) {
                let rgba = this.section.overlay;
                return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
            }
        },
        setBlur() {
            if (!!this.section.blur) {
                return `${this.section.blur}px`
            }
        },
        sectionHeight() {
            return this.section.type === SectionTypesEnum.MAIN_HEADER
                ? !!this.section.style && !!this.section.style.sectionHeight
                    ? `${this.section.style.sectionHeight}px`
                    : '680px'
                : null
        },
        suggestedColorsUsed() {
            return this.section.backgroundColor && !this.section.backgroundColor.startsWith('#') && Object.values(SuggestedColorsEnum).includes(this.section.backgroundColor)
        },
        suggestedColorOptions() {
            return generateSuggestedColors(this.colorStyles, this.$store.getters.colorPalette)
        },
        colorStyles() {
            return this.$store.getters.colorStyles;
        },
    },
    methods: {
        // This beauty is used to generate unique ids for the svg-s, that are using 'linearGradient'
        // to fix the issue of inheriting 'color' based on 'id' in the DOM causing every svg
        // to have the same color that was set on the first svg file with that 'id'
        // Slower render on production/staging, that's why it has to be looped with a setTimeout
        modifySvgElements() {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    const checkSvgPresence = () => {
                        const svgElements = this.$el.querySelectorAll('svg');

                        if (svgElements.length > 0) {
                            // SVGs found, perform modifications
                            svgElements.forEach((svg, index) => {
                                const uniqueId = `gradient-${this.section._id}-${index}`;

                                const gradients = svg.querySelectorAll('linearGradient');
                                gradients.forEach((gradient) => {
                                    const oldId = gradient.id;
                                    const newId = `${uniqueId}-${oldId}`;
                                    gradient.id = newId;

                                    const references = svg.querySelectorAll(`[fill="url(#${oldId})"], [stroke="url(#${oldId})"]`);
                                    references.forEach((ref) => {
                                        ref.setAttribute('fill', `url(#${newId})`);
                                        ref.setAttribute('stroke', `url(#${newId})`);
                                    });
                                });
                            });
                        } else {
                            // SVGs not yet rendered, retry after a delay
                            setTimeout(checkSvgPresence, 200); // Adjust delay as needed
                        }
                    };
                    checkSvgPresence();
                });

            });
        },
    }

}
</script>

<style lang="scss" scoped>

.ufo-sec .ufo-sec--bg {
    overflow: hidden;
}

.background-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .background-svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.ufo-fab--container {
    top: 0;
    transform: translate(0, 100%);
    right: 72px;
    padding: 0 10px;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    z-index: 4;
}

.ufo-sec--bg:hover {
    .ufo-fab--container {
        transform: translate(0, 100%);
        visibility: visible;
        opacity: 1;
    }
}

.ufo-sec--header-main .ufo-sec--bg {
    .ufo-fab--container {
        transform: none;
        bottom: 0;
        top: unset;
        padding-top: 10px;
        pointer-events: none;
    }
}
</style>