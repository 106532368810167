<template>
    <div v-if="columnMedia.mockupSettings" :class="columnMedia.media.mockupSettings.deviceType">
        <MockupContainer :media="columnMedia" />
    </div>
    <PlaceColumnMediaContent v-else :column-media="columnMedia" :placeholder-image="placeholderImage" />
</template>

<script>
    import PlaceColumnMediaContent from "./PlaceColumnMediaContent.vue";
    import MockupContainer from "@/scripts/common/components/MockupContainer.vue";

    export default {
        name: "GetColumnMediaContent",
        props: ['columnMedia',  'placeholderImage'],
        components: {
            MockupContainer,
            PlaceColumnMediaContent
        }
    }
</script>