// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
img {
&.aspect-ratio-1_1[data-v-6ccf010a] {
        aspect-ratio: 1;
        object-fit: cover;
}
&.aspect-ratio-3_4[data-v-6ccf010a] {
        aspect-ratio: 3/4;
        object-fit: cover;
}
&.aspect-ratio-4_3[data-v-6ccf010a] {
        aspect-ratio: 4/3;
        object-fit: cover;
}
&.aspect-ratio-9_16[data-v-6ccf010a] {
        aspect-ratio: 9/16;
        object-fit: cover;
}
&.aspect-ratio-16_9[data-v-6ccf010a] {
        aspect-ratio: 16/9;
        object-fit: cover;
}
}
.zoom-overlay[data-v-6ccf010a] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: zoom-out;
}
.zoom-background[data-v-6ccf010a] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 300ms ease;
}
.zoom-background.active[data-v-6ccf010a] {
    opacity: 1;
}
.zoomed-image[data-v-6ccf010a] {
    position: absolute;
    transition: top 300ms ease, left 300ms ease, width 300ms ease, height 300ms ease;
    will-change: top, left, width, height;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.5));
}
`, "",{"version":3,"sources":["webpack://./src/scripts/common/ImageZoom.vue"],"names":[],"mappings":";AAmJA;AACI;QACI,eAAe;QACf,iBAAiB;AACrB;AACA;QACI,iBAAiB;QACjB,iBAAiB;AACrB;AACA;QACI,iBAAiB;QACjB,iBAAiB;AACrB;AACA;QACI,kBAAkB;QAClB,iBAAiB;AACrB;AACA;QACI,kBAAkB;QAClB,iBAAiB;AACrB;AACJ;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,2BAA2B;IAC3B,UAAU;IACV,8BAA8B;AAClC;AAEA;IACI,UAAU;AACd;AAEA;IACI,kBAAkB;IAClB,gFAAgF;IAChF,qCAAqC;IACrC,kDAAkD;AACtD","sourcesContent":["\nimg {\n    &.aspect-ratio-1_1 {\n        aspect-ratio: 1;\n        object-fit: cover;\n    }\n    &.aspect-ratio-3_4 {\n        aspect-ratio: 3/4;\n        object-fit: cover;\n    }\n    &.aspect-ratio-4_3 {\n        aspect-ratio: 4/3;\n        object-fit: cover;\n    }\n    &.aspect-ratio-9_16 {\n        aspect-ratio: 9/16;\n        object-fit: cover;\n    }\n    &.aspect-ratio-16_9 {\n        aspect-ratio: 16/9;\n        object-fit: cover;\n    }\n}\n\n.zoom-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1000;\n    cursor: zoom-out;\n}\n\n.zoom-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.6);\n    backdrop-filter: blur(10px);\n    opacity: 0;\n    transition: opacity 300ms ease;\n}\n\n.zoom-background.active {\n    opacity: 1;\n}\n\n.zoomed-image {\n    position: absolute;\n    transition: top 300ms ease, left 300ms ease, width 300ms ease, height 300ms ease;\n    will-change: top, left, width, height;\n    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.5));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
