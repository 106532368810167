<template>
    <div :id="section._id" v-if="$reviewStore.displayUI" :class="['review-fabs', section.type, {'sticky-header': isThereStickyHeader}]" :style="{zIndex:invertedIndex}">
        <div class="sticky">
            <div ref="reaction" v-if="hovering || hasReaction || (reaction || comments)" class="reaction-fab-container">
                <fab :selected="reaction" :icon="sectionReaction.reaction === null ? 'reaction' : null" @click="showReaction" tooltip="Reaction" :tooltip-position="'top'" >
                    <template v-if="sectionReaction.reaction" #reaction>
                        <div class="reaction-in-fab">
                            <div class="reaction-wrapper">
                                <img :src="getReactionImage(sectionReaction.reaction)" alt="reaction"/>
                            </div>
                        </div>
                    </template>
                </fab>
                <div class="reactions-container" v-if="reaction">
                    <div @click="() => reviewMode ? setEmoji(reaction.value) : null" class="reaction" v-for="reaction in reactions">
                        <div class="reaction-wrapper" :class="[{selected:reaction.value === sectionReaction.reaction}]">
                            <div class="fab-wrapper">
                                <img :src="reaction.img" alt="reaction"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="comments" v-if="hovering || hasReaction || (comments || reaction)" class="comments-fab-container">
                <fab :selected="comments" :icon="!sectionReaction.comments.length ? 'comment' : null" @click="showComments" tooltip="Comments" :tooltip-position="'top'">
                    <template v-if="sectionReaction.comments.length" #comment>
                        <div class="comment-in-fab">
                            <Icon :icon-name="'chat'" />
                            <span>{{sectionReaction.comments.length}}</span>
                        </div>
                    </template>
                </fab>
                <div class="comments-container" v-if="comments">
                    <div class="header-row">
                        <div>{{ sectionReaction.comments.length ? 'Comments' : 'Add comment' }}</div>
                        <Icon iconName="close" @click="showComments"/>
                    </div>
                    <div ref="commentList" class="comments" v-if="sectionReaction.comments.length && updating === null">
                        <Comment
                            v-bind:key="comment.label"
                            v-for="(comment, index) in sortedComments"
                            :comment="comment"
                            :updateComment="() => updateComment(index)"
                            :deleteComment="() => showDeleteModal(index)"
                            :edit="reviewMode"
                            :index="index"

                        />
                    </div>
                    <CommentInput v-if="reviewMode" :current-comment="currentComment" :add-comment="addComment" :update-comment-label="updateCommentLabel" />
                </div>
            </div>
            <ConfirmationModal :show="showConfirmationModal" :modal-size="'small'" @action="deleteComment()" @close="closeDeleteCommentModal()">
                <template #title>Delete comment</template>
                <template #content>
                    <p>Are you sure you want to delete this comment?</p>
                </template>
            </ConfirmationModal>
        </div>
    </div>
</template>

<script>
import Fab from "../../../../common/components/Fab.vue";
import Icon from "../../../../common/Icon.vue";
import Comment from "../Comment.vue";
import ApiHelper from "../../../../api/ApiHelper.js";
import ConfirmationModal from "../../../../common/ConfirmationModal.vue";
import CommentInput from "../CommentInput.vue";

//Emojis
import thumbsUp from "../../../../../images/review/emojis/thumbs-up.png";
import heart from "../../../../../images/review/emojis/heart.png";
import clap from "../../../../../images/review/emojis/clap.png";
import bulb from "../../../../../images/review/emojis/light-bulb.png";
import checkMark from "../../../../../images/review/emojis/check-mark.png";

export default {
        name: "ReviewFabs",
        props:['section', 'hovering', 'invertedIndex', 'review'],
        components: {
            CommentInput,
            ConfirmationModal,
            Fab,
            Icon,
            Comment
        },
        data() {
            return {
                reaction:false,
                comments:false,
                updating:null,
                indexToDelete:null,
                showConfirmationModal:false,
                show:false,
                listenerMounted:false,
                reactions: [
                    {name:'like', value:'&#128077', img:thumbsUp},
                    {name:'heart', value:'&#128150', img:heart},
                    {name:'clap', value:'&#128079', img:clap},
                    {name:'bulb', value:'&#128161', img:bulb},
                    {name:'check', value:'&#x2705', img:checkMark},
                ],
                currentComment: {text:null, label:null},
                defaultSectionReaction: {
                    sectionId:null,
                    reaction:null,
                    comments:[],
                    pageData:{
                        shortId:null,
                        name:null
                    }
                },
                indexToUpdate:null,
            }
        },
        watch: {
            '$route'(to, from) {
                //TODO: somehow when route changes and everything updates this shit is keeping the data of the previous section
                this.defaultSectionReaction = {
                    sectionId:null,
                        reaction:null,
                        comments:[],
                        pageData:{
                        shortId:null,
                        name:null
                    }
                }
                this.setDefaultValues();
            }
        },
        mounted() {
            this.setDefaultValues();
        },
        methods: {
            setDefaultValues() {
                this.defaultSectionReaction.sectionId = this.section._id;
                this.defaultSectionReaction.pageData = {
                    shortId:this.$store.state.view.project.shortId,
                    name:this.$store.state.view.project.name
                }
            },
            showReaction() {
                this.reaction = !this.reaction;
                this.comments = false;
                this.mountOutsideClick();
            },
            showComments() {
                if(this.comments && this.updating !== null) {
                    this.currentComment = {text:null, label:null}
                }
                this.comments = !this.comments;
                this.updating = null;
                this.reaction = false;
                this.mountOutsideClick();
            },
            mountOutsideClick() {
                if((this.reaction || this.comments) && !this.listenerMounted) {
                    this.listenerMounted = true;
                    document.addEventListener('click', this.clickOutside, true);
                } else {
                    this.listenerMounted = false;
                    document.removeEventListener('click', this.clickOutside, true);
                }
            },
            clickOutside() {
                if(this.reaction) {
                    if (this.reaction && this.reviewer && this.$refs.reaction && !this.$refs.reaction.contains(event.target)) {
                        this.showReaction();
                    }
                }
                if(this.comments) {
                    if (this.comments && this.reviewer && this.$refs.comments && !this.$refs.comments.contains(event.target)) {
                        this.showComments();
                    }
                }
            },
            setEmoji(emoji) {
                if(this.reviewer) {
                    if(emoji === this.sectionReaction.reaction) {
                        this.sectionReaction.reaction = null;
                    }
                    else {
                        this.sectionReaction.reaction = emoji;
                    }
                    this.reaction = false;
                }
                this.saveReaction();
            },
            updateCommentLabel(label) {
                this.currentComment.label = this.currentComment.label === label ? null : label;
            },
            addComment() {
                if(this.currentComment.text) {
                    if(this.updating === null) {
                        this.sectionReaction.comments.push(
                            {
                                ...this.currentComment,
                                createdAt:Date.now(),
                                fromUserId:this.$reviewStore.review.reviewerDetails._id
                            }
                            );
                        this.currentComment = {text:null, label:null};
                    }
                    else {
                        this.sectionReaction.comments[this.updating] = {...this.currentComment, createdAt:Date.now(), fromUserId:this.$reviewStore.review.reviewerDetails._id};
                        this.currentComment = {text:null, label:null};
                    }
                    this.saveReaction();

                    //Sroll down to new comment if not there
                    if(this.$refs.commentList) {
                        this.$nextTick(() => {
                            const container = this.$refs.commentList;
                            container.scrollTop = container.scrollHeight;
                        });
                    }
                }
            },
            async saveReaction() {
                if(this.reviewer) {
                    if(this.hasReaction) {
                        if(!this.isReactionExists) {
                            this.sectionReactions.push({sectionId:this.section._id, ...this.sectionReaction});
                        }
                    }
                    else {
                        //If no reaction and comment, remove data
                        if(this.isReactionExists) {
                            if (this.sectionReactions.hasOwnProperty(this.savedDataIndex)) {
                                this.sectionReactions.splice(this.savedDataIndex, 1);
                            }
                        }
                    }
                    this.updating = null;
                    await ApiHelper.post('/api/review/' + this.$reviewStore.review._id + "/" + this.$reviewStore.review.contentId, this.$reviewStore.review);
                }
                else {
                    this.$reviewStore.showReviewLoginModal = true;
                }
            },
            updateComment(index) {
                this.currentComment = this.sectionReaction.comments[index];
                this.updating = index;
            },
            showDeleteModal(index) {
                this.indexToDelete = index;
                this.showConfirmationModal = true;
            },
            closeDeleteCommentModal() {
                this.showConfirmationModal = false;
                this.indexToDelete = null;
            },
            deleteComment() {
                this.sectionReaction.comments.splice(this.indexToDelete, 1);
                this.indexToDelete = null
                this.saveReaction();
                this.showConfirmationModal = false;
            },
            getReactionImage(reaction) {
                return this.reactions.find(x => x.value === reaction).img;
            }
        },
        computed: {
            sectionReactions() {
                return this.$reviewStore.review.sectionReactions;
            },
            hasReaction() {
                return this.sectionReaction.reaction || this.sectionReaction.comments.length
            },
            reviewMode() {
                return !this.$reviewStore.receiver
            },
            sortedComments() {
                return [...this.sectionReaction.comments].sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            },
            savedDataIndex() {
                return this.sectionReactions.findIndex(x => x.sectionId === this.section._id)
            },
            isReactionExists() {
                return this.sectionReactions.find(x => x.sectionId === this.section._id)
            },
            sectionReaction() {
                let reactionGroup = this.sectionReactions.find(x => x.sectionId === this.section._id);
                return reactionGroup ? reactionGroup : this.defaultSectionReaction
            },
            isThereStickyHeader() {
                return this.$store.state.view.stickedHeader
            },
            reviewer() {
                return this.$reviewStore.review.reviewerDetails;
            }
        }
    }
</script>

<style lang="scss">
    //sidebar templates
    .profile_theme_four {
        .review-fabs {
            &.hero {
                top: 20px !important;
            }
        }
    }

    .profile_theme_nine {
        .review-fabs {
            &.hero {
                top: -300px !important;
                right: -20px !important;
            }
        }
    }

    .profile_theme_default {
        .review-fabs {
            &.hero {
                top: -60px !important;
                right: 0 !important;
            }
        }
    }
</style>