<template>
    <div :id="'section-' + section.index"
         class="ufo-sec ufo-sec--header-main"
    >
        <ToggleGrid />
        <SectionBackgroundImage :section="section" />

        <div class="grid">
            <GetSiteNavigation :section="section" />
        </div>

        <div class="grid ufo--hero-content-grid"
             :style="rowStyle">

            <template v-for="element in elements">
                <div v-if="element.key ==='media' && section.media || element.key === 'text'"
                     :class="['col',
                    `col-${element.key}`,
                    {'has-image': section.media && element.key === 'text'},
                    setHorizontalPosition,
                    setVerticalPosition,
                    mediaSize,
                    mediaFrame
                    ]">

                    <div v-if="element.key === 'text'"
                         :class="['text-container', textAlignment, mediaSize, mediaFrame]">
                        <AnimationWrapper
                            :animation-style="$store.getters.animationSettings?.animationStyle"
                            :animation-speed="$store.getters.animationSettings?.animationSpeed"
                        >
                            <template #animationContent>
                                <div>
                                    <div :class="section.title.style.textStyle ? section.title.style.textStyle :'h1'" v-html="fixText('title')"></div>
                                    <div :class="section.subtitle.style.textStyle ? section.subtitle.style.textStyle :'h2'" v-html="fixText('subtitle')"></div>
                                </div>
                            </template>
                        </AnimationWrapper>
                    </div>

                    <div v-if="element.key ==='media' && section.media"
                         :class="['image-container', mediaSize, mediaFrame]">
                        <AnimationWrapper
                            :animation-style="$store.getters.animationSettings?.animationStyle"
                            :animation-speed="$store.getters.animationSettings?.animationSpeed"
                        >
                            <template #animationContent>
                                <div :class="['media-container', !section.media.displayMode && !section.media.mockupSettings ? 'display-mode--default' : '', `display-mode--${section.media.displayMode || 'default'}`]">
                                    <GetMediaContent :section="section" :default-caption="'Image alt tag'" :placeholder-image="'v2/gallery_sample/gallery-sample-tall-01.jpg'" />
                                </div>
                            </template>
                        </AnimationWrapper>
                    </div>

                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import GetMediaContent from "./_helper/GetMediaContent.vue";
    import GetSiteNavigation from "./_helper/GetSiteNavigation.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";
    import {MockupOrientation} from "@/scripts/common/mockupStore/mockupStructure.js";

    export default {
        name: "MainHeader",
        components: {
            SectionBackgroundImage,
            AnimationWrapper, GetSiteNavigation, GetMediaContent, ToggleGrid },
        props:['section'],
        data() {
            return {
                elements: [
                    { key: 'text'},
                    { key: 'media'}
                ]
            }
        },
        beforeMount() {
            if(this.section.flipped) {
                this.elements.reverse();
            }
        },
        methods: {
            fixText(data) {
                return this.section[data].text.replaceAll('\n', '<br/>')
            }
        },
        computed: {
            textAlignment() {
                return `align-text-${this.section.title.style?.textAlign}`
            },
            rowStyle() {
                return {
                    '--rowSize': this.section.style && this.section.style.sectionHeight ? this.section.style.sectionHeight + 'px' : null
                }
            },
            setHorizontalPosition() {
                if (this.section.title.style?.left === 0) {
                    return 'left'
                } else if (this.section.title.style?.left === 100) {
                    return 'right'
                }
            },
            setVerticalPosition() {
                if (this.section.title.style?.top === 0) {
                    return 'top'
                } else if (this.section.title.style?.top === 100) {
                    return 'bottom'
                } else {
                    return 'center'
                }
            },
            mediaSize() {
                return !!this.section.media && !!this.section.media.displayMode
                    ? `media-${this.section.media.displayMode}`
                    : null;
            },
            mockupData() {
                return this.section.media.mockupSettings
            },
            mediaFrame() {
                return this.section.media && !!this.mockupData
                    ? `frame-${this.mockupData.deviceType.toLowerCase()} ${this.mockupData.orientation === MockupOrientation.LANDSCAPE ? 'landscape' : ''}`
                    : null
            }
        }
    }
</script>