import {
    MockupColorScheme,
    MockupDeviceType,
    Mockup,
    MockupType,
    MockupOrientation,
    MockupSettings, MockupSettingsGroups
} from "@/scripts/common/mockupStore/mockupStructure.js";

export const desktopMockups = {
    [MockupDeviceType.DESKTOP]: {
        [MockupType.REALISTIC]: {
            //No realistic for monitor
            'v3': new Mockup(
                'desktop-realistic-v3',
                MockupDeviceType.DESKTOP,
                MockupType.REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v3',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.SEMI_REALISTIC]: {
            'v2': new Mockup(
                'desktop-semi-realistic-v2',
                MockupDeviceType.DESKTOP,
                MockupType.SEMI_REALISTIC,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.SIMPLE]: {
            'v2': new Mockup(
                'desktop-simple-v2',
                MockupDeviceType.DESKTOP,
                MockupType.SIMPLE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.OUTLINE]: {
            'v2': new Mockup(
                'desktop-outline-v2',
                MockupDeviceType.DESKTOP,
                MockupType.OUTLINE,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
                [],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.BROWSER]: {
            'v2': new Mockup(
                'desktop-browser-v2',
                MockupDeviceType.DESKTOP,
                MockupType.BROWSER,
                [MockupColorScheme.LIGHT, MockupColorScheme.DARK],
                'v2',
               [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
        },
        [MockupType.TRADITIONAL]: {
            //No trad for monitor
        },
        [MockupType.MINIMAL]: {
            'v2': new Mockup(
                'desktop-minimal-v2',
                MockupDeviceType.DESKTOP,
                MockupType.MINIMAL,
                [MockupColorScheme.LIGHT],
                'v2',
                [MockupSettingsGroups.CORNER],
                [MockupOrientation.PORTRAIT]
            ),
        }
    },
}