<template>
    <div class="ufo--message-highlight-container" :class="[type, {dark: darkScheme}]">
        <div class="border-left"/>
        <div class="ufo--message-highlight" :class="[type, {dark: darkScheme}]">
            <div v-if="!hideIcon" class="picto">
                <Icon :icon-name="icon ? icon : type" />
            </div>
            <div class="message-content">
                <div v-if="title" class="title" v-html="title"></div>
                <div class="message">
                    <span v-html="message" />{{' '}}
                    <Cta v-if="url" type="link" :dark-scheme="darkScheme" @click="clickLink()" >
                        {{ linkText ? linkText : 'Learn more' }}
                    </Cta>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../Icon.vue";
import Cta from "./CtaButton.vue";

export default {
    name: 'message-highlight',
    components: {Cta, Icon},
    props: {
        type: {
            type: String,
            default: 'info' //info, warning, success
        },
        title: {
            type: String,
            default: null
        },
        message: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        linkText: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        darkScheme: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        clickLink() {
            return window.open(this.url);
        }
    }
}
</script>

<style lang="scss" scoped>
.ufo--message-highlight-container {

    --highlight-border: var(--asphalt50);
    --highlight-bg: var(--asphalt20);
    --highlight-title: var(--asphalt100);
    --highlight-message: var(--asphalt60);

    &.warning {
        --highlight-border: var(--error50);
    }
    &.success {
        --highlight-border: var(--success60);
    }

    &.dark {
        --highlight-border: var(--asphalt40);
        --highlight-bg: var(--asphalt90);
        --highlight-title: var(--asphalt10);
        --highlight-message: var(--asphalt40);
        &.warning {
            --highlight-border: var(--error40);
        }
        &.success {
            --highlight-border: var(--success50);
        }
    }

    border-radius: 4px;
    width: 100%;
    display: flex;
    background-color: var(--highlight-bg);
    overflow: hidden;

    .border-left {
        width: 4px;
        background-color: var(--highlight-border);
    }

    .ufo--message-highlight {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 16px;
        gap: 12px;

        .picto {
            flex-shrink: 0;
            color: var(--highlight-border);
            width: 24px;
            height: 24px;
        }

        .message-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
        }

        .title {
            font: var(--editor-ui-b1-medium);
            letter-spacing: 0.16px;
            color: var(--highlight-title);
        }
        .message {
            font: var(--editor-ui-b2-regular);
            letter-spacing: 0.14px;
            color: var(--highlight-message);
        }

    }
}

</style>