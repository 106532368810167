<template>
    <div :class="['ufo--page-hero-background', setCustomClass]"
         :style="setBackground"
    >

        <div v-if="!!section.usingCustomBackground && section.selectedBackgroundStyleId > 0" class="background-wrapper">
            <InlineSvg
                v-for="(layer, i) in selectedBackgroundStyle.layers"
                :key="layer"
                class="background-svg"
                :src="layer"
                :style="[{
                    color: i === 0
                    ? suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][0]
                        : section.backgroundColor
                    : i === 1 ? suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][1]
                        : section.gradientColor1
                    : suggestedColorsUsed
                        ? suggestedColorOptions[selectedBackgroundStyle.type][section.backgroundColor][1]
                        : section.gradientColor2
                }]"
            />
        </div>
    </div>
</template>

<script>
import {EditedImageTypeEnum, PageTypesEnum, SuggestedColorsEnum} from "../../../../common/enums.js";
    import InlineSvg from "vue-inline-svg";
    import bg_solid from "../../../../../images/background_generator/components/bg_solid.svg";
    import bg_gradient_3 from "../../../../../images/background_generator/components/bg_gradient_3.svg";
    import bg_gradient_4 from "../../../../../images/background_generator/components/bg_gradient_4.svg";
    import bg_gradient_5 from "../../../../../images/background_generator/components/bg_gradient_5.svg";
    import bg_gradient_6 from "../../../../../images/background_generator/components/bg_gradient_6.svg";
    import bg_gradient_7 from "../../../../../images/background_generator/components/bg_gradient_7.svg";
    import bg_gradient_8_a from "../../../../../images/background_generator/components/bg_gradient_8_a.svg";
    import bg_gradient_8_b from "../../../../../images/background_generator/components/bg_gradient_8_b.svg";
    import bg_gradient_9_a from "../../../../../images/background_generator/components/bg_gradient_9_a.svg";
    import bg_gradient_9_b from "../../../../../images/background_generator/components/bg_gradient_9_b.svg";
    import {generateSuggestedColors} from "../../../../common/helpers.js";

    export default {
        name: 'template-hero-background',
        components: {InlineSvg},
        props: {
            section: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                EditedImageTypeEnum: EditedImageTypeEnum,
                bgStyles: [
                    {
                        id: '0',
                        thumbnail: 'bg_style_1',
                        layers: [bg_solid],
                        type: 'none'
                    },
                    {
                        id: '1',
                        thumbnail: 'bg_style_2',
                        layers: [bg_solid],
                        type: 'solid'
                    },
                    {
                        id: '2',
                        thumbnail: 'bg_style_3',
                        layers: [bg_solid, bg_gradient_3],
                        type: 'gradient'
                    },
                    {
                        id: '3',
                        thumbnail: 'bg_style_4',
                        layers: [bg_solid, bg_gradient_4],
                        type: 'gradient'
                    },
                    {
                        id: '4',
                        thumbnail: 'bg_style_5',
                        layers: [bg_solid, bg_gradient_5],
                        type: 'gradient'
                    },
                    {
                        id: '5',
                        thumbnail: 'bg_style_6',
                        layers: [bg_solid, bg_gradient_6],
                        type: 'gradient'
                    },
                    {
                        id: '6',
                        thumbnail: 'bg_style_7',
                        layers: [bg_solid, bg_gradient_7],
                        type: 'gradient'
                    },
                    {
                        id: '7',
                        thumbnail: 'bg_style_8',
                        layers: [bg_solid, bg_gradient_8_a, bg_gradient_8_b],
                        type: 'gradient'
                    },
                    {
                        id: '8',
                        thumbnail: 'bg_style_9',
                        layers: [bg_solid, bg_gradient_9_a, bg_gradient_9_b],
                        type: 'gradient'
                    },
                ],
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.modifySvgElements();
                }, 100); // Delay to allow time for SVGs to render
            });
        },
        computed: {
            selectedBackgroundStyle() {
                return this.bgStyles.find(e => e.id === this.section.selectedBackgroundStyleId);
            },
            setCustomClass() {
                return [this.showOverlay ? 'overlay' : null, this.showBlur ? 'blur' : null].toString().replace(',', ' ');
            },
            showOverlay() {
                return !!this.$store.state.portfolio.templateSettings.hero.overlay && this.$store.state.portfolio.templateSettings.hero.overlay.a > 0 && !this.section.usingCustomBackground;
            },
            showBlur() {
                return !!this.$store.state.portfolio.templateSettings.hero.blur && !this.section.usingCustomBackground;
            },
            setDim() {
                if(!!this.$store.state.portfolio.templateSettings.hero.overlay) {
                    let rgba = this.$store.state.portfolio.templateSettings.hero.overlay;
                    return  `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
                }
            },
            setBlur() {
                if(!!this.$store.state.portfolio.templateSettings.hero.blur && !this.section.usingCustomBackground) {
                    return `${this.$store.state.portfolio.templateSettings.hero.blur}px`
                }
            },
            backgroundImageUrl() {
                if(!!this.$store.state.portfolio.templateSettings.hero.image && !this.section.usingCustomBackground) {
                    return !!this.$store.state.portfolio.templateSettings.hero.image.croppedImageUrl
                        ? this.$store.state.portfolio.templateSettings.hero.image.croppedImageUrl
                        : this.$store.state.portfolio.templateSettings.hero.image.url
                }
            },
            backgroundColor() {
                return !!this.$store.state.portfolio.templateSettings.hero.backgroundColor
                    ? this.$store.state.portfolio.templateSettings.hero.backgroundColor.startsWith("#")
                        ? this.$store.state.portfolio.templateSettings.hero.backgroundColor
                        : `#${this.$store.state.portfolio.templateSettings.hero.backgroundColor}`
                    : null
            },
            setBackground() {
                return {
                    'background-image': !!this.backgroundImageUrl ? `url(${this.backgroundImageUrl})` : null,
                    'background-color': this.backgroundColor,
                    '--colorOverlay': this.setDim,
                    '--blur': this.setBlur
                }
            },
            suggestedColorsUsed() {
                return this.section.backgroundColor && !this.section.backgroundColor.startsWith('#') && Object.values(SuggestedColorsEnum).includes(this.section.backgroundColor)
            },
            suggestedColorOptions() {
                return generateSuggestedColors(this.colorStyles, this.$store.getters.colorPalette)
            },
            colorStyles() {
                return this.$store.getters.colorStyles;
            },
        },
        methods: {
            // This beauty is used to generate unique ids for the svg-s, that are using 'linearGradient'
            // to fix the issue of inheriting 'color' based on 'id' in the DOM causing every svg
            // to have the same color that was set on the first svg file with that 'id'
            modifySvgElements() {
                this.$nextTick(() => {
                    const svgElements = this.$el.querySelectorAll('svg');

                    svgElements.forEach((svg, index) => {
                        const uniqueId = `gradient-${this.section._id}-${index}`;

                        const gradients = svg.querySelectorAll('linearGradient');
                        gradients.forEach((gradient) => {
                            const oldId = gradient.id;
                            const newId = `${uniqueId}-${oldId}`;
                            gradient.id = newId;

                            const references = svg.querySelectorAll(`[fill="url(#${oldId})"], [stroke="url(#${oldId})"]`);
                            references.forEach((ref) => {
                                ref.setAttribute('fill', `url(#${newId})`);
                                ref.setAttribute('stroke', `url(#${newId})`);
                            });
                        });
                    });
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .ufo--page-hero-background {
        overflow: hidden;
    }

    .background-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .background-svg {
            //min-width: 100%;
            //min-height: 100%;
            //width: auto;
            //height: auto;
            //position: absolute;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .theme_six,
    .profile_theme_six {
        .ufo--page-hero-background {
            .ufo-fab--container {
                top: -28px;
            }
        }
    }

    .theme_five,
    .profile_theme_five {
        .ufo--page-hero-background {
            .ufo-fab--container {
                top: 74px;
            }
        }
    }

</style>