import {mobileMockups} from "@/scripts/common/mockupStore/mobile.js";
import {tabletMockups} from "@/scripts/common/mockupStore/tablet.js";
import {laptopMockups} from "@/scripts/common/mockupStore/laptop.js";
import {desktopMockups} from "@/scripts/common/mockupStore/desktop.js";

import {
    MockupColorScheme,
    MockupDeviceType,
    MockupOrientation, MockupSettingGroupNames,
    MockupSettings,
    MockupSettingsGroups,
    MockupType
} from "@/scripts/common/mockupStore/mockupStructure.js";

const mockupStore = {
    ...mobileMockups,
    ...tabletMockups,
    ...desktopMockups,
    ...laptopMockups
};

export function findMockup(deviceType, mockupType, version, colorScheme, settingsOption, orientation) {
    //console.log('findMockup', deviceType, mockupType, version, colorScheme, settingsOption, orientation)
    //TODO: this is for now, as we only have 1 setting option. but will be refactored if we have more options
    let setting = settingsOption.length ? isDefaultSettingValue(settingsOption[0].name, settingsOption[0].value) ? MockupSettings.DEFAULT : settingsOption[0].value : MockupSettings.DEFAULT;
    let mockup = mockupStore[deviceType][mockupType][version];
    return mockup.urls[setting][orientation][colorScheme]
}

export function findLatestMockupByDeviceAndType(deviceType, mockupType) {
    let latestVersion = getLatestVersion(deviceType, mockupType);
    return mockupStore[deviceType][mockupType][latestVersion];
}

export function availableMockupSettings(deviceType, mockupType) {
    let latestVersion = getLatestVersion(deviceType, mockupType);
    return mockupStore[deviceType][mockupType][latestVersion].settings.map(x => x.name);
}

export function mockupHaveSetting(deviceType, mockupType, setting, version) {
    return mockupStore[deviceType][mockupType][version].settings.map(x => x.name).includes(setting);
}

export function isDefaultSettingValue(settingName, settingValue) {
    return MockupSettingsGroups[settingName].default === settingValue;
}

export function getDefaultMockupSettings(deviceType, mockupType) {
    let latestVersion = getLatestVersion(deviceType, mockupType);
    if(mockupStore[deviceType][mockupType][latestVersion].settings.length) {
        return [{name:mockupStore[deviceType][mockupType][latestVersion].settings[0].name, value:mockupStore[deviceType][mockupType][latestVersion].settings[0].default}]
    }
    return []
}

export function mockupOrientationAvailable(deviceType, mockupType) {
    let latestVersion = getLatestVersion(deviceType, mockupType);
    return mockupStore[deviceType][mockupType][latestVersion].availableOrientations.length;
}

export function mockupColorSchemeAvalible(deviceType, mockupType, colorScheme) {
    let latestVersion = getLatestVersion(deviceType, mockupType);
    return mockupStore[deviceType][mockupType][latestVersion].colorSchemes.includes(colorScheme);

}

export const defaultMockup = {
    deviceType:MockupDeviceType.MOBILE,
    mockupType:MockupType.REALISTIC,
    colorScheme:MockupColorScheme.DARK,
    orientation:MockupOrientation.PORTRAIT,
    version:getLatestVersion(MockupDeviceType.MOBILE, MockupType.SEMI_REALISTIC),
    settings:{name: MockupSettingsGroups.NOTCH.name ,value:MockupSettings.ISLAND},
    shadow:false
};

export function getLatestVersion(deviceType, mockupType) {
    const mockupTypes = mockupStore[deviceType][mockupType];
    if (!mockupTypes) {
        console.error("Device type not found: ", deviceType, mockupType);
        return null;
    }

    // Extract version numbers and sort them to find the latest
    const latest = Object.keys(mockupTypes)
        .map(version => parseInt(version.substring(1))) // Extract the numeric part of 'v1', 'v2', etc.
        .sort((a, b) => b - a)[0]; // Sort descending and get the highest

    // Return the latest version in the original format
    return `v${latest}`;
}


export const excludedSettings = [MockupSettingGroupNames.LAPTOP_NOTCH];

let generalExcludedMockupTypes = [MockupType.MINIMAL, MockupType.TRADITIONAL];
let THDesignerExcludedMockupTypes = [MockupType.TRADITIONAL];

const createOptions = (values, transformer) =>
    values.map(transformer);

const createFilteredOptions = (values, excluded, transformer) =>
    values.filter(value => !excluded.includes(value)).map(transformer);

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const generalMockupSettings = {
    deviceType: {
        name: 'Device type',
        options: [
            { id: null, name: 'none' },
            ...Object.values(MockupDeviceType).map(type => ({
                id: type.toLowerCase(),
                name: type,
            })),
        ],
    },
    mockupType: {
        name: 'Mockup style',
        options: createFilteredOptions(
            Object.values(MockupType),
            generalExcludedMockupTypes,
            type => ({ id: type.toLowerCase().replace(/\s+/g, ''), name: type })
        ),
    },
    settings: {
        name: 'Settings',
        groups: Object.entries(MockupSettingsGroups)
            .filter(([key]) =>
                !excludedSettings.some(excluded => excluded.toLowerCase() === key.toLowerCase())
            )
            .map(([group, settings]) => ({
                id: group,
                name: capitalize(group),
                displayName:settings.displayName,
                options: createOptions(settings.options, setting => ({
                    id: setting,
                    name: setting,
                })),
            })),
    },
    colorScheme: {
        name: 'Color scheme',
        options: createOptions(Object.values(MockupColorScheme), color => ({
            id: color.toLowerCase(),
            name: color,
        })),
    },
    shadow: {
        name: 'Device shadow',
        options: [
            { id: 'shadow', name: true },
            { id: 'no-shadow', name: false },
        ],
    },
};

export const extendedGeneralMockupSettings = {
    ...generalMockupSettings,
    mockupType: {
        name: 'Mockup style',
        options: createFilteredOptions(
            Object.values(MockupType),
            generalExcludedMockupTypes,
            type => ({ id: type.toLowerCase().replace(/\s+/g, ''), name: type })
        ),
    },
};

export const thDesignerMockupSettings = {
    ...generalMockupSettings,
    mockupType: {
        name: 'Mockup style',
        options: createFilteredOptions(
            Object.values(MockupType),
            THDesignerExcludedMockupTypes,
            type => ({ id: type.toLowerCase().replace(/\s+/g, ''), name: type })
        ),
    },
};
