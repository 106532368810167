import mixpanel from "mixpanel-browser";
import {store} from "@/scripts/store/FolioStore.js";

// Initialize Mixpanel
mixpanel.init(
    // TODO
    process.env.NODE_ENV === "production" ? "0a5ac67fba63f3174470fc8b9f2c153a" : "63f1338bf16b4e87d11daabdf7e2ef63",
    { debug: process.env.NODE_ENV !== "production" }
);

const MixpanelService = {

    MixpanelEventGroups: Object.freeze({
        USER_LIFECYCLE: "USER",     // Auth, subscription, account, Domain
        PORTFOLIO: "PORTFOLIO",     // Portfolio management
        CASE_STUDY: "CASE_STUDY",   // Case studies
        EDIT: "EDIT",               // Content editing, images, media, Templates, colors, fonts, animations
        DOMAIN: "DOMAIN",           // Domain
        AI_FEATURES:"AI_FEATURES"
    }),

    identify(userId) {
        mixpanel.identify(userId);
    },

    track(event, properties = {}) {
        // event: "EVENT_NAME",
        // properties: {
        //      "event_group": "MixpanelEventGroups[key]", // if there is any, can skip this
        //      "prop_name_1": "Prop value"
        //      "prop_name_2": "Prop value"
        //      ...
        //  }
        // }
        return new Promise((resolve, reject) => {
            try {
                mixpanel.track(event, properties, function () {
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });

    },

    setUser(user) {
        mixpanel.people.set({
            "$name": user.name,
            "$email": user.email,
        });
    },

    getUrls() {
        const portfolio = store.state.portfolio;
        const project = store.state.project;

        if (!portfolio || !project) {
            return {};
        }

        return {
            portfolio_url: `https://uxfol.io/${portfolio.shortId}`,
            page_url: store.getters.isHomePage
                ? `https://uxfol.io/${portfolio.shortId}`
                : `https://uxfol.io/p/${portfolio.shortId}/${project.shortId}`,
        };
    }

};

export default MixpanelService;