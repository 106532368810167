export const MockupDeviceType = Object.freeze({
    MOBILE:"Mobile",
    LAPTOP:"Laptop",
    DESKTOP:"Desktop",
    TABLET:"Tablet"
});

export const MockupType = Object.freeze({
    REALISTIC:"Realistic",
    SEMI_REALISTIC:"Semirealistic",
    SIMPLE:"Simple",
    OUTLINE:"Outline",
    BROWSER:"Browser",
    TRADITIONAL:"Traditional",
    MINIMAL:"Minimal"
});

export const MockupColorScheme = Object.freeze({
    LIGHT:"Light",
    DARK:"Dark"
});

export const MockupOrientation = Object.freeze({
    PORTRAIT:"Portrait",
    LANDSCAPE:"Landscape",
});

export const MockupSettings = Object.freeze({
    DEFAULT:"Default", //No settings available
    ISLAND:"Island",
    NOTCH:"Notch",
    SIMPLE:"Simple",
    ROUNDED:"Rounded",
    SQUARED:"Squared",
});

export const MockupSettingGroupNames = Object.freeze({
    CORNER:'CORNER',
    NOTCH:'NOTCH',
    LAPTOP_NOTCH:'LAPTOP_NOTCH'
})

export const MockupSettingsGroups = Object.freeze({
    CORNER: {
        name: MockupSettingGroupNames.CORNER,
        displayName: 'Device corner',
        options: [MockupSettings.ROUNDED, MockupSettings.SQUARED],
        default: MockupSettings.ROUNDED
    },
    NOTCH: {
        name:MockupSettingGroupNames.NOTCH,
        displayName:'Bezel type',
        options: [MockupSettings.ISLAND, MockupSettings.NOTCH, MockupSettings.SIMPLE],
        default: MockupSettings.SIMPLE
    },
    LAPTOP_NOTCH: {
        name:MockupSettingGroupNames.LAPTOP_NOTCH,
        displayName:'Bezel type',
        options: [MockupSettings.SIMPLE, MockupSettings.NOTCH],
        default: MockupSettings.SIMPLE
    }
});

export class Mockup {
    constructor(id, deviceType, mockupType, colorSchemes, version, settingsGroup, availableOrientations) {
        this.id = id;
        this.deviceType = deviceType;
        this.mockupType = mockupType;
        this.colorSchemes = colorSchemes;
        this.version = version;
        this.settings = settingsGroup; //If a mockup don't have any settings, only have default mockup
        this.availableOrientations = availableOrientations.length ? availableOrientations : [MockupOrientation.PORTRAIT];

        // Initialize URL storage for each orientation
        this.urls = {};
        if (this.settings.length) {
            this.settings.forEach(settingGroup => {
                // Iterate through each option in the settings group
                settingGroup.options.forEach(option => {
                    // Setup URLs for each option, marking the default option as true
                    let isDefault = settingGroup.default === option;
                    this.setupUrls(isDefault ? MockupSettings.DEFAULT : option, isDefault);
                });
            });
        } else {
            // Handle cases where there are no settings groups by creating a default path
            this.setupUrls(MockupSettings.DEFAULT, true);
        }
    }

    setupUrls(setting, isDefault) {
        this.urls[setting] = {};
        this.availableOrientations.forEach(orientation => {
            this.urls[setting][orientation] = {};
            this.colorSchemes.forEach(colorScheme => {
                // Generate path for each color scheme
                this.urls[setting][orientation][colorScheme] = this.generateSvgPath(colorScheme, setting, orientation, isDefault);
            });
        });
    }

    generateSvgPath(colorScheme, setting, orientation, isDefault) {
        const settingSuffix = isDefault ? '' : `--${setting.toLowerCase()}`;
        const orientationSuffix = orientation === MockupOrientation.LANDSCAPE ? '--landscape' : '';
        return `/mockups/${this.deviceType.toLowerCase()}/${this.mockupType.toLowerCase()}/${this.version}/${colorScheme.toLowerCase()}${settingSuffix}${orientationSuffix}.svg`;
    }
}