import {FontScaleLarge, FontScaleMedium, FontScaleSmall} from "./fontScales.js";
import {
    AbyssColorPalette,
    AsphaltColorPalette,
    BorealisColorPalette,
    CaramelColorPalette,
    ChestnutColorPalette,
    CoalColorPalette,
    CrimsonColorPalette,
    GraniteColorPalette,
    GraphiteColorPalette,
    IndigoColorPalette,
    ObsidianColorPalette
} from "./colorPalettes.js";
import {
    AlberSansOnly,
    BricolageGroteskAndKarla,
    ClashDisplayAndInter,
    DMSansOnly,
    GambettaAndInter,
    GeneralSansAndGambetta,
    IbmPlexMonoAndIbmPlexSans,
    InterOnly,
    MerchantAndOpenSans,
    OutfitAndPoppins,
    RoundoAndKarla,
    SpaceGroteskAndWorkSans,
    StixTwoTextAndSwitzer
} from "./fontPresets.js";

function getColors(colorPalette = AsphaltColorPalette, uniqueColors = {}) {
    return {
        heroTitle: {
            paletteColorIndex: !uniqueColors.heroTitle ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.heroTitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.heroTitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        heroSubtitle: {
            paletteColorIndex: !uniqueColors.heroSubtitle ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.heroSubtitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.heroSubtitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        heroParagraph: {
            paletteColorIndex: !uniqueColors.heroParagraph ? colorPalette.COLOR_MAP.PARAGRAPH : null,
            color: uniqueColors.heroParagraph || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH],
            primaryColor: uniqueColors.heroParagraph || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH]
        },
        h1: {
            paletteColorIndex: !uniqueColors.h1 ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.h1 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.h1 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        h2: {
            paletteColorIndex: !uniqueColors.h2 ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.h2 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.h2 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        h3: {
            paletteColorIndex: !uniqueColors.h3 ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.h3 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.h3 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        h4: {
            paletteColorIndex: !uniqueColors.h4 ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.h4 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.h4 || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        p1: {
            paletteColorIndex: !uniqueColors.p1 ? colorPalette.COLOR_MAP.PARAGRAPH : null,
            color: uniqueColors.p1 || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH],
            primaryColor: uniqueColors.p1 || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH]
        },
        p2: {
            paletteColorIndex: !uniqueColors.p2 ? colorPalette.COLOR_MAP.PARAGRAPH : null,
            color: uniqueColors.p2 || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH],
            primaryColor: uniqueColors.p2 || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH]
        },
        projectTitle: {
            paletteColorIndex: !uniqueColors.projectTitle ? colorPalette.COLOR_MAP.HEADING : null,
            color: uniqueColors.projectTitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING],
            primaryColor: uniqueColors.projectTitle || colorPalette.COLORS[colorPalette.COLOR_MAP.HEADING]
        },
        projectSubtitle: {
            paletteColorIndex: !uniqueColors.projectSubtitle ? colorPalette.COLOR_MAP.PARAGRAPH : null,
            color: uniqueColors.projectSubtitle || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH],
            primaryColor: uniqueColors.projectSubtitle || colorPalette.COLORS[colorPalette.COLOR_MAP.PARAGRAPH]
        },
        navigation: {
            paletteColorIndex: !uniqueColors.navigation ? colorPalette.COLOR_MAP.NAVIGATION : null,
            color: uniqueColors.navigation || colorPalette.COLORS[colorPalette.COLOR_MAP.NAVIGATION],
            primaryColor: uniqueColors.navigation || colorPalette.COLORS[colorPalette.COLOR_MAP.NAVIGATION]
        },
        navigationHover: {
            paletteColorIndex: !uniqueColors.navigationHover ? colorPalette.COLOR_MAP.NAVIGATION_HOVER : null,
            color: uniqueColors.navigationHover || colorPalette.COLORS[colorPalette.COLOR_MAP.NAVIGATION_HOVER],
            primaryColor: uniqueColors.navigationHover || colorPalette.COLORS[colorPalette.COLOR_MAP.NAVIGATION_HOVER]
        },
        background: {
            paletteColorIndex: !uniqueColors.background ? colorPalette.COLOR_MAP.BACKGROUND : null,
            color: uniqueColors.background || colorPalette.COLORS[colorPalette.COLOR_MAP.BACKGROUND],
            primaryColor: uniqueColors.background || colorPalette.COLORS[colorPalette.COLOR_MAP.BACKGROUND]
        },
        dividerLine: {
            paletteColorIndex: !uniqueColors.dividerLine ? colorPalette.COLOR_MAP.DIVIDER_LINE : null,
            color: uniqueColors.dividerLine || colorPalette.COLORS[colorPalette.COLOR_MAP.DIVIDER_LINE],
            primaryColor: uniqueColors.dividerLine || colorPalette.COLORS[colorPalette.COLOR_MAP.DIVIDER_LINE]
        },
        sidebarBackground: {
            paletteColorIndex: !uniqueColors.sidebarBackground ? colorPalette.COLOR_MAP.FOREGROUND : null,
            color: uniqueColors.sidebarBackground || colorPalette.COLORS[colorPalette.COLOR_MAP.FOREGROUND],
            primaryColor: uniqueColors.sidebarBackground || colorPalette.COLORS[colorPalette.COLOR_MAP.FOREGROUND]
        },
        hyperLink: {
            paletteColorIndex: !uniqueColors.hyperLink ? colorPalette.COLOR_MAP.HYPERLINK : null,
            color: uniqueColors.hyperLink || colorPalette.COLORS[colorPalette.COLOR_MAP.HYPERLINK],
            primaryColor: uniqueColors.hyperLink || colorPalette.COLORS[colorPalette.COLOR_MAP.HYPERLINK]
        },
        buttonFill: {
            paletteColorIndex: !uniqueColors.buttonFill ? colorPalette.COLOR_MAP.BUTTON_FILL : null,
            color: uniqueColors.buttonFill || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_FILL],
            primaryColor: uniqueColors.buttonFill || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_FILL]
        },
        buttonOutline: {
            paletteColorIndex: !uniqueColors.buttonOutline ? colorPalette.COLOR_MAP.BUTTON_FILL : null,
            color: uniqueColors.buttonOutline || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_FILL],
            primaryColor: uniqueColors.buttonOutline || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_FILL]
        },
        buttonText: {
            paletteColorIndex: !uniqueColors.buttonText ? colorPalette.COLOR_MAP.BUTTON_TEXT : null,
            color: uniqueColors.buttonText || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_TEXT],
            primaryColor: uniqueColors.buttonText || colorPalette.COLORS[colorPalette.COLOR_MAP.BUTTON_TEXT]
        },
    }
}

export default {
    getDesignSettingsByTheme(theme) {
        let currentTheme = theme ? theme : 'theme_default';
        let strippedTheme = currentTheme.replace("profile_", "");
        return {
            fontStyles: this.getFontStylesByTheme()[strippedTheme],
            colorStyles: this.getColorStylesByTheme()[strippedTheme],
            colorPalette: {
                id: this.getColorPaletteByTheme()[strippedTheme].ID,
                colors: this.getColorPaletteByTheme()[strippedTheme].COLORS
            },
            globalFontStyles: true,
            globalColorStyles: true,
            globalAnimationSettings: true,
            animationSettings: {
                animationStyle: "NONE",
                animationSpeed: 2.0,
            }
        }
    },

    getFontStylesByTheme() {
        return {
            // Baze
            theme_default: {
                heroTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: 28,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: 24,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: 16,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h2: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h3: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h4: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                p1: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic',
                },
                projectTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.PAGE_TITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                button: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.BUTTON
                },
            },
            // Twee
            theme_one: {
                heroTitle: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.HERO_TITLE,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.HERO_SUBTITLE,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.HERO_PARAGRAPH,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                h2: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                h3: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                h4: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                p1: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P1,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P2,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.PROJECT_SUBTITLE,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.NAVIGATION,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.HEADING,
                    size: FontScaleSmall.PAGE_TITLE,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.HEADING
                },
                button: {
                    font: IbmPlexMonoAndIbmPlexSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.BUTTON,
                    fontWeight: IbmPlexMonoAndIbmPlexSans.WEIGHTS.BUTTON
                },
            },
            // Alaine
            theme_two: {
                heroTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: 72,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: 48,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: 22,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h2: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h3: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h4: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                p1: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: RoundoAndKarla.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                button: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: RoundoAndKarla.WEIGHTS.BUTTON
                },
            },
            // Axis
            theme_three: {
                heroTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.HERO_TITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.HERO_SUBTITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.HERO_PARAGRAPH,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h2: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h3: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h4: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                p1: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                button: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.BUTTON
                },
            },
            // Cubic
            theme_four: {
                heroTitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: 32,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: 24,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: 14,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h1: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h2: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h3: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h4: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                p1: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P1,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P2,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.PROJECT_SUBTITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.NAVIGATION,
                    fontWeight: OutfitAndPoppins.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                button: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.BUTTON,
                    fontWeight: OutfitAndPoppins.WEIGHTS.BUTTON
                },
            },
            // Panorama
            theme_five: {
                heroTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: 72,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: 48,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: 22,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h2: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h3: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                h4: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING,
                },
                p1: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: ClashDisplayAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.HEADING
                },
                button: {
                    font: ClashDisplayAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: ClashDisplayAndInter.WEIGHTS.BUTTON
                },
            },
            // Poster
            theme_six: {
                heroTitle: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.HERO_TITLE,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.HERO_SUBTITLE,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.HERO_PARAGRAPH,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                h2: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                h3: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                h4: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                p1: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: StixTwoTextAndSwitzer.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.HEADING
                },
                button: {
                    font: StixTwoTextAndSwitzer.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: StixTwoTextAndSwitzer.WEIGHTS.BUTTON
                },
            },
            // Uncurled
            theme_seven: {
                heroTitle: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: 72,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: 48,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.HERO_PARAGRAPH,
                    fontWeight: GambettaAndInter.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                h2: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                h3: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                h4: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                p1: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: GambettaAndInter.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: GambettaAndInter.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: GambettaAndInter.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: GambettaAndInter.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: GambettaAndInter.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: GambettaAndInter.WEIGHTS.HEADING
                },
                button: {
                    font: GambettaAndInter.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: GambettaAndInter.WEIGHTS.BUTTON
                },
            },
            // Nominee
            theme_eight: {
                heroTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: 32,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: 28,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: 20,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h2: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h3: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h4: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                p1: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: InterOnly.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.PAGE_TITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                button: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: InterOnly.WEIGHTS.BUTTON
                },
            },
            // Anthra
            theme_nine: {
                heroTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: 40,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: 28,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: 16,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h2: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h3: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                h4: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                p1: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: RoundoAndKarla.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: RoundoAndKarla.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: RoundoAndKarla.WEIGHTS.HEADING
                },
                button: {
                    font: RoundoAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: RoundoAndKarla.WEIGHTS.BUTTON
                },
            },
            // Orielle
            theme_ten: {
                heroTitle: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: 80,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: 56,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: 22,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                h2: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                h3: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                h4: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                p1: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: SpaceGroteskAndWorkSans.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.HEADING
                },
                button: {
                    font: SpaceGroteskAndWorkSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: SpaceGroteskAndWorkSans.WEIGHTS.BUTTON
                },
            },
            // Bauhaus
            theme_eleven: {
                heroTitle: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: 72,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.HERO_SUBTITLE,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.HERO_PARAGRAPH,
                    fontWeight: DMSansOnly.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.H1,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                h2: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.H2,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                h3: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.H3,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                h4: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.H4,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                p1: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P1,
                    fontWeight: DMSansOnly.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P1,
                    fontWeight: DMSansOnly.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.PROJECT_TITLE,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.PROJECT_SUBTITLE,
                    fontWeight: DMSansOnly.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.NAVIGATION,
                    fontWeight: DMSansOnly.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: DMSansOnly.FONTS.HEADING,
                    size: FontScaleLarge.PROJECT_TITLE,
                    fontWeight: DMSansOnly.WEIGHTS.HEADING
                },
                button: {
                    font: DMSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.BUTTON,
                    fontWeight: DMSansOnly.WEIGHTS.BUTTON
                },
            },
            // Otis
            theme_twelve: {
                heroTitle: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: 60,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: 30,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: 20,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                h2: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                h3: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                h4: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                p1: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: GeneralSansAndGambetta.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.HEADING
                },
                button: {
                    font: GeneralSansAndGambetta.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: GeneralSansAndGambetta.WEIGHTS.BUTTON
                },
            },
            // Nelson
            theme_thirteen: {
                heroTitle: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.HERO_TITLE,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.HERO_SUBTITLE,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.HERO_PARAGRAPH,
                    fontWeight: AlberSansOnly.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                h2: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                h3: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                h4: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                p1: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: AlberSansOnly.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: AlberSansOnly.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: AlberSansOnly.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: AlberSansOnly.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: AlberSansOnly.FONTS.HEADING,
                    size: FontScaleMedium.PAGE_TITLE,
                    fontWeight: AlberSansOnly.WEIGHTS.HEADING
                },
                button: {
                    font: AlberSansOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: AlberSansOnly.WEIGHTS.BUTTON
                },
            },
            // Jakob
            theme_fourteen: {
                heroTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.HERO_TITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.HERO_SUBTITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.HERO_PARAGRAPH,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.H1,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h2: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.H2,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h3: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.H3,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                h4: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.H4,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                p1: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P1,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P2,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.PROJECT_TITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.PROJECT_SUBTITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.NAVIGATION,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: BricolageGroteskAndKarla.FONTS.HEADING,
                    size: FontScaleLarge.PAGE_TITLE,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.HEADING
                },
                button: {
                    font: BricolageGroteskAndKarla.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.BUTTON,
                    fontWeight: BricolageGroteskAndKarla.WEIGHTS.BUTTON
                },
            },
            theme_fifteen: {
                heroTitle: {
                    font: 'ClashDisplay',
                    size: 64,
                    lineHeight: 67,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                heroSubtitle: {
                    font: 'ClashGrotesk',
                    size: 36,
                    lineHeight: 42,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                heroParagraph: {
                    font: 'ClashGrotesk',
                    size: 24,
                    lineHeight: 30,
                    fontWeight: 400,
                    fontStyle: 'normal',
                },
                h1: {
                    font: 'ClashDisplay',
                    size: 64,
                    lineHeight: 106,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                h2: {
                    font: 'ClashGrotesk',
                    size: 36,
                    lineHeight: 118,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                h3: {
                    font: 'ClashDisplay',
                    size: 36,
                    lineHeight: 50,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                h4: {
                    font: 'ClashGrotesk',
                    size: 20,
                    lineHeight: 24,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                p1: {
                    font: 'ClashGrotesk',
                    size: 18,
                    lineHeight: 33,
                    fontWeight: 400,
                    fontStyle: 'normal',
                },
                p2: {
                    font: 'ClashGrotesk',
                    size: 16,
                    lineHeight: 27,
                    fontWeight: 400,
                    fontStyle: 'normal',
                },
                projectTitle: {
                    font: 'ClashDisplay',
                    size: 48,
                    lineHeight: 43,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
                projectSubtitle: {
                    font: 'ClashGrotesk',
                    size: 18,
                    lineHeight: 25,
                    fontWeight: 300,
                    fontStyle: 'normal',
                },
                navigation: {
                    font: 'ClashGrotesk',
                    size: 16,
                    lineHeight: 19,
                    fontWeight: 400,
                    fontStyle: 'normal',
                },
                pageTitle: {
                    font: 'Montserrat',
                    size: 80,
                    lineHeight: 87,
                    fontWeight: 700,
                    fontStyle: 'normal',
                },
                button: {
                    font: "ClashDisplay",
                    size: 14,
                    relativeLineHeight: 1.22,
                    fontWeight: 500,
                    fontStyle: 'normal',
                },
            },
            // Norman
            theme_sixteen: {
                heroTitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.HERO_TITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.HERO_SUBTITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.HERO_PARAGRAPH,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.H1,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h2: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.H2,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h3: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.H3,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                h4: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.H4,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                p1: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P1,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.P2,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle:  {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.PROJECT_SUBTITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.NAVIGATION,
                    fontWeight: OutfitAndPoppins.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: OutfitAndPoppins.FONTS.HEADING,
                    size: FontScaleMedium.PROJECT_TITLE,
                    fontWeight: OutfitAndPoppins.WEIGHTS.HEADING
                },
                button: {
                    font: OutfitAndPoppins.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleMedium.BUTTON,
                    fontWeight: OutfitAndPoppins.WEIGHTS.BUTTON
                },
            },
            // Aurora
            theme_seventeen: {
                heroTitle: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.HERO_TITLE,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.HERO_SUBTITLE,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.HERO_PARAGRAPH,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                h1: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.H1,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                h2: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.H2,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                h3: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.H3,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                h4: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.H4,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING
                },
                p1: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P1,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.P2,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.PARAGRAPH,
                    fontStyle: 'italic'
                },
                projectTitle: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.PROJECT_TITLE,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING,
                },
                projectSubtitle: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.PROJECT_SUBTITLE,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.PARAGRAPH,
                },
                navigation: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.NAVIGATION,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.NAVIGATION,
                },
                pageTitle: {
                    font: MerchantAndOpenSans.FONTS.HEADING,
                    size: FontScaleLarge.PAGE_TITLE,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.HEADING,
                },
                button: {
                    font: MerchantAndOpenSans.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleLarge.BUTTON,
                    fontWeight: MerchantAndOpenSans.WEIGHTS.BUTTON,
                },
            },
            // Tesler
            theme_eighteen: {
                heroTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.HERO_TITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                heroSubtitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.HERO_SUBTITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                heroParagraph: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.HERO_PARAGRAPH,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                h1: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H1,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h2: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H2,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h3: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H3,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                h4: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.H4,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                p1: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P1,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                p2: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.P2,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                projectTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.PROJECT_TITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                projectSubtitle: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.PROJECT_SUBTITLE,
                    fontWeight: InterOnly.WEIGHTS.PARAGRAPH
                },
                navigation: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.NAVIGATION,
                    fontWeight: InterOnly.WEIGHTS.NAVIGATION
                },
                pageTitle: {
                    font: InterOnly.FONTS.HEADING,
                    size: FontScaleSmall.PAGE_TITLE,
                    fontWeight: InterOnly.WEIGHTS.HEADING
                },
                button: {
                    font: InterOnly.FONTS.PARAGRAPH_AND_NAV,
                    size: FontScaleSmall.BUTTON,
                    fontWeight: InterOnly.WEIGHTS.BUTTON
                },
            },
        };
    },

    getColorStylesByTheme() {
        return {
            // Baze
            theme_default: getColors(AsphaltColorPalette, {
                buttonFill: AsphaltColorPalette.COLORS[4],
                sidebarBackground: '#FFFFFF'
            }),
            // Twee
            theme_one: getColors(GraphiteColorPalette, {
                buttonFill: GraphiteColorPalette.COLORS[4],
                buttonOutline: '#DEDEDE',
                buttonText: '#000000'
            }),
            // Alaine
            theme_two: getColors(IndigoColorPalette),
            // Axis
            theme_three: getColors(CoalColorPalette, {
                buttonFill: '#EFEFEF',
                buttonOutline: '#EFEFEF',
                buttonText: '#000000'
            }),
            // Cubic
            theme_four: getColors(GraphiteColorPalette),
            // Panorama
            theme_five: getColors(AbyssColorPalette),
            // Poster
            theme_six: getColors(CoalColorPalette, {
                heroTitle: '#FFFFFF',
                heroSubtitle: '#FFFFFF',
                heroParagraph: '#FFFFFF',
            }),
            // Uncurled
            theme_seven: getColors(ChestnutColorPalette, {buttonFill: ChestnutColorPalette.COLORS[4]}),
            // Nominee
            theme_eight: getColors(GraniteColorPalette),
            // Anthra
            theme_nine: getColors(CoalColorPalette, {
                heroTitle: '#FFFFFF',
                heroSubtitle: '#FFFFFF',
                heroParagraph: '#A4A4A4',
                navigation: '#A4A4A4',
                navigationHover: '#FFFFFF',
                sidebarBackground: '#262f34',
                buttonFill: '#C6CAD6',
                buttonOutline: '#C6CAD6',
                buttonText: '#212329'
            }),
            // Orielle
            theme_ten: getColors(ObsidianColorPalette),
            // Bauhaus
            theme_eleven: getColors(CrimsonColorPalette, {buttonFill: CrimsonColorPalette.COLORS[4]}),
            // Otis
            theme_twelve: getColors(GraphiteColorPalette),
            // Nelson
            theme_thirteen: getColors(CoalColorPalette, {buttonFill: CoalColorPalette.COLORS[4], buttonText: CoalColorPalette.COLORS[0]}),
            // Jakob
            theme_fourteen: getColors(AsphaltColorPalette),
            theme_fifteen: getColors(),
            // Norman
            theme_sixteen: getColors(CaramelColorPalette, {
                buttonFill: CaramelColorPalette.COLORS[4],
                buttonText: CaramelColorPalette.COLORS[1]
            }),
            // Aurora
            theme_seventeen: getColors(BorealisColorPalette, {buttonFill: BorealisColorPalette[4]}),
            // Tesler
            theme_eighteen: getColors(ObsidianColorPalette)
        };
    },

    getColorPaletteByTheme() {
        return {
            theme_default: AsphaltColorPalette,
            theme_one: GraphiteColorPalette,
            theme_two: IndigoColorPalette,
            theme_three: CoalColorPalette,
            theme_four: GraphiteColorPalette,
            theme_five: AbyssColorPalette,
            theme_six: CoalColorPalette,
            theme_seven: ChestnutColorPalette,
            theme_eight: GraniteColorPalette,
            theme_nine: CoalColorPalette,
            theme_ten: ObsidianColorPalette,
            theme_eleven: CrimsonColorPalette,
            theme_twelve: GraphiteColorPalette,
            theme_thirteen: CoalColorPalette,
            theme_fourteen: AsphaltColorPalette,
            theme_sixteen: CaramelColorPalette,
            theme_seventeen: BorealisColorPalette,
            theme_eighteen: ObsidianColorPalette
        }
    }
}