<template>
    <div class="empty-state grid">
        <div :class="['empty-collection', {dark:portfolioIsDark}]">
            <div :class="['container']">
                <Icon :icon-name="caseStudiesAreDraft ? 'hidden-gradient' : 'add-case-study-gradient'" />
                <div class="text-wrapper">
                    <h2 class="title">{{ title }}</h2>
                    <p class="subtitle">{{ subtitle }}</p>
                </div>
                <div class="centered-button">
                    <Cta :dark-scheme="portfolioIsDark" @click="() => $router.push('home')">Open the editor</Cta>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Cta from "../../../../common/components/CtaButton.vue";
    import Icon from "../../../../common/Icon.vue";
    import {hexToHSL, resolveColor} from "../../../../common/helpers.js";
    import {PageTypesEnum} from "../../../../common/enums.js";

    export default {
        name: "EmptyProjectGrid",
        components: {
            Icon,
            Cta
        },
        computed: {
            caseStudiesAreDraft() {
                return this.$store.state.view.hasDraftCasestudies
            },
            title() {
                return this.caseStudiesAreDraft ? 'Your case studies are in draft mode' : 'You haven’t added any case studies yet'
            },
            subtitle() {
                return this.caseStudiesAreDraft ? 'You can publish your case studies from the editor.' : 'Add case studies to your portfolio from the editor.'
            },
            portfolioIsDark() {
                return hexToHSL(resolveColor(this.colorStyles.background, this.colorPalette)).l < 0.2;
            },
            colorStyles() {
                return this.isCaseStudy && !this.usingGlobalColorStyles ?
                    this.$store.state.view.project.designSettings.colorStyles :
                    this.$store.state.view.portfolio.designSettings.colorStyles;
            },
            colorPalette() {
                return this.isCaseStudy && !this.usingGlobalColorStyles ?
                    this.$store.state.view.project.designSettings.colorPalette :
                    this.$store.state.view.portfolio.designSettings.colorPalette;
            },
            isCaseStudy() {
                return this.$store.state.project && this.$store.state.project.type === PageTypesEnum.CASE_STUDY;
            },
            usingGlobalColorStyles() {
                return this.$store.state.view.project.type === PageTypesEnum.CASE_STUDY && this.$store.state.view.project.designSettings.globalColorStyles;
            },
        }
    }
</script>

<style scoped lang="scss">
    .empty-state {
        padding-top: 60px;
        padding-bottom: 60px;

        .empty-collection {
            padding: 90px 0;
            border-radius: 8px;
            border: 1px dashed rgba(15, 15, 15, 0.20);
            background-color: rgba(15, 15, 15, 0.02);
            grid-column: 1 / 13;

            &.dark {
                border: 1px dashed rgba(255, 255, 255, 0.20);
                background: rgba(255, 255, 255, 0.02);
                .container {
                    svg {
                        color:var(--asphalt10);
                    }
                    .text-wrapper {
                        .title {
                            color: var(--asphalt10);
                        }

                        .subtitle {
                            color: var(--asphalt30);
                        }
                    }
                }
            }

            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 36px;
                align-self: stretch;
                max-width: 500px;
                margin: auto;

                svg {
                    color:var(--asphalt100);
                    width: 48px;
                    height: 48px;
                }

                .text-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-self: stretch;
                    text-align: center;

                    .title {
                        align-self: stretch;
                        font: var(--editor-ui-h1);
                        color: var(--asphalt100);
                        margin: 0;
                    }

                    .subtitle {
                        align-self: stretch;
                        font: var(--editor-ui-b1-regular);
                        color: var(--asphalt60);
                        margin: 0;
                    }
                }

                .centered-button {

                }

            }

        }
    }
</style>