<template>
    <div v-if="loaded">
        <ReviewMode v-if="review && pageType !== 'coming_soon'">
            <component :is="currentComponent" v-bind="currentProps" />
        </ReviewMode>
        <template v-else>
            <component :is="currentComponent" v-bind="currentProps" />
        </template>
    </div>
</template>

<script>
    import Project from "./Project.vue"
    import ApiHelper from "../../api/ApiHelper.js";
    import { checkFonts } from "../../settingsEditor/_helpers/fontLoaders.js";
    import CustomPage from "./Page.vue";
    import PasswordProtection from "./common/PasswordProtection.vue";
    import ErrorPage from "../common/ErrorPage.vue";
    import GoogleFontsHelper from "../../common/GoogleFontsHelper.js";
    import ReviewMode from "../../view/review/ReviewMode.vue";
    import {resolveColor} from "@/scripts/common/helpers.js";
    import ComingSoon from "../../common/ComingSoon.vue";

    export default {
        name: "PageWrapper",
        props: ['review'],
        components: {
            ReviewMode,
            ErrorPage,
            PasswordProtection,
            CustomPage,
            Project,
            ComingSoon
        },
        data() {
            return {
                pageType:null,
                loaded:false
            }
        },
        async beforeRouteEnter(to, from, next) {
            try {
                let url = '/data/project'
                let projectShortId = to.params.projectShortId;
                let portfolioShortId = to.params.portfolioShortId;

                if(portfolioShortId) {
                    url += "/" + portfolioShortId;
                }

                if(projectShortId) {
                    url += "/" + projectShortId;
                }

                let res = await ApiHelper.get(url);
                next(vm => vm.setData(res.status, res.data))
            }catch(err) {
                next(vm => vm.setData(err, null))
            }
        },
        async beforeRouteUpdate(to, from, next) {
            try {
                let url = this.fetchUrl(to)
                let res = await ApiHelper.get(url);
                let data = res.data;
                this.setData(null, data);
                next();
            }
            catch(err) {
                if(err.response.status === 404) {
                    this.pageType = 'not_found'
                    this.loaded = true;
                    next();
                } else if (err.response.status === 401) {
                    this.pageType = 'coming_soon';
                    this.loaded = true;
                    next();
                } else {
                    this.pageType = 'password';
                    this.loaded = true;
                    next();
                }
            }
        },
        methods: {
            setData(err, data) {
                if(err && !data) {
                    if(err.response.status === 404) {
                        this.pageType = 'not_found'
                        this.loaded = true;
                        return;
                    } else if (err.response.status === 401) {
                        this.pageType = 'coming_soon';
                        this.loaded = true;
                        return;
                    }

                    this.pageType = 'password';
                    this.loaded = true;
                    return;
                }
                //Load fonts
                //TODO: every case study can have different fonts -> global not needed but figure this out
                if(!this.$store.state.view.fontsLoaded || data.pageType === 'project') {
                    checkFonts(data.fonts)
                    //load legacy fonts
                    let legacyFonts = data.fontNames.filter((fontName) => !data.fonts.map(x => x.name).includes(fontName))
                    legacyFonts.forEach((name) =>{
                        GoogleFontsHelper.getFontURL(name);
                    })
                    this.$store.state.view.fontsLoaded = true;
                }

                let viewData = {
                    portfolio:data.portfolio,
                    project:data.project,
                    user:data.user,
                    portfolioPages:data.portfolioPages,
                    activePage:data.project.shortId,
                    footerProjects:data.footerProjects,
                    loggedInUserId:data.loggedInUserId,
                }
                this.$store.commit('updateView', viewData);

                this.pageType = data.pageType;


                const target = document.querySelector("body");
                if(data.pageType === 'project') {
                    target.style.backgroundColor = resolveColor(
                        data.project.designSettings.colorStyles.background,
                        data.project.designSettings.colorPalette
                    );
                } else {
                    target.style.backgroundColor = resolveColor(
                        data.portfolio.designSettings.colorStyles.background,
                        data.project.designSettings.colorPalette
                    );
                }

                if(!data.portfolio.templateSettings.theme.includes('profile_')) {
                    try {
                        import(`../../../styles/_${data.portfolio.templateSettings.theme.replaceAll('_', '-').replace('profile_', '')}-base.scss`);
                    }
                    catch (e) {
                        console.log('failed to import scss')
                    }
                }

                //TODO: figure this out
                this.$store.state.portfolio = data.portfolio
                this.$store.state.project = data.project

                this.loaded = true;
            },
            fetchUrl(to) {
                let url = '/data/project'
                let projectShortId = to.params.projectShortId;
                let portfolioShortId = to.params.portfolioShortId;

                if(portfolioShortId) {
                    url += "/" + portfolioShortId;
                }

                if(projectShortId) {
                    url += "/" + projectShortId;
                }

                return url;
            }
        },
        computed: {
            currentComponent() {
                if (!this.loaded) return null;
                switch (this.pageType) {
                    case 'project': return 'Project';
                    case 'custom': return 'CustomPage';
                    case 'password': return 'PasswordProtection';
                    case 'not_found': return 'ErrorPage';
                    case 'coming_soon': return 'ComingSoon';
                    default: return null;
                }
            },
            currentProps() {
                switch (this.pageType) {
                    case 'password':
                        return { fetchUrl: this.fetchUrl, setData: this.setData, type: 'Case Study' };
                    case 'coming_soon':
                        return { type: 'Case Study' };
                    case 'not_found':
                        return { error: { code: 404, title: "This page doesn't exist", message: "We can't seem to find the page you are looking for" } };
                    default:
                        return {};
                }
            }
        }
    }
</script>

<style lang="scss">

    .ufo--page-sections,
    .footer-info {
        --black: #140a2f;
        --white: #FFFFFF;
        --red: #fc3d83;
        --blue: #4a3dff;
        --purple: #5c50ff;
    }
</style>