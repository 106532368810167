export const defaultColorMap = {
    HEADING: 0,
    PARAGRAPH: 1,
    NAVIGATION: 1,
    NAVIGATION_HOVER: 0,
    BUTTON_FILL: 2,
    BUTTON_TEXT: 4,
    HYPERLINK: 0,
    BACKGROUND: 4,
    FOREGROUND: 3,
    DIVIDER_LINE: 3
}

export function getColorStyleMappings(colorMap) {
    return [
        { key: 'heroTitle', colorKey: colorMap.HEADING },
        { key: 'heroSubtitle', colorKey: colorMap.HEADING },
        { key: 'heroParagraph', colorKey: colorMap.PARAGRAPH },
        { key: 'h1', colorKey: colorMap.HEADING },
        { key: 'h2', colorKey: colorMap.HEADING },
        { key: 'h3', colorKey: colorMap.HEADING },
        { key: 'h4', colorKey: colorMap.HEADING },
        { key: 'p1', colorKey: colorMap.PARAGRAPH },
        { key: 'p2', colorKey: colorMap.PARAGRAPH },
        { key: 'projectTitle', colorKey: colorMap.HEADING },
        { key: 'projectSubtitle', colorKey: colorMap.PARAGRAPH },
        { key: 'background', colorKey: colorMap.BACKGROUND },
        { key: 'sidebarBackground', colorKey: colorMap.FOREGROUND },
        { key: 'navigation', colorKey: colorMap.NAVIGATION },
        { key: 'navigationHover', colorKey: colorMap.NAVIGATION },
        { key: 'hyperLink', colorKey: colorMap.HYPERLINK },
        { key: 'dividerLine', colorKey: colorMap.DIVIDER_LINE },
        { key: 'buttonFill', colorKey: colorMap.BUTTON_FILL },
        { key: 'buttonOutline', colorKey: colorMap.BUTTON_FILL },
        { key: 'buttonText', colorKey: colorMap.BUTTON_TEXT }
    ]
}

// LIGHT PALETTES
// template remaster 2024.07.
export const GraphiteColorPalette = Object.freeze({
    ID: 'graphite',
    NAME: 'Graphite',
    IMAGE: 'graphite',
    TYPE: 'light',
    COLORS: ['#000000', '#787878', '#505050', '#EBEBEB', '#FFFFFF'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
})

export const CoalColorPalette = Object.freeze({
    ID: 'coal',
    NAME: 'Coal',
    IMAGE: 'coal',
    TYPE: 'light',
    COLORS: ['#000000', '#000000', '#000000', '#E1E1E1', '#FFFFFF'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
})

export const AsphaltColorPalette = Object.freeze({
    ID: 'asphalt',
    NAME: 'Asphalt',
    IMAGE: 'asphalt',
    TYPE: 'light',
    COLORS: ['#1E1C1B', '#727272', '#D5D1CF', '#D4D4D4', '#F1F1F1'],
    COLOR_MAP: {
        ...defaultColorMap,
        BUTTON_TEXT: 0
    }
})

export const ConcreteColorPalette = Object.freeze({
    ID: 'concrete',
    NAME: 'Concrete',
    IMAGE: 'concrete',
    TYPE: 'light',
    COLORS: ['#646464', '#646464', '#646464', '#C3C3C3', '#D7D7D7'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
})

export const IndigoColorPalette = Object.freeze({
    ID: 'indigo',
    NAME: 'Indigo',
    IMAGE: 'indigo',
    TYPE: 'light',
    COLORS: ['#3A405A', '#848292', '#3A405A', '#DACEC8', '#F8E9DD'],
    COLOR_MAP: defaultColorMap
})

export const ChestnutColorPalette = Object.freeze({
    ID: 'chestnut',
    NAME: 'Chestnut',
    IMAGE: 'chestnut',
    TYPE: 'light',
    COLORS: ['#3D312A', '#7C7069', '#D7C4BA', '#CDC1BB', '#E8DDD6'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1,
        BUTTON_TEXT: 0
    }
})

export const CaramelColorPalette = Object.freeze({
    ID: 'caramel',
    NAME: 'Caramel',
    IMAGE: 'caramel',
    TYPE: 'light',
    COLORS: ['#5C5651', '#917550', '#B58D56', '#E2E0D8', '#F5F4EB'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }

})

export const StampColorPalette = Object.freeze({
    ID: 'stamp',
    NAME: 'Stamp',
    IMAGE: 'stamp',
    TYPE: 'light',
    COLORS: ['#2D1B4D', '#2D1B4D', '#E1CEE4', '#C4CDCE', '#E1EFE6'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1,
        BUTTON_TEXT: 0
    }
})

export const BorealisColorPalette = Object.freeze({
    ID: 'borealis',
    NAME: 'Borealis',
    IMAGE: 'borealis',
    TYPE: 'light',
    COLORS: ['#2C2669', '#817D93', '#E5E4F3', '#D5D3E7', '#F5F4FF'],
    COLOR_MAP: {
        ...defaultColorMap,
        BUTTON_TEXT: 0
    }
})

export const CrimsonColorPalette = Object.freeze({
    ID: 'crimson',
    NAME: 'Crimson',
    IMAGE: 'crimson',
    TYPE: 'light',
    COLORS: ['#FF000F', '#FF000F', '#FF000F', '#FFCCC3', '#FFF3E5'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1,
        BUTTON_TEXT: 0
    }

})

// before template remaster
export const TaupeColorPalette = Object.freeze({
    ID: 'taupe',
    NAME: 'Taupe',
    IMAGE: 'taupe',
    TYPE: 'light',
    COLORS: ['#534D46', '#6C6760', '#9C886F', '#C1B9AE', '#F6F5F3'],
    COLOR_MAP: defaultColorMap
},)

export const BlueberryColorPalette = Object.freeze({
    ID: 'blueberry',
    NAME: 'Blueberry',
    IMAGE: 'blueberry',
    TYPE: 'light',
    COLORS: ['#213BC8', '#383830', '#233BC8', '#DCDCDB', '#F5F5F4'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
},)

export const ClementineColorPalette = Object.freeze({
    ID: 'clementine',
    NAME: 'Clementine',
    IMAGE: 'clementine',
    TYPE: 'light',
    COLORS: ['#bd7920', '#4e4d4b', '#bd7920', '#DBD7CF', '#f4efe7'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
},)

export const OliveColorPalette = Object.freeze({
    ID: 'olive',
    NAME: 'Olive',
    IMAGE: 'olive',
    TYPE: 'light',
    COLORS: ['#33332a', '#494946', '#4c5334', '#DDDFD6', '#e8e9e4'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
},)

////////////////////////////////
////////////////////////////////
////////////////////////////////
// DARK PALETTES
////////////////////////////////
////////////////////////////////
////////////////////////////////

// template remaster 2024.07.
export const ObsidianColorPalette = Object.freeze({
    ID: 'obsidian',
    NAME: 'Obsidian',
    IMAGE: 'obsidian',
    TYPE: 'dark',
    COLORS: ['#FFFFFF', '#B9B9B9', '#313030', '#242424', '#060606'],
    COLOR_MAP: {
        ...defaultColorMap,
        BUTTON_TEXT: 0
    }
})

export const AbyssColorPalette = Object.freeze({
    ID: 'abyss',
    NAME: 'Abyss',
    IMAGE: 'abyss',
    TYPE: 'dark',
    COLORS: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#141414', '#000000'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
})

export const GraniteColorPalette = Object.freeze({
    ID: 'granite',
    NAME: 'Granite',
    IMAGE: 'granite',
    TYPE: 'dark',
    COLORS: ['#FFFFFF', '#FFFFFF', '#363636', '#282B2C', '#171A1C'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 2,
        BUTTON_TEXT: 0
    }
})

export const EclipseColorPalette = Object.freeze({
    ID: 'eclipse',
    NAME: 'Eclipse',
    IMAGE: 'eclipse',
    TYPE: 'dark',
    COLORS: ['#E9FF72', '#A3A3A3', '#E9FF72', '#1C1E12', '#0A0A0A'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
})

// before template remaster
export const SpaceGreyColorPalette = Object.freeze({
    ID: 'spaceGrey',
    NAME: 'Space grey',
    IMAGE: 'space-grey',
    TYPE: 'dark',
    COLORS: ['#ececee', '#a0a0a8', '#ececee', '#494946', '#0b0b0d'],
    COLOR_MAP: defaultColorMap
},)

export const NeonColorPalette = Object.freeze({
    ID: 'neon',
    NAME: 'Neon',
    IMAGE: 'neon',
    TYPE: 'dark',
    COLORS: ['#f2f3ed', '#bdc3c1', '#d9e783', '#252B29', '#131a18'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
},)

export const PurpleHazeColorPalette = Object.freeze({
    ID: 'purpleHaze',
    NAME: 'Purple haze',
    IMAGE: 'purple-haze',
    TYPE: 'dark',
    COLORS: ['#f0f0f8', '#aaafc9', '#5a53ab', '#23242D', '#11121b'],
    COLOR_MAP: {
        ...defaultColorMap,
        DIVIDER_LINE: 1
    }
},)

export const MoonlightColorPalette = Object.freeze({
    ID: 'moonlight',
    NAME: 'Moonlight',
    IMAGE: 'moonlight',
    TYPE: 'dark',
    COLORS: ['#f6e4b8', '#cdc9c1', '#f6e4b8', '#404652', '#1f2532'],
    COLOR_MAP: defaultColorMap
},)

const lightColorPalettes = [
    GraphiteColorPalette,
    CoalColorPalette,
    AsphaltColorPalette,
    ConcreteColorPalette,
    IndigoColorPalette,
    ChestnutColorPalette,
    CaramelColorPalette,
    StampColorPalette,
    BorealisColorPalette,
    CrimsonColorPalette,
    TaupeColorPalette,
    BlueberryColorPalette,
    ClementineColorPalette,
    OliveColorPalette
]

const darkColorPalettes = [
    ObsidianColorPalette,
    AbyssColorPalette,
    GraniteColorPalette,
    EclipseColorPalette,
    SpaceGreyColorPalette,
    NeonColorPalette,
    PurpleHazeColorPalette,
    MoonlightColorPalette
]

const colorPalettes = [
    ...lightColorPalettes,
    ...darkColorPalettes
]

export function getAllColorPalettes() {
    return colorPalettes
}

export function getColorPaletteById(id) {
    return colorPalettes.find(palette => palette.ID === id) || null
}


