<template>
    <div class="zoom-overlay" @click="handleClose">
        <div class="zoom-background" :class="[{ active: this.imageLoaded }]"></div>
        <img
            alt="zoomed-in-image"
            :src="zoomData.imageUrl"
            :class="['zoomed-image', ...inheritedClass]"
            :style="imageStyle"
            @click.stop="handleClose"
            @transitionend="onTransitionEnd"
        />
    </div>
</template>

<script>
export default {
    name: 'ZoomImage',
    data() {
        return {
            imageStyle: {},
            initialRect: null,
            finalRect: null,
            isClosing: false,
            waitingForTransition: false,
            imageLoaded: false,
            PADDING: 40
        };
    },
    computed: {
        zoomData() {
            return this.$store.state.zoomData;
        },
        inheritedClass() {
            return this.zoomData.imageRef ? [...this.zoomData.imageRef.classList] : [];
        },
        forcedAspectRatio() {
            if (!(this.zoomData && this.zoomData.imageRef)) {
                return null
            }
            const ratioClass = [...this.zoomData.imageRef.classList].find(cls =>
                cls.startsWith('aspect-ratio-')
            );
            if (!ratioClass) {
                return null
            }
            const parts = ratioClass.split('-');

            if (parts.length >= 3) {
                const ratioParts = parts[2].split('_');
                if (ratioParts.length === 2) {
                    const widthRatio = Number(ratioParts[0]);
                    const heightRatio = Number(ratioParts[1]);
                    if (widthRatio && heightRatio) {
                        return widthRatio / heightRatio;
                    }
                }
            }

            return null;
        }
    },
    methods: {
        computeFinalRect(naturalWidth, naturalHeight) {
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const maxWidth = viewportWidth - this.PADDING * 2;
            const maxHeight = viewportHeight - this.PADDING * 2;
            const ratio = this.forcedAspectRatio || (naturalWidth / naturalHeight);

            let {finalWidth, finalHeight} = this.calculateFinalDimensionsWhilePreserveAspectRatio(maxWidth, maxHeight, ratio);

            const finalLeft = (viewportWidth - finalWidth) / 2;
            const finalTop = (viewportHeight - finalHeight) / 2;
            return { left: finalLeft, top: finalTop, width: finalWidth, height: finalHeight };
        },
        calculateFinalDimensionsWhilePreserveAspectRatio(maxWidth, maxHeight, ratio) {
            let finalWidth, finalHeight;
            if (maxWidth / ratio <= maxHeight) {
                finalWidth = maxWidth;
                finalHeight = maxWidth / ratio;
            } else {
                finalHeight = maxHeight;
                finalWidth = maxHeight * ratio;
            }
            return {finalWidth:finalWidth, finalHeight:finalHeight}
        },
        handleClose() {
            if (this.isClosing) return;
            this.isClosing = true;
            this.waitingForTransition = true;
            this.imageStyle = {
                ...this.imageStyle,
                top: this.initialRect.top + 'px',
                left: this.initialRect.left + 'px',
                width: this.initialRect.width + 'px',
                height: this.initialRect.height + 'px'
            };
        },
        onTransitionEnd(e) {
            if (!this.isClosing) return;
            if (['top', 'left', 'width', 'height'].includes(e.propertyName)) {
                if (!this.waitingForTransition) return;
                this.waitingForTransition = false;
                this.$store.commit('resetZoom');
            }
        }
    },
    mounted() {
        if (this.zoomData && this.zoomData.imageRef) {
            const rect = this.zoomData.imageRef.getBoundingClientRect();
            this.initialRect = {
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height
            };
            this.imageStyle = {
                position: 'absolute',
                top: rect.top + 'px',
                left: rect.left + 'px',
                width: rect.width + 'px',
                height: rect.height + 'px'
            };

            const img = new Image();
            img.src = this.zoomData.imageUrl;
            img.onload = () => {
                this.imageLoaded = true;
                this.finalRect = this.computeFinalRect(img.naturalWidth, img.naturalHeight);
                this.$nextTick(() => {
                    requestAnimationFrame(() => {
                        this.imageStyle = {
                            ...this.imageStyle,
                            top: this.finalRect.top + 'px',
                            left: this.finalRect.left + 'px',
                            width: this.finalRect.width + 'px',
                            height: this.finalRect.height + 'px'
                        };
                    });
                });
            };
        }
    }
};
</script>

<style scoped>
img {
    &.aspect-ratio-1_1 {
        aspect-ratio: 1;
        object-fit: cover;
    }
    &.aspect-ratio-3_4 {
        aspect-ratio: 3/4;
        object-fit: cover;
    }
    &.aspect-ratio-4_3 {
        aspect-ratio: 4/3;
        object-fit: cover;
    }
    &.aspect-ratio-9_16 {
        aspect-ratio: 9/16;
        object-fit: cover;
    }
    &.aspect-ratio-16_9 {
        aspect-ratio: 16/9;
        object-fit: cover;
    }
}

.zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: zoom-out;
}

.zoom-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 300ms ease;
}

.zoom-background.active {
    opacity: 1;
}

.zoomed-image {
    position: absolute;
    transition: top 300ms ease, left 300ms ease, width 300ms ease, height 300ms ease;
    will-change: top, left, width, height;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.5));
}
</style>
