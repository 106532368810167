<template>
    <template v-if="galleryLayout === 'slider'">
        <div :class="['gallery-item', galleryItem.media.mockupSettings ? galleryItem.media.mockupSettings.deviceType : '', 'slider-mockup']">
            <template v-if="galleryItem.media.mockupSettings">
                <MockupContainer :media="galleryItem.media" />
            </template>
            <div v-else class="ufo-mockup--display">
                <div class="ufo-mockup--screen">
                    <div :class="['ufo-mockup--scroller', 'slider-image-container']">
                        <img ref="img"
                             @load="imageLoaded"
                             v-zoomable="isImageZoomable"
                             :class="[!galleryItem.media.mockupSettings ? 'slider-image' : '', !galleryItem.media.mockupSettings && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : '']"
                             loading="lazy"
                             :src="getImageUrl"
                             :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                             :width="galleryItem.media.width ? galleryItem.media.width : null"
                             :height="galleryItem.media.height ? galleryItem.media.height : null" />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <AnimationWrapper
            v-if="galleryLayout !== 'carousel'"
            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            :delay-animation="index ? index * 0.1 : 0"
        >
            <template #animationContent>
                <template v-if="galleryItem.media.mockupSettings">
                    <div :class="['gallery-item', galleryItem.media.mockupSettings.deviceType]">
                        <MockupContainer :media="galleryItem.media" />
                    </div>
                </template>
                <div v-else class="gallery-item">
                    <div class="mockup-display">
                        <div class="mockup-image">
                            <img ref="img"
                                 @load="imageLoaded"
                                 :loading="galleryLayout !== 'tilted' ? 'lazy' : null"
                                 v-zoomable="isImageZoomable"
                                 :class="!galleryItem.media.mockupSettings && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                                 :src="getImageUrl"
                                 :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                                 :width="galleryItem.media.width ? galleryItem.media.width : null"
                                 :height="galleryItem.media.height ? galleryItem.media.height : null" />
                        </div>
                    </div>
                </div>
            </template>
        </AnimationWrapper>

        <template v-else>
            <template v-if="galleryItem.media.mockupSettings">
                <div :class="'gallery-item' + galleryItem.media.mockupSettings.deviceType">
                    <MockupContainer :media="galleryItem.media" />
                </div>
            </template>
            <div v-else class="gallery-item">
                <div class="mockup-display">
                    <div class="mockup-image">
                        <img ref="img"
                             @load="imageLoaded"
                             :loading="galleryLayout !== 'tilted' ? 'lazy' : null"
                             v-zoomable="isImageZoomable"
                             :class="!galleryItem.media.mockupSettings && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                             :src="getImageUrl"
                             :alt="galleryItem.media.altTag ? galleryItem.media.altTag : 'Screen design displayed in a mockup'"
                             :width="galleryItem.media.width ? galleryItem.media.width : null"
                             :height="galleryItem.media.height ? galleryItem.media.height : null" />
                    </div>
                </div>
            </div>
        </template>
    </template>
</template>

<script>
    import AnimationWrapper from "../../../../../scripts/common/components/AnimationWrapper.vue";
    import MockupContainer from "@/scripts/common/components/MockupContainer.vue";

    export default {
        name: "GetGalleryMediaContent",
        components: {MockupContainer, AnimationWrapper},
        props:['galleryItem', 'galleryLayout', 'index', 'forcedAspectRatio'],
        methods: {
            altTagReplace(text) {
                return text.replace('\"', '&quot;')
            },
            imageLoaded() {
                this.$store.commit("recalculateScrollTriggers");
            },
        },
        computed: {
            isImageZoomable() {
                return this.galleryItem.type !== 'MainHeader'
                    && this.galleryLayout !== 'tilted'
                    && (!this.galleryItem.media.displayMode || this.galleryItem.media.displayMode && this.galleryItem.media.displayMode !== 'full')
                    && !this.galleryItem.media.mockupSettings
            },
            getImageUrl() {
                return this.galleryItem.media.croppedImageUrl ? this.galleryItem.media.croppedImageUrl : this.galleryItem.media.url
            }
        }
    }
</script>